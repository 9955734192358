export var apiServiceUrl = function apiServiceUrl() {
  var environments = {
    "localhost:443": "https://test-api.vardenisiffror.se",
    "vis.local": "https://test-api.vardenisiffror.se",
    "dev.vardenisiffror": "https://test-api.vardenisiffror.se",
    "test.vardenisiffror": "https://test-api.vardenisiffror.se",
    "qa.vardenisiffror": "https://staging-api.vardenisiffror.se",
    "staging.vardenisiffror": "https://staging-api.vardenisiffror.se",
    vardenisiffror: "https://api.vardenisiffror.se"
  };
  var environment = Object.keys(environments).find(function (key) {
    return location.host.includes(key);
  });
  return environments[environment];
};
export var webApiServiceUrl = "".concat(apiServiceUrl(), "/webapi");
export var mediaApiServiceUrl = "".concat(apiServiceUrl(), "/media");
export var cmsApiServiceUrl = "".concat(apiServiceUrl(), "/cms");
export var authApiServiceUrl = "".concat(apiServiceUrl(), "/auth");
//export const authApiServiceUrl = `http://localhost:5001/auth`

export var bvoServiceUrl = function bvoServiceUrl() {
  var environments = {
    "localhost:443": "https://authtest.vardenisiffror.se",
    "vis.local": "https://authtest.vardenisiffror.se",
    "dev.vardenisiffror": "https://authtest.vardenisiffror.se",
    "test.vardenisiffror": "https://authtest.vardenisiffror.se",
    "qa.vardenisiffror": "https://auth.vardenisiffror.se",
    "staging.vardenisiffror": "https://auth.vardenisiffror.se",
    vardenisiffror: "https://auth.vardenisiffror.se"
  };
  var environment = Object.keys(environments).find(function (key) {
    return location.host.includes(key);
  });
  return environments[environment];
};