import styled from "styled-components";
export var Label = styled.label.withConfig({
  displayName: "rangeCommonstyles__Label",
  componentId: "sc-17ezmyg-0"
})(["", ""], function (_ref) {
  var theme = _ref.theme;
  return "\n    font-family: ".concat(theme.fonts.robotoRegular, ";\n    font-size: ").concat(theme.fontSizes.xs, ";\n    height: ").concat(theme.fontSizes.xs, ";\n    color: ").concat(theme.colors.G1, ";\n    margin: auto 10px;");
});
export var rangeStyles = function rangeStyles(theme, disabled) {
  return "\n  appearance: none;\n  height: 22px;\n  outline: none;\n  margin: 0;\n\n  &::-webkit-slider-thumb {\n    appearance: none;\n    position: relative;\n    width: 22px;\n    height: 21px;\n\n    cursor: ".concat(!disabled ? "pointer" : "initial", ";\n    transition: all .3s;\n    background: ").concat(disabled ? "".concat(theme.colors.P2, "80") : theme.colors.i2, ";\n    border: 4px solid ").concat(theme.colors.G1, ";\n\n    ").concat(!disabled ? "\n      &:hover {\n        z-index: 9;\n        border: 4px solid ".concat(theme.colors.G1, ";\n        background: ").concat(theme.colors.i4, ";\n        box-shadow: 0 0 0 4px ").concat(theme.colors.i1, ";\n      }\n      ") : "", "\n  }\n\n  &:focus::-webkit-slider-thumb {\n    z-index: 9;\n    border: 4px solid ").concat(theme.colors.G1, ";\n    box-shadow: 0 0 0 4px ").concat(theme.colors.i1, ";\n  }");
};