import styled from "styled-components";
import { header2, hiddenStyles, extendedSystemStyles } from "@mixins";
var styledSystemKeys = ["space", "layout", "color", "typography"];
export default styled.h2.withConfig({
  displayName: "Header2",
  componentId: "sc-1j2wpf8-0"
})(["", " ", ""], function (_ref) {
  var theme = _ref.theme,
    inverted = _ref.inverted,
    visuallyHidden = _ref.visuallyHidden;
  return "\n    ".concat(header2(theme), "\n    color: ").concat(!inverted ? theme.colors.G4 : theme.colors.G1, ";\n    margin: 5px 0 15px;\n\n    ").concat(visuallyHidden ? hiddenStyles : "");
}, extendedSystemStyles(styledSystemKeys));