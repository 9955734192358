import styled from "styled-components";
import { header1, extendedSystemStyles } from "@mixins";
var styledSystemKeys = ["space", "layout", "color", "typography"];
export default styled.h1.withConfig({
  displayName: "Header1",
  componentId: "sc-1i1fe64-0"
})(["", " ", ""], function (_ref) {
  var theme = _ref.theme,
    inverted = _ref.inverted;
  return "\n    ".concat(header1(theme), "\n    color: ").concat(!inverted ? theme.colors.G4 : theme.colors.G1, ";\n    margin: 5px 0 15px;");
}, extendedSystemStyles(styledSystemKeys));