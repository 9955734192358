import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from "react";
import useCustomEffect from "./useCustomEffect";
export default function useOpen(_open) {
  var _useState = useState(_open),
    _useState2 = _slicedToArray(_useState, 2),
    open = _useState2[0],
    setOpen = _useState2[1];
  var toggleOpen = function toggleOpen() {
    return setOpen(function (old) {
      return !old;
    });
  };
  useCustomEffect(function () {
    setOpen(_open);
  }, [_open]);
  return {
    open: open,
    toggleOpen: toggleOpen,
    setOpen: setOpen
  };
}