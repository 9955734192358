export default function useLeftRightArrow(selectable, setActiveIdx) {
  var onKeyDown = function onKeyDown(e) {
    // ArrowLeft
    if (e.keyCode === 37) {
      setActiveIdx(function (old) {
        var newIndex = old > 0 ? old - 1 : selectable.length - 1;
        document.getElementById(selectable[newIndex].id).focus();
        return newIndex;
      });
    }
    // ArrowRight
    else if (e.keyCode === 39) {
      setActiveIdx(function (old) {
        var newIndex = old < selectable.length - 1 ? old + 1 : 0;
        document.getElementById(selectable[newIndex].id).focus();
        return newIndex;
      });
    }

    //Home
    if (e.keyCode === 36) {
      document.getElementById(selectable[0].id).focus();
      setActiveIdx(0);
    }
    //End
    if (e.keyCode === 35) {
      document.getElementById(selectable[selectable.length - 1].id).focus();
      setActiveIdx(selectable.length - 1);
    }
  };
  return {
    onKeyDown: onKeyDown
  };
}