import styled from "styled-components";
import { extendedSystemStyles, hoverStyle, focusStyle, mainText, addIcon } from "@mixins";
var styledSystemKeys = ["space", "color", "layout", "flexbox", "position", "border"];
export var SelectInput = styled.select.withConfig({
  displayName: "SelectInput",
  componentId: "sc-lqwqw-0"
})(["&:after{", " ", "}", " ", ""], function (_ref) {
  var theme = _ref.theme;
  return addIcon(theme, "expandDown");
}, function (_ref2) {
  var theme = _ref2.theme,
    disabled = _ref2.disabled,
    inverted = _ref2.inverted;
  return "\n      position: absolute;\n      top: calc(50% - 1.3rem);\n      left: 15px;\n      color: ".concat(disabled ? "".concat(theme.colors.P2, "a8") : inverted ? "".concat(theme.colors.G3, "d9") : theme.colors.P3, ";\n    ");
}, function (_ref3) {
  var theme = _ref3.theme,
    disabled = _ref3.disabled,
    inverted = _ref3.inverted,
    error = _ref3.error;
  return "\n    appearance: none;\n    -webkit-appearance: none;\n    -moz-appearance: none;\n\n    position: relative;\n\n    height: ".concat(theme.actionStyles.height, ";\n    width: 100%;\n    padding: 10px 20px;\n    ").concat(mainText(theme), "\n\n    color: ").concat(disabled ? "".concat(theme.colors.P2, "a8") : theme.colors.P2, ";\n    background: ").concat(disabled ? theme.colors.G3 : "inherit", ";\n\n    border-radius: ").concat(theme.actionStyles.buttonRadius, ";\n    box-shadow: ").concat(!disabled ? error ? "inset 0 0 0 3px ".concat(theme.colors.V3) : "inset 0 0 0 3px ".concat(theme.colors.i1) : "inset 0 0 0 3px ".concat(theme.colors.i1, "80"), ";\n\n    transition: all .2s;\n\n    ").concat(!disabled ? "\n      cursor: pointer;\n\n      &:hover { ".concat(hoverStyle(theme), " }\n\n      &:focus {\n        background: inherit;\n        ").concat(focusStyle(theme), "\n      }\n    ") : "", "\n\n    ::-webkit-input-placeholder, :placeholder, ::-ms-input-placeholder {\n      color: ").concat(!disabled ? "".concat(theme.colors.P2, "a8") : "".concat(theme.colors.P2, "80"), ";\n    }\n\n    ").concat(inverted ? "color: ".concat(theme.colors.G1, ";") : "", "\n\n  ");
}, extendedSystemStyles(styledSystemKeys));
export var SelectOption = styled.option.withConfig({
  displayName: "SelectInput__SelectOption",
  componentId: "sc-lqwqw-1"
})(["", ""], extendedSystemStyles(styledSystemKeys));