import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useEffect } from "react";
export default function useElementWidth(element) {
  var _useState = useState(0),
    _useState2 = _slicedToArray(_useState, 2),
    elWidth = _useState2[0],
    setElWidth = _useState2[1];
  useEffect(function () {
    var resizer = function resizer(entries) {
      setElWidth(entries[0].contentRect.width);
    };
    var resizeObserver = new ResizeObserver(resizer);
    if (element) resizeObserver.observe(element.current);
    return function () {
      resizeObserver.disconnect();
    };
  }, [element]);
  return elWidth;
}