import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { v4 as uniqueId } from "uuid";
import { omit, pipe } from "ramda";
import slugify from "slugify";
export var newLink = function newLink() {
  return {
    id: uniqueId(),
    type: "href",
    title: "",
    to: ""
  };
};
export var adjustCompositionSlug = function adjustCompositionSlug(locked, x) {
  return !locked && x.title ? _objectSpread(_objectSpread({}, x), {}, {
    slug: slugify(x.title, {
      lower: true,
      remove: /[*+~.()'"!:@]/g
    })
  }) : x;
};
export var removeTypenames = function removeTypenames(nextProp, nextFunc, x) {
  return x[nextProp] ? omit(["__typename"], _objectSpread(_objectSpread({}, x), {}, _defineProperty({}, nextProp, x[nextProp].map(nextFunc)))) : omit(["__typename"], x);
};
export var removeTypename = function removeTypename(nextProp, nextFunc, x) {
  return x[nextProp] ? omit(["__typename"], _objectSpread(_objectSpread({}, x), {}, _defineProperty({}, nextProp, nextFunc(x[nextProp])))) : omit(["__typename"], x);
};
export var removeTypenamesFromComponent = function removeTypenamesFromComponent(x) {
  var component = omit(["__typename"], x);
  if (component.hyperlinks) component.hyperlinks = component.hyperlinks.map(omit(["__typename"]));
  if (component.measureSpotlights) component.measureSpotlights = component.measureSpotlights.map(omit(["__typename"]));
  if (component.eventParams) component.eventParams = omit(["__typename"], component.eventParams);
  return component;
};
export var removeTypenameFromCategory = function removeTypenameFromCategory(x) {
  return removeTypename(null, function (x) {
    return x;
  }, x);
};
export var removeTypenameFromMetadata = function removeTypenameFromMetadata(x) {
  return removeTypename("category", removeTypenameFromCategory, x);
};
export var removeTypenamesFromGrid = function removeTypenamesFromGrid(x) {
  return removeTypenames("components", removeTypenamesFromComponent, x);
};
export var removeTypenamesFromChapter = function removeTypenamesFromChapter(x) {
  return removeTypenames("grids", removeTypenamesFromGrid, x);
};
export var removeTypenamesFromView = function removeTypenamesFromView(x) {
  return removeTypenames("chapters", removeTypenamesFromChapter, x);
};
export var removeTypenamesFromComposition = pipe(function (x) {
  return removeTypenames("views", removeTypenamesFromView, x);
}, function (x) {
  return removeTypename("metadata", removeTypenameFromMetadata, x);
}, function (x) {
  return removeTypename("pageHeader", removeTypenamesFromGrid, x);
});
export var addTypenames = function addTypenames(nextProp, nextFunc, __typename, x) {
  return x[nextProp] ? _objectSpread(_objectSpread({}, x), {}, _defineProperty({
    __typename: __typename
  }, nextProp, x[nextProp].map(nextFunc))) : _objectSpread(_objectSpread({}, x), {}, {
    __typename: __typename
  });
};
export var addTypename = function addTypename(nextProp, nextFunc, __typename, x) {
  return x[nextProp] ? _objectSpread(_objectSpread({}, x), {}, _defineProperty({
    __typename: __typename
  }, nextProp, nextFunc(x[nextProp]))) : _objectSpread(_objectSpread({}, x), {}, {
    __typename: __typename
  });
};
export var addTypenameToCategory = function addTypenameToCategory(x) {
  return addTypename(null, function (x) {
    return x;
  }, "Category", x);
};
export var addTypenamesToComponent = function addTypenamesToComponent(x) {
  var component = _objectSpread(_objectSpread({}, x), {}, {
    __typename: "Component"
  });
  if (component.hyperlinks) component.hyperlinks = component.hyperlinks.map(function (x) {
    return _objectSpread(_objectSpread({}, x), {}, {
      __typename: "Hyperlink"
    });
  });
  if (component.measureSpotlights) component.measureSpotlights = component.measureSpotlights.map(function (x) {
    return _objectSpread(_objectSpread({}, x), {}, {
      __typename: "MeasureSpotlight"
    });
  });
  if (component.eventParams) component.eventParams = _objectSpread(_objectSpread({}, component.eventParams), {}, {
    __typename: "EventParams"
  });
  return component;
};
export var addTypenameToMetadata = function addTypenameToMetadata(x) {
  return addTypename("category", addTypenameToCategory, "Metadata", x);
};
export var addTypenamesToGrid = function addTypenamesToGrid(x) {
  return addTypenames("components", addTypenamesToComponent, "Grid", x);
};
export var addTypenamesToChapter = function addTypenamesToChapter(x) {
  return addTypenames("grids", addTypenamesToGrid, "Chapter", x);
};
export var addTypenamesToView = function addTypenamesToView(x) {
  return addTypenames("chapters", addTypenamesToChapter, "View", x);
};
export var addTypenamesToComposition = pipe(function (x) {
  return addTypenames("views", addTypenamesToView, "Composition", x);
}, function (x) {
  return addTypename("metadata", addTypenameToMetadata, "Composition", x);
});

/*
  When sending a graphql request to the server __typename needs to be stripped from the payload
*/
export var removeIdsAndTypenamesFromComponent = function removeIdsAndTypenamesFromComponent(x) {
  var component = omit(["id", "__typename"], x);
  if (component.hyperlinks) component.hyperlinks = component.hyperlinks.map(omit(["id", "__typename"]));
  if (component.measureSpotlights) component.measureSpotlights = component.measureSpotlights.map(omit(["id", "__typename"]));
  if (component.eventParams) component.eventParams = omit(["id", "__typename"], component.eventParams);
  return component;
};
export var addIdsToComponent = function addIdsToComponent(x) {
  var component = _objectSpread(_objectSpread({}, x), {}, {
    id: uniqueId()
  });
  if (["HIGHLIGHTS", "LINKS"].includes(component.type)) {
    component.hyperlinks = component.hyperlinks.map(function (hyperlink) {
      return _objectSpread(_objectSpread({}, hyperlink), {}, {
        id: uniqueId()
      });
    });
  }
  if (["MEASURE_SPOTLIGHTS"].includes(component.type)) {
    component.measureSpotlights = component.measureSpotlights.map(function (measureSpotlight) {
      return _objectSpread(_objectSpread({}, measureSpotlight), {}, {
        id: uniqueId()
      });
    });
  }
  return component;
};

/*
  In order for graphql to be able to do an optimistic update it needs to have access to all enteties __typename
*/