import _typeof from "@babel/runtime/helpers/typeof";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from "react";
import FileSaver from "file-saver";
import domtoimage from "dom-to-image";
import { useMatomo } from "@datapunt/matomo-tracker-react";
export default function useExportPng(entry) {
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    error = _useState2[0],
    setError = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    loading = _useState4[0],
    setLoading = _useState4[1];
  var _useMatomo = useMatomo(),
    trackEvent = _useMatomo.trackEvent;
  var preAdjustHtml = function preAdjustHtml() {
    var _Array$from, _Array$from2, _Array$from3;
    (_Array$from = Array.from(document.getElementsByClassName("export-png-holder"))) === null || _Array$from === void 0 ? void 0 : _Array$from.forEach(function (el) {
      return el.setAttribute("style", "background: white;");
    });
    (_Array$from2 = Array.from(document.getElementsByClassName("remove-pre-png"))) === null || _Array$from2 === void 0 ? void 0 : _Array$from2.forEach(function (el) {
      return el.classList.add("hidden-styles");
    });
    (_Array$from3 = Array.from(document.getElementsByClassName("add-pre-png"))) === null || _Array$from3 === void 0 ? void 0 : _Array$from3.forEach(function (el) {
      return el.setAttribute("style", "display: flex;");
    });
  };
  var postAdjustHtml = function postAdjustHtml() {
    var _Array$from4, _Array$from5, _Array$from6;
    (_Array$from4 = Array.from(document.getElementsByClassName("export-png-holder"))) === null || _Array$from4 === void 0 ? void 0 : _Array$from4.forEach(function (el) {
      return el.setAttribute("style", "background: none;");
    });
    (_Array$from5 = Array.from(document.getElementsByClassName("remove-pre-png"))) === null || _Array$from5 === void 0 ? void 0 : _Array$from5.forEach(function (el) {
      return el.classList.remove("hidden-styles");
    });
    (_Array$from6 = Array.from(document.getElementsByClassName("add-pre-png"))) === null || _Array$from6 === void 0 ? void 0 : _Array$from6.forEach(function (el) {
      return el.setAttribute("style", "display: none;");
    });
  };
  var filter = function filter(node) {
    var _node$className;
    if (_typeof(node.className) === "object") return true;
    return !((_node$className = node.className) !== null && _node$className !== void 0 && _node$className.includes("png-ignore"));
  };
  var captureScreen = function captureScreen(pngRef, fileName) {
    if (!loading) setLoading(true);
    preAdjustHtml();
    var pngBody = pngRef.current.getBoundingClientRect();
    var width = 3100;
    var factor = width / pngBody.width;
    var height = factor * pngBody.height;
    domtoimage.toBlob(pngRef.current, {
      filter: filter,
      style: {
        width: pngBody.width,
        height: pngBody.height,
        transform: "scale(".concat(factor, ")"),
        "transform-origin": "top left"
      },
      height: height,
      width: width
    }).then(function (blob) {
      setLoading(false);
      postAdjustHtml();
      if (entry) trackEvent({
        category: "Png-export",
        action: entry,
        name: "respons - 200"
      });
      FileSaver.saveAs(blob, "".concat(fileName, ".png"));
    }).catch(function (e) {
      if (entry) trackEvent({
        category: "Png-export",
        action: entry,
        name: "respons - 500"
      });
      setError(e);
    });
  };
  return {
    error: error,
    loading: loading,
    captureScreen: captureScreen
  };
}