import axios from "axios";
import { prop } from "ramda";
import queryString from "query-string";
import { webApiServiceUrl } from "@microServices";

/*
The api searches amongst measureEvents produced by BE.
By default it will fetch the 100 latest events.
*/
export default function searchMeasureEvents(searchQuery, authTicket) {
  return axios.get("".concat(webApiServiceUrl, "/api/searchmeasureevents/events?").concat(queryString.stringify(searchQuery)), {
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
}