import { useEffect, useRef } from "react";
import { equals } from "ramda";

/*
Reacts own useEffect only does a shallow reference equals in their useEffect,
that is {} === {}
Many components aquire objects from their parent that they want to update their own state/fetch data based on,
in these cases react will think that the object changes all the time which doesnt work for us.
This wrapper makes it so that the useEffect will only run when deepequal changes, in other words it will compare
the actual keys+values of the object/array and so on.
*/
export default function useCustomEffect() {
  var fn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {};
  var deps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var isFirst = useRef(true);
  var prevDeps = useRef(deps);
  useEffect(function () {
    var isFirstEffect = isFirst.current;
    var isSameDeps = equals(prevDeps.current, deps);
    isFirst.current = false;
    prevDeps.current = deps;
    if (isFirstEffect || !isSameDeps) {
      return fn();
    }
  }, [fn, deps]);
}