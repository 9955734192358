import styled from "styled-components";
import { extendedSystemStyles, addIcon, focusStyle } from "@mixins";
export var layoutStyledSystemKeys = ["space", "layout"];
export var gridStyledSystemKeys = ["grid"];
export var listItemWidth = 400;
export var StyledGrid = styled.ol.withConfig({
  displayName: "carouselstyles__StyledGrid",
  componentId: "sc-1qbifas-0"
})(["display:grid;overflow-x:auto;overflow-y:hidden;width:fit-content;", " ", " & > li{width:", "px;min-width:", "px;}&::-webkit-scrollbar{width:0;height:0;}", ""], extendedSystemStyles(gridStyledSystemKeys), function (_ref) {
  var offset = _ref.offset;
  return "\n  transition: transform .3s;\n  transform: translateX(".concat(offset, "px);\n");
}, listItemWidth, listItemWidth, function (_ref2) {
  var theme = _ref2.theme;
  return "\n  @media(max-width: ".concat(theme.breakpoints[2], "){\n    width: 100%;\n  }\n");
});
export var ScrollButton = styled.button.withConfig({
  displayName: "carouselstyles__ScrollButton",
  componentId: "sc-1qbifas-1"
})(["width:60px;height:100%;background:rgba(0,0,0,0.4);position:absolute;top:0;z-index:1000;color:white;font-weight:bold;cursor:pointer;&:focus{", " position:absolute;}&.prev{&:before{", "}left:0px;}&.next{right:0px;&:before{", "}}", ""], function (_ref3) {
  var theme = _ref3.theme;
  return focusStyle(theme);
}, function (_ref4) {
  var theme = _ref4.theme;
  return addIcon(theme, "expandLeft");
}, function (_ref5) {
  var theme = _ref5.theme;
  return addIcon(theme, "expandRight");
}, function (_ref6) {
  var theme = _ref6.theme;
  return "\n  @media(max-width: ".concat(theme.breakpoints[2], "){\n    display: none;\n  }\n");
});