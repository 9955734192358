import styled from "styled-components";
import { extendedSystemStyles } from "@mixins";
import { opacityInAnim } from "@animation-ui";
var styledSystemKeys = ["space", "layout", "grid"];
export default styled.ol.withConfig({
  displayName: "R1C1_1",
  componentId: "sc-kjquam-0"
})(["display:block;width:100%;animation:", " 1s;", " ", ""], opacityInAnim, function (_ref) {
  var theme = _ref.theme,
    breakpoint = _ref.breakpoint;
  return "\n\n  @media (max-width: ".concat(theme.breakpoints[breakpoint], "){\n    display: grid;\n    grid-template-columns: 1fr;\n\n    img {\n      padding: 0;\n    }\n  }");
}, extendedSystemStyles(styledSystemKeys));