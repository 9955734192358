import styled from "styled-components";
import fadeIn from "@animation-ui/fadeIn";
import { addIcon, hoverStyle, focusStyle } from "@mixins";
export var Warning = styled.span.withConfig({
  displayName: "styles__Warning",
  componentId: "sc-m6okgk-0"
})(["&:before{", "}animation:", " 0.3s linear;margin:auto 10px auto 0;font-size:2.5rem;color:", ";"], function (_ref) {
  var theme = _ref.theme;
  return addIcon(theme, "warning");
}, fadeIn, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.G1;
});
export var Close = styled.span.withConfig({
  displayName: "styles__Close",
  componentId: "sc-m6okgk-1"
})(["&:before{", "}font-size:2.5rem;padding:12px;height:49px;margin:auto 0 auto auto;color:", ";transition:all 0.3s;&:hover{", "}&:focus{", "}"], function (_ref3) {
  var theme = _ref3.theme;
  return addIcon(theme, "cross");
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.G1;
}, function (_ref5) {
  var theme = _ref5.theme;
  return hoverStyle(theme);
}, function (_ref6) {
  var theme = _ref6.theme;
  return focusStyle(theme);
});
export var ErrorHolder = styled.div.withConfig({
  displayName: "styles__ErrorHolder",
  componentId: "sc-m6okgk-2"
})(["animation:", " 0.3s linear;display:flex;width:100%;padding-left:15px;height:50px;border-top:1px solid ", ";background:", ";align-items:center;"], fadeIn, function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.G1;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.colors.V3;
});