import { curry, lensProp, includes, set, view } from "ramda";
export var mapKeys = curry(function (func, keys, obj) {
  return keys.filter(function (x) {
    return includes(x, Object.keys(obj));
  }).map(function (key) {
    return {
      lens: lensProp(key),
      key: key
    };
  }).reduce(function (acc, _ref) {
    var lens = _ref.lens,
      key = _ref.key;
    return set(lens, func(view(lens, obj), key), acc);
  }, obj);
});
export default mapKeys;