import cookies from "js-cookie";
import { v4 as uniqueId } from "uuid";
import { createInstance } from "@datapunt/matomo-tracker-react";
var testId = 1;
var prodId = 4;
export var createMatomoInstance = function createMatomoInstance() {
  var uid = cookies.get("visUid");
  if (!uid) {
    uid = uniqueId();
    cookies.set("visUid", uid);
  }
  var siteIds = {
    "vis.local": testId,
    "dev.vardenisiffror.se": testId,
    "test.vardenisiffror.se": testId,
    "qa.vardenisiffror.se": testId,
    "staging.vardenisiffror.se": testId
  };
  var siteId = siteIds[Object.keys(siteIds).find(function (x) {
    return location.origin.includes(x);
  })] || prodId;
  return createInstance({
    urlBase: "https://vardenisiffror.matomo.cloud/",
    siteId: siteId,
    userId: uid,
    // optional, default value: `undefined`.
    trackerUrl: "https://vardenisiffror.matomo.cloud/matomo.php",
    // optional, default value: `${urlBase}matomo.php`
    srcUrl: "//cdn.matomo.cloud/vardenisiffror.matomo.cloud/matomo.js",
    // optional, default value: `${urlBase}matomo.js`
    linkTracking: false,
    // optional, default value: true
    configurations: {
      setCookiePath: location.origin
    }
  });
};