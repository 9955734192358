import { useEffect, useRef } from "react";
import { blockDefaultEvent } from "utils/events";
export default function useLockedFocusArea(containerRef, firstRef, lastRef) {
  var keysPressed = useRef({});
  useEffect(function () {
    var _firstRef$current;
    var copiedContainerRef = containerRef.current;
    (_firstRef$current = firstRef.current) === null || _firstRef$current === void 0 ? void 0 : _firstRef$current.focus();
    var isActive = function isActive(el) {
      return document.activeElement === el;
    };
    var keydown = function keydown(e) {
      //9 = Tab, 16 = shift
      keysPressed.current[e.keyCode] = true;
      if (keysPressed.current[16] && keysPressed.current[9] && isActive(firstRef.current)) {
        blockDefaultEvent(e);
        lastRef.current.focus();
      } else if (!keysPressed.current[16] && keysPressed.current[9] && isActive(lastRef.current)) {
        blockDefaultEvent(e);
        firstRef.current.focus();
      }
    };
    var keyup = function keyup(e) {
      return delete keysPressed.current[e.keyCode];
    };
    copiedContainerRef.addEventListener("keydown", keydown);
    copiedContainerRef.addEventListener("keyup", keyup);
    return function () {
      copiedContainerRef === null || copiedContainerRef === void 0 ? void 0 : copiedContainerRef.removeEventListener("keydown", keydown);
      copiedContainerRef === null || copiedContainerRef === void 0 ? void 0 : copiedContainerRef.removeEventListener("keyup", keyup);
    };
  }, [containerRef, firstRef, lastRef]);
}