export default {
  //brand
  P0: "#1C3236",
  P1: "#29484D",
  P2: "#30545A",
  P3: "#406E75",
  //interaction
  i1: "#67B7C3",
  i2: "#61C8D8",
  i3: "#82E0EF",
  i4: "#A4DAE2",
  i5: "#EDF7F7",
  //validation
  V1: "#00998D",
  V2: "#E3A250",
  V3: "#B54949",
  V3_2: "#8a3030",
  V4: "#EB4040",
  //tabels
  T1: "#C4E3CD",
  T2: "#F2D5B0",
  T3: "#E9C8C8",
  //graphColors
  C1: "#008058",
  C2: "#528E00",
  C3: "#BEAF01",
  C4: "#D17B0D",
  C5: "#BF3E05",
  C6: "#911348",
  C7: "#910091",
  C8: "#4944CB",
  C9: "#0081C6",
  C10: "#00A9BF",
  //Grayscale
  G1: "#FFFFFF",
  G2: "#f6f9fc",
  G3: "#f2f2f2",
  G4: "#000000",
  G5: "#8C8C8C",
  opacitySuffix: ["",
  //0
  "1a",
  //.1
  "33",
  //.2
  "4d",
  //.3
  "66",
  //.4
  "80",
  //.5
  "99",
  //.6
  "b3",
  //.7
  "cc",
  //.8
  "e6" //.9
  ]
};