import styled from "styled-components";
import { addIcon, hoverStyle_noBorder, focusStyle, extendedSystemStyles, activeStyle } from "@mixins";
export var styledSystemKeys = ["space", "layout", "flexbox"];
export var StyledAccordion = styled.section.withConfig({
  displayName: "accordionstyles__StyledAccordion",
  componentId: "sc-suj5yw-0"
})(["", "", ""], function (_ref) {
  var theme = _ref.theme,
    borderBottom = _ref.borderBottom;
  return "\n  ".concat(borderBottom ? "border-bottom: 2px solid ".concat(theme.colors.i1, ";") : 'none', "\n  background: ").concat(theme.colors.i5, ";");
}, extendedSystemStyles(styledSystemKeys));
export var HeaderContainer = styled.button.withConfig({
  displayName: "accordionstyles__HeaderContainer",
  componentId: "sc-suj5yw-1"
})(["&:before{", "}&:after{padding-left:15px;", "}", ""], function (_ref2) {
  var theme = _ref2.theme,
    icon = _ref2.icon,
    minHeight = _ref2.minHeight;
  return addIcon(theme, icon, minHeight ? "11px 10px auto 0" : "18px 10px auto 0");
}, function (_ref3) {
  var theme = _ref3.theme,
    open = _ref3.open;
  return !open ? addIcon(theme, "expandDown", "auto 0 auto auto") : addIcon(theme, "expandUp", "auto 0 auto auto");
}, function (_ref4) {
  var theme = _ref4.theme,
    open = _ref4.open,
    background = _ref4.background,
    minHeight = _ref4.minHeight,
    borderTop = _ref4.borderTop;
  return "\n    min-height: ".concat(minHeight ? minHeight : "60px", ";\n    width: 100%;\n    display: flex;\n    padding: 0 20px;\n    text-align: left;\n    align-items: center;\n    font-size: inherit;\n\n    outline: none;\n    border-top: ").concat(borderTop ? borderTop : "none", ";\n    background: ").concat(background ? background : "none", ";\n    transition: all .3s;\n\n    &:focus { ").concat(focusStyle(theme), " }\n    &:hover { ").concat(hoverStyle_noBorder(theme), " }\n\n    ").concat(open ? activeStyle(theme, background) : "");
});
export var ContentContainer = styled.div.withConfig({
  displayName: "accordionstyles__ContentContainer",
  componentId: "sc-suj5yw-2"
})(["", ""], function (_ref5) {
  var open = _ref5.open,
    limitedHeight = _ref5.limitedHeight,
    background = _ref5.background,
    padding = _ref5.padding;
  return "\n    padding: ".concat(open ? padding ? padding : "15px 20px" : "0 20px", ";\n    max-height: ").concat(open ? "auto" : 0, ";\n    opacity: ").concat(open ? 1 : 0, ";\n    visibility: ").concat(open ? "visible" : "hidden", ";\n    transition: padding .3s, max-height .3s, opacity .3s;\n    background: ").concat(background ? "white" : "none", ";\n    ").concat(limitedHeight ? "\n      max-height: 350px;\n      height: auto;\n      overflow: auto;\n    " : "", "\n  ");
});