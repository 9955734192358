import axios from "axios";
import { prop } from "ramda";
import { webApiServiceUrl } from "@microServices";
export default function getHealthcheck() {
  return axios.get("".concat(webApiServiceUrl, "/api/health/check"), {
    headers: {
      "Content-Type": "application/json"
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
}