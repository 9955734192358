var networkErrors = {
  "Internal Server Error": "Internt serverfel",
  500: "Internt serverfel",
  "Error: TypeError: Failed to fetch": "Nätverksproblem",
  "[Network] Failed to fetch": "Lyckades inte hämta resursen, tjänsten som anropas är nere",
  ERR_INTERNET_DISCONNECTED: "Nätverksproblem",
  ERR_EMPTY_RESPONSE: "Servern svarar inte",
  "Request Entity Too Large": "Datamängden är för stor",
  "An error has occurred. Object reference not set to an instance of an object. ": "An error has occurred. Object reference not set to an instance of an object",
  "Not found": "Kunde inte hitta resursen",
  404: "Kunde inte hitta resursen",
  "operator does not exist": "Kan inte utföra operationen",
  "Access denied": "Åtkomst nekad. Endast inloggade har tillgång till den efterfrågade resursen. Vid timeout testa att logga in igen i en annan flik.",
  Forbidden: "Åtkomst nekad. Endast inloggade har tillgång till funktionaliteten på denna sida",
  "The current user is not authorized": "Åtkomst nekad. Endast inloggade har tillgång till den efterfrågade resursen. Vid timeout testa att logga in igen i en annan flik.",
  "Bad Request": "Dålig förfrågan. Något är fel i förfrågan till servern."
};
export var translateError = function translateError(error) {
  var activeError = Object.keys(networkErrors).find(function (x) {
    return "".concat(error).toLowerCase().includes(x.toLowerCase()) || x.toLowerCase().includes("".concat(error).toLowerCase());
  });
  return networkErrors[activeError] || error || "Okänt fel";
};
export var pathErrors = {
  exists: "Url pathen existerar redan i systemet",
  empty: "Fältet kan inte vara tomt, ange URL"
};