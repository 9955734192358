import styled from "styled-components";
import PropTypes from "prop-types";
import { focusStyle, activeStyle, hoverStyle, addIcon, extendedSystemStyles } from "@mixins";
var styledSystemKeys = ["space", "layout", "flexbox"];
var PrimaryButton = styled.button.attrs({
  "data-cy": "primary-button"
}).withConfig({
  displayName: "PrimaryButton",
  componentId: "sc-trh7na-0"
})(["&:before{", "}&:after{", "}", " ", ""], function (_ref) {
  var iconBefore = _ref.iconBefore,
    theme = _ref.theme;
  return addIcon(theme, iconBefore, "auto 10px auto 0");
}, function (_ref2) {
  var iconAfter = _ref2.iconAfter,
    theme = _ref2.theme;
  return addIcon(theme, iconAfter, "auto 0 auto 10px");
}, function (_ref3) {
  var theme = _ref3.theme,
    active = _ref3.active,
    disabled = _ref3.disabled,
    blocked = _ref3.blocked,
    _ref3$variant = _ref3.variant,
    variant = _ref3$variant === void 0 ? "default" : _ref3$variant;
  return "\n    height: ".concat(theme.actionStyles.height, ";\n    font-family: ").concat(theme.fonts.robotoRegular, ";\n    font-size: ").concat(theme.fontSizes[theme.actionStyles.fontSize], ";\n    line-height: ").concat(theme.actionStyles.height, ";\n\n    display: flex;\n    justify-content: center;\n\n    width: 210px;\n    padding: 0;\n    border-radius: ").concat(theme.actionStyles.buttonRadius, ";\n    box-shadow: ").concat(!disabled ? variant === "danger" ? "inset 0 0 0 1px ".concat(theme.colors.V3) : "inset 0 0 0 1px ".concat(theme.colors.i1) : "", ";\n\n    background: ").concat(variant === "danger" ? theme.colors.V3 : disabled && !active ? "".concat(theme.colors.P2, "80") : theme.colors.P2, ";\n    color: ").concat(theme.colors.G1, ";\n\n    cursor: ").concat(blocked ? "not-allowed" : "", ";\n\n    outline: none;\n    border: none;\n    transition: all .3s;\n\n    ").concat(!active && !disabled && !blocked ? "\n      &:hover{ ".concat(hoverStyle(theme, variant), " }\n      &:focus{ ").concat(focusStyle(theme, variant), " }\n    ") : "", "\n\n    ").concat(active ? activeStyle(theme) : "");
}, extendedSystemStyles(styledSystemKeys));
PrimaryButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  styles: PropTypes.object,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  blocked: PropTypes.bool,
  iconBefore: PropTypes.string,
  iconAfter: PropTypes.string,
  variant: PropTypes.oneOf(["default", "danger"])
};
export default PrimaryButton;