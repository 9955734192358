export var allGraphTypes = [{
  id: "timeline",
  label: "Tidslinje",
  icon: "lineGraph"
}, {
  id: "barchart",
  label: "Stapeldiagram",
  icon: "barChart"
}, {
  id: "deviation",
  label: "Avvikelsediagram",
  icon: "deviationChart"
}, {
  id: "heatchart",
  label: "Tabelldiagram",
  icon: "heatChart"
}, {
  id: "spider",
  label: "Spindeldiagram",
  icon: "spiderChart"
}, {
  id: "stackedarea",
  label: "Staplat ytdiagram",
  icon: "stackedBarchart"
}, {
  id: "area",
  label: "Ytdiagram",
  icon: "stackedLineGraph"
}, {
  id: "donut",
  label: "Ringdiagram",
  icon: "pieChart"
}, {
  id: "cake",
  label: "Cirkeldiagram",
  icon: "pieChart"
}];