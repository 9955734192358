import styled from "styled-components";
import { hoverStyle, activeStyle, addIcon, hiddenStyles, ellipsis, colorFullSroll, mainText } from "@mixins";
export var Label = styled.p.withConfig({
  displayName: "selectLinkstyles__Label",
  componentId: "sc-jizpgr-0"
})(["", ""], function (_ref) {
  var theme = _ref.theme,
    hideLabel = _ref.hideLabel,
    disabled = _ref.disabled,
    inverted = _ref.inverted;
  return "\n    font-family: ".concat(theme.fonts.robotoMedium, ";\n    font-size: ").concat(theme.fontSizes.xs, ";\n    line-height: ").concat(theme.lineHeight.xs, ";\n\n    color: ").concat(disabled ? "".concat(theme.colors.P2, "a8") : inverted ? theme.colors.G1 : theme.colors.G4, ";\n    font-weight: 100;\n    margin: 6px 0;\n\n    ").concat(hideLabel ? hiddenStyles : "");
});
export var SelectButton = styled.button.withConfig({
  displayName: "selectLinkstyles__SelectButton",
  componentId: "sc-jizpgr-1"
})(["&:after{position:absolute;right:20px;top:calc(50% - 10px);", "}", ""], function (_ref2) {
  var theme = _ref2.theme,
    open = _ref2.open;
  return open ? addIcon(theme, "expandUp", "auto 0 auto auto") : addIcon(theme, "expandDown", "auto 0 auto auto");
}, function (_ref3) {
  var theme = _ref3.theme,
    open = _ref3.open,
    inverted = _ref3.inverted;
  return "\n    ".concat(mainText(theme), "\n    display: inline-block;\n    height: 44px;\n    padding: 0 55px 0 20px;\n    text-align: left;\n    background: ").concat(inverted ? theme.colors.P3 : theme.colors.G1, ";\n    color: ").concat(inverted ? theme.colors.G1 : theme.colors.G4, ";\n\n    border-radius: 3px;\n    box-shadow: inset 0 0 0 3px ").concat(theme.colors.i1, ";\n    outline: none;\n    border: none;\n    transition: all .3s;\n    ").concat(ellipsis, "\n\n    ").concat(open ? activeStyle(theme) : "", "\n    &:hover{ ").concat(hoverStyle(theme), " }\n  ");
});
export var Options = styled.ul.withConfig({
  displayName: "selectLinkstyles__Options",
  componentId: "sc-jizpgr-2"
})(["", ""], function (_ref4) {
  var theme = _ref4.theme,
    open = _ref4.open;
  return "\n    display: ".concat(open ? "flex" : "none", ";\n    flex-direction: column;\n    padding: 0;\n    margin: 0;\n    max-height: calc(44px * 8);\n    overflow-y: auto;\n    ").concat(colorFullSroll(theme, "10px", true), "\n\n    border: 3px solid ").concat(theme.colors.i1, ";\n    background: ").concat(theme.colors.G1, ";\n    z-index: 101;\n    border-top: none;\n    outline: none;\n\n    position: absolute;\n    top: 100%;\n    left: 0;\n    right: 0;\n  ");
});
export var Option = styled.li.withConfig({
  displayName: "selectLinkstyles__Option",
  componentId: "sc-jizpgr-3"
})(["", ""], function (_ref5) {
  var theme = _ref5.theme;
  return "\n    box-shadow: 0 1px 0 0 ".concat(theme.colors.i1, ";\n    list-style: none;\n    a {\n      ").concat(ellipsis, "\n      display: block;\n      line-height: 33px;\n    }\n  ");
});