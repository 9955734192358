import axios from "axios";
import { prop } from "ramda";
import { webApiServiceUrl } from "@microServices";
export var getInformationSourcesInReportingSystem = function getInformationSourcesInReportingSystem(reportingSystemId) {
  var url = !reportingSystemId ? "".concat(webApiServiceUrl, "/api/informationsource") : "".concat(webApiServiceUrl, "/api/informationsource?reportingSystemId=").concat(reportingSystemId);
  return axios.get(url, {
    headers: {
      "Content-Type": "application/json"
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};
export var getAllInformationSources = function getAllInformationSources(authTicket) {
  return axios.get("".concat(webApiServiceUrl, "/api/informationsource"), {
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};