import { curry, propEq } from "ramda";
var opacitySuffix = ["e6",
//.9
"cc",
//.8
"b3",
//.7
"99",
//.6
"80" //.5
];

export var colorOptions = {
  MY_COLORS: {
    id: "MY_COLORS",
    label: "Mina färger"
  },
  ONE_COLOR: {
    id: "ONE_COLOR",
    label: "En färg"
  },
  MANY_COLORS: {
    id: "MANY_COLORS",
    label: "Flera färger"
  }
};
export var defaultColor = {
  hex: "#406E75",
  className: "P3"
};
export var targetColor = function targetColor(idx) {
  return "#008058".concat(opacitySuffix[idx]);
};
export var extractRelativeColor = function extractRelativeColor(relativeTo) {
  if (!relativeTo || isNaN(relativeTo)) return null;
  return relativeTo >= 1 ? {
    hex: "#00998D"
  } : {
    hex: "#E3A250"
  };
};
export var customizableColors = [{
  hex: "#008058",
  className: "C1"
}, {
  hex: "#528E00",
  className: "C2"
}, {
  hex: "#BEAF01",
  className: "C3"
}, {
  hex: "#D17B0D",
  className: "C4"
}, {
  hex: "#BF3E05",
  className: "C5"
}, {
  hex: "#911348",
  className: "C6"
}, {
  hex: "#910091",
  className: "C7"
}, {
  hex: "#4944CB",
  className: "C8"
}, {
  hex: "#0081C6",
  className: "C9"
}, {
  hex: "#00A9BF",
  className: "C10"
}];
export var manyColorsFunc = function manyColorsFunc(idx) {
  return idx === -1 ? {
    hex: "#ffffff00",
    className: "hidden"
  } : customizableColors[idx % customizableColors.length];
};
export var oneColorFunc = function oneColorFunc(idx) {
  return idx === -1 ? {
    hex: "#ffffff00",
    className: "hidden"
  } : {
    hex: "#406E75",
    className: "P3"
  };
};
export var myColorsFunc = function myColorsFunc(idx, selectedList, id) {
  var _selectedList$units;
  var selected = selectedList === null || selectedList === void 0 ? void 0 : (_selectedList$units = selectedList.units) === null || _selectedList$units === void 0 ? void 0 : _selectedList$units.find(propEq("id", id));
  return customizableColors.find(propEq("hex", selected === null || selected === void 0 ? void 0 : selected.color)) || {
    className: "P3",
    hex: "#406E75"
  };
};
export var colorFunc = function colorFunc(option) {
  var safeOption = option && option.toLowerCase();
  switch (safeOption) {
    case colorOptions.MANY_COLORS.id.toLowerCase():
      return manyColorsFunc;
    case colorOptions.ONE_COLOR.id.toLowerCase():
      return oneColorFunc;
    case colorOptions.MY_COLORS.id.toLowerCase():
      return myColorsFunc;
    default:
      return manyColorsFunc;
  }
};
export var getColorUsingOption = curry(function (option, selectedList, idx, id) {
  var relativeTo = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  return relativeTo ? extractRelativeColor(relativeTo) : colorFunc(option)(idx, selectedList, id);
});
export var manipulateColor = function manipulateColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);
  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);
  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;
  var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);
  return "#" + RR + GG + BB;
};