export default [{
  src: "info",
  alt: "Ikon av information"
}, {
  src: "barChart",
  alt: "Ikon av stapeldiagram"
}, {
  src: "stackedBarchart",
  alt: "Ikon av staplade staplar"
}, {
  src: "deviationChart",
  alt: "Ikon av avvikelsediagram"
}, {
  src: "lineGraph",
  alt: "Ikon av tidslinjediagram"
}, {
  src: "stackedLineGraph",
  alt: "Ikon av staplat tidslinjediagram"
}, {
  src: "heatChart",
  alt: "Ikon av heatmap"
}, {
  src: "spiderChart",
  alt: "Ikon av spindeldiagram"
}, {
  src: "pieChart",
  alt: "Ikon av tårtdiagram"
}, {
  src: "list",
  alt: "Ikon av en punktlista"
}, {
  src: "vis",
  alt: "Ikon av Vården i siffror"
}, {
  src: "xls",
  alt: "Ikon av Excel"
}, {
  src: "pdf2",
  alt: "Ikon av pdf"
}, {
  src: "goal",
  alt: "Ikon av målvärde"
}, {
  src: "siths",
  alt: "Ikon av sithskort"
}, {
  src: "manWoman",
  alt: "Infografik föreställande en kvinna och en man."
}, {
  src: "woman",
  alt: "Infografik föreställande en kvinna."
}, {
  src: "womanFilled",
  alt: "Ikon av ifylld kvinna"
}, {
  src: "man",
  alt: "Ikon av man"
}, {
  src: "manFilled",
  alt: "Ikon av ifylld man"
}, {
  src: "psychiatry",
  alt: "Infografik för att visualisera psykiatri."
}, {
  src: "healthcare",
  alt: "Ikon av hälso- och sjukvård"
}, {
  src: "stetoscope",
  alt: "Ikon av stetoskop"
}, {
  src: "waitingTimes",
  alt: "Infografik för att visualisera väntetider."
}, {
  src: "baby",
  alt: "Infografic föreställande en bebis."
}, {
  src: "hospitalBed",
  alt: "Infografik föreställande en sjukhussäng."
}, {
  src: "population",
  alt: "Infografik för att visualisera allmänhetens åsikt."
}, {
  src: "diabetes",
  alt: "Infografik för att visualisera diabetes eller diabetesvård."
}, {
  src: "surgery",
  alt: "Infografik för att visualisera kirurgi."
}, {
  src: "knee",
  alt: "Infografik föreställande ett knä."
}, {
  src: "cancer",
  alt: "Infografik för att visualisera cancer."
}, {
  src: "heart",
  alt: "Ikon av hjärta"
}, {
  src: "heartFilled",
  alt: "Ikon av ifyllt hjärta"
}, {
  src: "settings",
  alt: "Infografik för att visualisera effektivitet och kostnad."
}, {
  src: "eye",
  alt: "Infografik föreställande ett öga."
}, {
  src: "patientExperience",
  alt: "Infografik för att visualisera patienterfarenhet."
}, {
  src: "endOfLifeCare",
  alt: "Infografik för att visualisera vård i livets slutskede."
}, {
  src: "workout",
  alt: "Ikon av träning"
}, {
  src: "news",
  alt: "Infografik för att visualisera en aktuell nyhet eller händelse."
}, {
  src: "area",
  alt: "Ikon av område"
}, {
  src: "global",
  alt: "Ikon av global"
}, {
  src: "thematic",
  alt: "Ikon av tematisk"
}, {
  src: "hospital",
  alt: "Ikon av sjukhus"
}, {
  src: "heartbeat",
  alt: "Ikon av hjärtslag"
}, {
  src: "openBook",
  alt: "Ikon av öppen bok"
}, {
  src: "stroke",
  alt: "Infografik för att visualisera stroke eller strokevård."
}, {
  src: "kidneys",
  alt: "Infografik föreställande njurar."
}, {
  src: "brokenArm",
  alt: "Infografik för att visualisera ortopedi."
}, {
  src: "heartCare",
  alt: "Infografik för att visualisera hjärtsjukvård."
}, {
  src: "lugns",
  alt: "Infografik föreställande lungor."
}, {
  src: "mobile",
  alt: "Ikon av mobil"
}, {
  src: "browser",
  alt: "Ikon av webbläsare"
}, {
  src: "laptop",
  alt: "Ikon av bärbar dator"
}, {
  src: "desktop",
  alt: "Ikon av stationär dator"
}, {
  src: "tablet",
  alt: "Ikon av surfplatta"
}, {
  src: "phone",
  alt: "Ikon av telefon"
}];