var variantFocusStyles = function variantFocusStyles(theme, variant) {
  var variants = {
    default: "\n    box-shadow: ".concat(theme.actionStyles.focusBorder, " ").concat(theme.colors.i1, ",\n                inset ").concat(theme.actionStyles.focusBorder, " ").concat(theme.colors.i1, ";\n  "),
    danger: "\n    box-shadow: ".concat(theme.actionStyles.focusBorder, " ").concat(theme.colors.V4, ",\n                inset ").concat(theme.actionStyles.focusBorder, " ").concat(theme.colors.V4, ";\n  ")
  };
  return variants[variant] || variants.default;
};
var variantHoverStyles = function variantHoverStyles(theme, variant) {
  var variants = {
    default: "\n    box-shadow: inset 0 0 0 1px ".concat(theme.colors.i1, ";\n    background: ").concat(theme.colors.i4, ";\n    color: ").concat(theme.colors.P0, ";\n  "),
    danger: "\n    box-shadow: inset 0 0 0 1px ".concat(theme.colors.V3, ";\n    background: ").concat(theme.colors.V4, ";\n    color: ").concat(theme.colors.G1, ";\n  ")
  };
  return variants[variant] || variants.default;
};
export var focusStyle = function focusStyle(theme, variant) {
  return "\n  z-index: 5;\n  position: relative;\n  ".concat(variantFocusStyles(theme, variant), ";\n");
};
export var activeStyle = function activeStyle(theme, background) {
  return "\n  background: ".concat(background || theme.colors.i2, ";\n  color: ").concat(theme.colors.P0, ";");
};
export var hoverStyle = function hoverStyle(theme, variant) {
  return "\n  cursor: pointer;\n  ".concat(variantHoverStyles(theme, variant), ";\n");
};
export var hoverStyle_noBorder = function hoverStyle_noBorder(theme) {
  return "\n  cursor: pointer;\n  background: ".concat(theme.colors.i4, ";\n  color: ").concat(theme.colors.P0, ";  ");
};
export var hiddenStyles = "\n  width: 0.1px;\n  height: 0.1px;\n  opacity: 0;\n  overflow: hidden;\n  position: absolute;\n  z-index: -1;";