import { pluck, equals, sortBy, identity } from "ramda";
import { useCustomEffect } from "hooks";
import { useUnitListsContext } from "@unitLists/context";
import { selectUnitListWithUnits } from "@unitLists/utils";
export var useUnitLists = function useUnitLists(query, setQuery, limit) {
  var _useUnitListsContext = useUnitListsContext(),
    activeUnitList = _useUnitListsContext.activeUnitList,
    setUnitLists = _useUnitListsContext.setUnitLists;

  // if state managed to update on it's own, update cookie
  useCustomEffect(function () {
    if (!equals(sortBy(identity, query.units), sortBy(identity, activeUnitList !== null && activeUnitList !== void 0 && activeUnitList.units ? pluck("id", activeUnitList.units) : []))) {
      setUnitLists(function (old) {
        return selectUnitListWithUnits(old, query.units);
      });
    }
  }, [query.units]);
  useCustomEffect(function () {
    var unitIds = activeUnitList !== null && activeUnitList !== void 0 && activeUnitList.units ? pluck("id", activeUnitList.units) : [];
    if (limit) unitIds = unitIds.slice(0, limit);
    if (!equals(sortBy(identity, query.units), sortBy(identity, unitIds))) {
      if (query.focusedUnit && !unitIds.includes(query.focusedUnit)) {
        setQuery({
          units: unitIds,
          focusedUnit: unitIds[0]
        });
      } else {
        setQuery({
          units: unitIds
        });
      }
    }
  }, [activeUnitList]);
};