import axios from "axios";
import { prop } from "ramda";
import { webApiServiceUrl } from "@microServices";
export var getOrganizationsMeta = function getOrganizationsMeta(ids) {
  return axios.post("".concat(webApiServiceUrl, "/api/organization"), ids, {
    headers: {
      "Content-Type": "application/json"
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};
export var getChildOrganizations = function getChildOrganizations(measureId, organizationId, authTicket) {
  return axios.post("".concat(webApiServiceUrl, "/api/organization/childorganizations"), {
    measureId: measureId,
    parentOrganizationId: organizationId,
    applyHospitalFilter: true
  }, {
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};