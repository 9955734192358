import { blockDefaultEvent } from "utils/events";
import { useDidMount, useCustomEffect } from "hooks";
export default function useHandleKeyDown(id, containerRef, selectRef, optionsRef, open, setOpen, selected, setSelected, selectable) {
  var mounted = useDidMount();
  useCustomEffect(function () {
    if (mounted) {
      if (open) {
        optionsRef.current.focus();
      } else {
        selectRef.current.focus();
      }
    }
  }, [open]);
  useCustomEffect(function () {
    if (document.activeElement === optionsRef.current) {
      var ele = document.getElementById("".concat(id, "-option-").concat(selected));
      ele.scrollIntoView({
        block: "nearest"
      });
    }
  }, [selected]);
  var handleKeyDown = function handleKeyDown(e) {
    if (open) blockDefaultEvent(e);
    //Enter, Tab, Escape
    if (open && [13, 9, 27].includes(e.keyCode)) setOpen(false);
    //ArrowDown
    else if (e.keyCode === 40) {
      if (!open) setOpen(true);
      setSelected(function (old) {
        return old < selectable ? old + 1 : selectable;
      });
    }
    //ArrowUp
    else if (e.keyCode === 38) {
      if (!open) setOpen(true);
      setSelected(function (old) {
        return old > 0 ? old - 1 : 0;
      });
    }

    //Home
    else if (open && e.keyCode === 36) {
      setSelected(0);
    }
    //End
    else if (open && e.keyCode === 35) {
      setSelected(selectable);
    }
  };
  useCustomEffect(function () {
    var containerRefCopy = containerRef.current;
    containerRef.current.addEventListener("keydown", handleKeyDown);
    return function () {
      return containerRefCopy === null || containerRefCopy === void 0 ? void 0 : containerRefCopy.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
}