import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from "react";
import { equals, isNil } from "ramda";
import useCustomEffect from "hooks/useCustomEffect";
var isNotControlled = function isNotControlled(isControlled, initialValueLoaded) {
  return !isControlled || !initialValueLoaded;
};
var shouldSetInitialValueLoaded = function shouldSetInitialValueLoaded(initialValueLoaded, _value) {
  return !initialValueLoaded && !isNil(_value);
};
export default function useInput(_value, callback, isControlled) {
  var _useState = useState(_value),
    _useState2 = _slicedToArray(_useState, 2),
    inputString = _useState2[0],
    setInputString = _useState2[1];
  var _useState3 = useState(!isNil(_value)),
    _useState4 = _slicedToArray(_useState3, 2),
    initialValueLoaded = _useState4[0],
    setInitialValueLoaded = _useState4[1];
  useCustomEffect(function () {
    if (!equals(inputString, _value) && isNotControlled(isControlled, initialValueLoaded)) setInputString(_value);
  }, [_value, isControlled, initialValueLoaded]);
  useCustomEffect(function () {
    if (shouldSetInitialValueLoaded(initialValueLoaded, _value)) setInitialValueLoaded(!isNil(_value));
  }, [_value]);
  var onInput = function onInput(value) {
    setInputString(value);
    callback(value);
  };
  return {
    inputString: inputString,
    setInputString: setInputString,
    onInput: onInput
  };
}