import { pipe, pluck, flatten, uniq, curry } from "ramda";
import { getPeriod } from "utils/periods";
import { defaultDates } from "utils/dates";
import { getRelativeTo } from "utils/measurement";
import { isNotEmptyArray } from "utils/validation";
export var getAdjustedMeasuresMetadata = function getAdjustedMeasuresMetadata(measures, query) {
  var _getPeriod, _getPeriod2;
  var periodTypes = pipe(pluck("periodTypes"), flatten, uniq)(measures);
  var periodType = !periodTypes.includes(query.periodType) ? periodTypes[0] : query.periodType || periodTypes[0];
  return {
    earliestDataDate: (_getPeriod = getPeriod(periodType, defaultDates.earliestDataDate)) === null || _getPeriod === void 0 ? void 0 : _getPeriod.start,
    lastDataDate: (_getPeriod2 = getPeriod(periodType, defaultDates.lastDataDate)) === null || _getPeriod2 === void 0 ? void 0 : _getPeriod2.end,
    periodTypes: periodTypes
  };
};
export var soughtValueIcons = {
  "Ej angivet": null,
  Högt: "trendUp",
  Lågt: "trendDown"
};
var byRelativeFilter = function byRelativeFilter(relativeFilter, relativeValue) {
  return (relativeFilter === null || relativeFilter === void 0 ? void 0 : relativeFilter.length) > 0 ? relativeFilter.includes(relativeValue) : true;
};
export var filterMeasuresByRelativeTo = curry(function (measures, measurementsWithMetadata, organizationId, relativeFilter, relativeTo) {
  return measures.filter(function (measure) {
    var _entry$;
    var entry = measurementsWithMetadata.get("".concat(measure.measureId, "-").concat(organizationId)) || [];
    var measurements = (_entry$ = entry[0]) === null || _entry$ === void 0 ? void 0 : _entry$.measurements;
    var relativeToValue = measurements ? getRelativeTo(null, relativeTo, measurements[0], measurements[1], measure.soughtValue) : null;
    return byRelativeFilter(relativeFilter, relativeToValue);
  });
});
export var filterMeasuresByHasMeasurements = curry(function (measures, measurementsWithMetadata) {
  return measures.filter(function (measure) {
    var entry = measurementsWithMetadata.get("".concat(measure.measureId)) || [];
    var measurements = pluck("measurements", entry).flat();
    return isNotEmptyArray(measurements);
  });
});