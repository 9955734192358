import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import _extends from "@babel/runtime/helpers/extends";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { pick } from "ramda";
import propTypes from "@styled-system/prop-types";
import { space, color, typography, layout, flexbox, grid, position, shadow, background, border, compose } from "styled-system";

//Extend a styled-component with styled-system style props
export var extendedSystemStyles = function extendedSystemStyles(keys) {
  return compose(keys.includes("space") && space, keys.includes("color") && color, keys.includes("typography") && typography, keys.includes("layout") && layout, keys.includes("flexbox") && flexbox, keys.includes("grid") && grid, keys.includes("position") && position, keys.includes("shadow") && shadow, keys.includes("background") && background, keys.includes("border") && border);
};

//Extend a functional component of a styled-component with styled-system style props
export var extendedSystemStylesProps = function extendedSystemStylesProps(keys, props) {
  var pickedProps = pick(keys.flatMap(function (key) {
    return Object.keys(propTypes[key]);
  }), props);
  var rest = _extends({}, (_objectDestructuringEmpty(pickedProps), pickedProps));
  return rest;
};

//Add propTypes to a functional component that has extended with styled-system
export var extendedSystemStylesPropTypes = function extendedSystemStylesPropTypes(keys) {
  return keys.reduce(function (acc, curr) {
    return _objectSpread(_objectSpread({}, acc), propTypes[curr]);
  }, {});
};