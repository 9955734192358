export default {
  xxs: "1rem",
  xs: "1.2rem",
  sm: "1.4rem",
  md: "1.6rem",
  lg: "2rem",
  xl: "2.8rem",
  xxl: "3rem",
  xxxl: "3.2rem",
  xxxxl: "4rem"
};