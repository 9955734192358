import { useCallback } from "react";
import { uniq, without } from "ramda";
import { blockDefaultEvent } from "utils/events";
import { useCustomEffect } from "hooks";
export default function useHandleKeyDown(id, containerRef, optionsRef, searchRef, open, setOpen, activeFocus, setActiveFocus, searchString, setSearchString, options, setOpenGroups) {
  var hasChildren = function hasChildren(x) {
    var _x$children;
    return ((_x$children = x.children) === null || _x$children === void 0 ? void 0 : _x$children.length) > 0;
  };
  var close = useCallback(function () {
    setOpen(false);
    setActiveFocus(-1);
    if (document.activeElement !== searchRef.current) searchRef.current.focus();
  }, [setOpen, setActiveFocus, searchRef]);
  useCustomEffect(function () {
    if (document.activeElement === optionsRef.current) {
      var ele = document.getElementById("".concat(id, "-option-").concat(activeFocus));
      ele.scrollIntoView({
        block: "nearest"
      });
    }
  }, [activeFocus]);
  var handleKeyDown = function handleKeyDown(e) {
    if (open && [9, 27, 37, 38, 39, 40].includes(e.keyCode)) blockDefaultEvent(e);
    //Tab
    if (open && [9].includes(e.keyCode)) close();
    //Escape
    else if (open && [27].includes(e.keyCode)) {
      close();
      setSearchString("");
    }

    //ArrowDown
    else if (e.keyCode === 40) {
      setActiveFocus(function (old) {
        return old < options.length - 1 ? old + 1 : 0;
      });
      if (document.activeElement !== optionsRef.current) optionsRef.current.focus();
      if (!open) setOpen(true);
    }
    //ArrowUp
    else if (e.keyCode === 38) {
      setActiveFocus(function (old) {
        return old > 0 ? old - 1 : options.length - 1;
      });
      if (document.activeElement !== optionsRef.current) optionsRef.current.focus();
      if (!open) setOpen(true);
    }

    //ArrowRight + Left
    else if (e.keyCode === 39 || e.keyCode === 37) {
      if (document.activeElement !== searchRef.current) {
        //if we're amongst our options
        var activeOption = options[activeFocus];
        if (hasChildren(activeOption)) {
          if (e.keyCode === 39) {
            //Right
            setOpenGroups(function (old) {
              return uniq(old.concat(activeOption.id));
            });
            setActiveFocus(function (old) {
              return old + 1;
            });
          } else {
            //Left
            setOpenGroups(function (old) {
              return without([activeOption.id], old);
            });
          }
        }
      }
    }

    //Home, End
    else if (open && (e.keyCode === 36 || e.keyCode === 35)) {
      if (document.activeElement !== searchRef.current) searchRef.current.focus();
      setActiveFocus(-1);
    }
  };
  useCustomEffect(function () {
    var containerRefCopy = containerRef.current;
    containerRef.current.addEventListener("keydown", handleKeyDown);
    return function () {
      return containerRefCopy === null || containerRefCopy === void 0 ? void 0 : containerRefCopy.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, options, activeFocus]);
}