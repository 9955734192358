import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { equals, pipe, toLower, toUpper, curry, pick, filter, omit } from "ramda";
import querystring from "query-string";
import { isEmptyArray } from "utils/validation";
import { getPeriod, nthPreviousPeriod, allPeriodTypes } from "utils/periods";
import { adjustDatesToPeriod, dateConvertKeys, getIsoDate } from "utils/dates";
import { mapKeys } from "utils/mapKeys";
import { allGenderTypes } from "utils/genders";
import { comparators } from "utils/measurement/measurementLabels";
import { measureGroupTypeId } from "utils/measures/measureGroupings";
import { sortOptions } from "utils/sort";

//Defaultparams should always be null/false for null/falsable values, and one of the possible selections in remaining
//Function removes the params in query that matches the value in defaultParams
export var filterDefaultParams = curry(function (defaultParams, query) {
  var alwaysInclude = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  return Object.keys(query).reduce(function (acc, key) {
    if (alwaysInclude.includes(key)) acc[key] = query[key];else if (!equals(query[key], defaultParams[key])) acc[key] = query[key];
    return acc;
  }, {});
});

//Merges two queries, while only keeping the variables present in the first one. Value is taken from new query, unless value doesn't exist then old value is kept.
export var mergeQueries = curry(function (defaultParams, query) {
  return Object.keys(defaultParams).reduce(function (acc, key) {
    if (query[key] === false) acc[key] = query[key];else if (isEmptyArray(query[key]) && acc[key]) return acc;else acc[key] = query[key] || query[key.toLowerCase()] || acc[key];
    return acc;
  }, _objectSpread({}, defaultParams));
});

//adjust query so that singular items that should be placed inside an array are placed inside an array
export var fixArrays = curry(function (defaultParams, query) {
  return Object.keys(query).reduce(function (acc, key) {
    if (Array.isArray(defaultParams[key])) acc[key] = [].concat(query[key]);
    return acc;
  }, _objectSpread({}, query));
});

//fix so that numbers are pased back into numbers from string queries
export var fixNumberParams = curry(function (defaultParams, query) {
  return Object.keys(query).reduce(function (acc, key) {
    if (typeof defaultParams[key] === "number") acc[key] = parseInt(acc[key]) || null;
    return acc;
  }, _objectSpread({}, query));
});

//fix so that booleans and null, undefined are parsed back into its real values from string queries
export var fixBooleans = function fixBooleans(query) {
  return Object.keys(query).reduce(function (acc, curr) {
    if (acc[curr] === "true") acc[curr] = true;else if (acc[curr] === "false") acc[curr] = false;else if (acc[curr] === "null") acc[curr] = null;else if (acc[curr] === "undefined") acc[curr] = undefined;
    return acc;
  }, _objectSpread({}, query));
};

//Repairs casing on queries. Acts as a dictionary and needs to be filled with all new props that are camelcased and used in queries
export var repairQueryValues = function repairQueryValues(query) {
  var _allPeriodTypes$query, _query$periodType, _query$units, _ref;
  var variables = {
    periodType: (_allPeriodTypes$query = allPeriodTypes[(_query$periodType = query.periodType) === null || _query$periodType === void 0 ? void 0 : _query$periodType.toLowerCase()]) === null || _allPeriodTypes$query === void 0 ? void 0 : _allPeriodTypes$query.id,
    gender: query.gender && Object.keys(allGenderTypes).find(function (x) {
      return toLower(x) === toLower(query.gender);
    }),
    relativeTo: query.relativeTo && Object.keys(comparators).find(function (x) {
      return toLower(x) === toLower(query.relativeTo);
    }),
    units: (_query$units = query.units) === null || _query$units === void 0 ? void 0 : _query$units.map(toUpper),
    focusedUnit: (_ref = query.focusedUnit || query.focusedunit) === null || _ref === void 0 ? void 0 : _ref.toUpperCase(),
    sort: query.sort && Object.keys(sortOptions).find(function (x) {
      return toLower(x) === toLower(query.sort);
    }),
    relativeFilter: query.relativeFilter && query.relativeFilter.map(function (x) {
      return parseInt(x) || null;
    }),
    relatedMeasuresById: query.relatedMeasuresById || query.relatededmeasuresbyid || null,
    relatedMeasuresByEntry: query.relatedMeasuresByEntry || query.relatedmeasuresbyentry || null
  };
  return mergeQueries(query, variables);
};

//go to method for handling queryParams
export var cleanQueryParams = curry(function (defaultParams, query) {
  return pipe(fixArrays(defaultParams), repairQueryValues, fixBooleans, dateConvertKeys(["dateFrom", "dateTo"]), adjustDatesToPeriod)(query);
});
export var adjustDateParams = function adjustDateParams(params, newMetadata) {
  var _params$dateTo, _params$dateTo2, _params$dateFrom, _defaultStartPeriod, _defaultStartPeriod$s, _params$dateFrom2, _params$dateFrom3, _endPeriod$end;
  var earliestDataDate = newMetadata.earliestDataDate,
    lastDataDate = newMetadata.lastDataDate,
    periodTypes = newMetadata.periodTypes;
  var isWrongPeriodType = !periodTypes.includes(params.periodType);
  var correctPeriodType = isWrongPeriodType ? periodTypes[0] : params.periodType;
  var endPeriod = ((_params$dateTo = params.dateTo) === null || _params$dateTo === void 0 ? void 0 : _params$dateTo.getTime()) > (lastDataDate === null || lastDataDate === void 0 ? void 0 : lastDataDate.getTime()) ? getPeriod(correctPeriodType, lastDataDate) : ((_params$dateTo2 = params.dateTo) === null || _params$dateTo2 === void 0 ? void 0 : _params$dateTo2.getTime()) < ((_params$dateFrom = params.dateFrom) === null || _params$dateFrom === void 0 ? void 0 : _params$dateFrom.getTime()) ? getPeriod(correctPeriodType, lastDataDate) : getPeriod(correctPeriodType, params.dateTo);
  var defaultStartPeriod = nthPreviousPeriod(correctPeriodType, endPeriod, 4);
  defaultStartPeriod = ((_defaultStartPeriod = defaultStartPeriod) === null || _defaultStartPeriod === void 0 ? void 0 : (_defaultStartPeriod$s = _defaultStartPeriod.start) === null || _defaultStartPeriod$s === void 0 ? void 0 : _defaultStartPeriod$s.getTime()) < (earliestDataDate === null || earliestDataDate === void 0 ? void 0 : earliestDataDate.getTime()) ? getPeriod(correctPeriodType, earliestDataDate) : defaultStartPeriod;
  var startPeriod = ((_params$dateFrom2 = params.dateFrom) === null || _params$dateFrom2 === void 0 ? void 0 : _params$dateFrom2.getTime()) < (earliestDataDate === null || earliestDataDate === void 0 ? void 0 : earliestDataDate.getTime()) || ((_params$dateFrom3 = params.dateFrom) === null || _params$dateFrom3 === void 0 ? void 0 : _params$dateFrom3.getTime()) > (endPeriod === null || endPeriod === void 0 ? void 0 : (_endPeriod$end = endPeriod.end) === null || _endPeriod$end === void 0 ? void 0 : _endPeriod$end.getTime()) ? defaultStartPeriod : isWrongPeriodType ? defaultStartPeriod : getPeriod(correctPeriodType, params.dateFrom);
  return _objectSpread(_objectSpread({}, params), {}, {
    periodType: correctPeriodType,
    dateFrom: (startPeriod === null || startPeriod === void 0 ? void 0 : startPeriod.start) || null,
    dateTo: (endPeriod === null || endPeriod === void 0 ? void 0 : endPeriod.end) || null
  });
};
export var buildMeasureUrl = function buildMeasureUrl(measure, query) {
  var queryParams = pipe(pick(["relatedMeasuresById", "relatedMeasuresByEntry", "units", "periodType", "dateFrom", "dateTo"]), filter(Boolean), mapKeys(getIsoDate, ["dateFrom", "dateTo"]), function (x) {
    var _measure$submeasures, _measure$submeasures$;
    return _objectSpread(_objectSpread({}, x), {}, {
      measureIds: query.gender !== "Total" ? [(_measure$submeasures = measure.submeasures) === null || _measure$submeasures === void 0 ? void 0 : (_measure$submeasures$ = _measure$submeasures.find(function (submeasure) {
        return submeasure.gender === query.gender;
      })) === null || _measure$submeasures$ === void 0 ? void 0 : _measure$submeasures$.measureId] : []
    });
  }, function (x) {
    return x.relatedMeasuresById && x.relatedMeasuresByEntry ? _objectSpread(_objectSpread({}, x), {}, {
      relatedMeasuresByEntry: measureGroupTypeId[x.relatedMeasuresByEntry] || "keyword"
    }) : omit(["relatedMeasuresById", "relatedMeasuresByEntry"], x);
  })(query);
  return "/indikator/".concat(measure.measureId, "?").concat(querystring.stringify(queryParams).toLowerCase());
};