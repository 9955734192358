import styled from "styled-components";
import { extendedSystemStyles } from "@mixins";
var styledSystemKeys = ["space", "layout", "flexbos", "colors"];
export default {
  Box: styled.div.withConfig({
    displayName: "skeleton__Box",
    componentId: "sc-dtxcnh-0"
  })(["", "", ""], function (_ref) {
    var theme = _ref.theme;
    return "\n    width: 100%;\n    background: ".concat(theme.colors.G3, ";\n  ");
  }, extendedSystemStyles(styledSystemKeys)),
  Circle: styled.div.withConfig({
    displayName: "skeleton__Circle",
    componentId: "sc-dtxcnh-1"
  })(["", "", ""], function (_ref2) {
    var theme = _ref2.theme;
    return "\n      border-radius: 50%;\n      background: ".concat(theme.colors.G3, ";\n    ");
  }, extendedSystemStyles(styledSystemKeys))
};