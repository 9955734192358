import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { equals } from "ramda";
export var measureGroupTypeId = {
  kallsystem: "registry",
  sjukhus: "organization",
  oversikt: "overview",
  keyword: "keyword"
};
export var measureGroupTypeToHumanReadable = function measureGroupTypeToHumanReadable(type) {
  var _Object$entries$find;
  return (_Object$entries$find = Object.entries(measureGroupTypeId).find(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      _ = _ref2[0],
      value = _ref2[1];
    return equals(value, type);
  })) === null || _Object$entries$find === void 0 ? void 0 : _Object$entries$find[0];
};