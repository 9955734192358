import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { authApiServiceUrl } from "@microServices";
import bvoResources from "@authentication/constants/bvoResources.json";
export var authLogoutUrl = "".concat(authApiServiceUrl, "/logout");
export var authLoginUrl = function authLoginUrl(guid) {
  return "".concat(authApiServiceUrl, "/login?guid=").concat(guid);
};
var fetchOptions = function fetchOptions() {
  return {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    }
  };
};
export var fetchUser = function fetchUser(eraseUser, setUser) {
  return fetch("".concat(authApiServiceUrl, "/user"), _objectSpread(_objectSpread({}, fetchOptions()), {}, {
    body: JSON.stringify(Object.values(bvoResources))
  })).then(function (response) {
    if (response.status !== 200) {
      eraseUser();
      return null;
    }
    return response.json();
  }).then(function (user) {
    if (user.ticket) {
      setUser(user);
      return true;
    } else {
      eraseUser();
      return false;
    }
  }).catch(function () {
    return eraseUser();
  });
};
export var logOut = function logOut() {
  return fetch(authLogoutUrl, fetchOptions());
};
export var logIn = function logIn(authguid) {
  return fetch(authLoginUrl(authguid), fetchOptions());
};