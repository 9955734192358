import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useState } from "react";
import { useQuery as useReactQuery } from "react-query";
import { useQuery as useUrqlQuery } from "urql";
import { prop, any } from "ramda";
import { searchMeasures, getAllKeywordGroups } from "@webapi/api";
import { useAuthContext } from "@authentication/context";
import { useDebouncedValue } from "hooks";
import { GET_CATEGORY } from "@cms/categories";
import { sort } from "utils/sort";
export default function useHeader() {
  var _debouncedSearchQuery;
  var _useAuthContext = useAuthContext(),
    getUser = _useAuthContext.getUser,
    getUserAccess = _useAuthContext.getUserAccess;
  var authTicket = getUser().ticket;

  //Measures-Search
  var _useState = useState({}),
    _useState2 = _slicedToArray(_useState, 2),
    searchQuery = _useState2[0],
    setSearchQuery = _useState2[1];
  var debouncedSearchQuery = useDebouncedValue(1000, _objectSpread({}, Object.fromEntries(Object.entries(searchQuery).filter(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      v = _ref2[1];
    return v;
  }))));
  var _useReactQuery = useReactQuery(["measuresSearch", debouncedSearchQuery, authTicket], function () {
      return searchMeasures(debouncedSearchQuery, authTicket);
    }, {
      initialData: [],
      enabled: ((_debouncedSearchQuery = debouncedSearchQuery.q) === null || _debouncedSearchQuery === void 0 ? void 0 : _debouncedSearchQuery.length) > 2
    }),
    searchResult = _useReactQuery.data;
  var searchOptions = searchResult.slice(0, 40).map(function (x) {
    return {
      id: x.id,
      label: x.name,
      to: "/indikator/".concat(x.id, "?relatedmeasuresbyentry=kallsystem&search=true")
    };
  });

  //CustomKeywordGroups
  var _useReactQuery2 = useReactQuery(["keywordGroups"], getAllKeywordGroups, {
      initialData: []
    }),
    customKeywordGroups = _useReactQuery2.data;
  var hydratedCustomKeywordGroups = customKeywordGroups.map(function (group) {
    return _objectSpread(_objectSpread({}, group), {}, {
      label: group.title,
      to: "/jamfor/nyckelordsgrupp/".concat(group.titleSlug)
    });
  }).filter(function (group) {
    var _group$keywords;
    return group.title && ((_group$keywords = group.keywords) === null || _group$keywords === void 0 ? void 0 : _group$keywords.length) > 5;
  });
  var sortedKeywordGroups = sort(prop("sortOrder"), hydratedCustomKeywordGroups);

  //Custom reportCategories
  var _useUrqlQuery = useUrqlQuery({
      query: GET_CATEGORY,
      variables: {
        where: {
          compositionType: {
            eq: "REPORT"
          }
        },
        order: {
          title: "ASC"
        }
      }
    }),
    _useUrqlQuery2 = _slicedToArray(_useUrqlQuery, 1),
    data = _useUrqlQuery2[0].data;
  var customReportCategories = ((data === null || data === void 0 ? void 0 : data.categories) || []).map(function (category) {
    return _objectSpread(_objectSpread({}, category), {}, {
      to: "/rapporter/".concat(category.slug),
      label: category.title
    });
  });
  var userAccess = getUserAccess();
  var authenticated = userAccess && any(function (key) {
    return userAccess[key];
  }, Object.keys(userAccess));
  return {
    adminAccess: authenticated,
    searchQuery: searchQuery,
    searchResult: searchResult,
    setSearchQuery: setSearchQuery,
    searchOptions: searchOptions,
    measuresCategories: [{
      id: "sourcesystem-entry",
      label: "Datakälla",
      to: "/jamfor/kallsystem"
    }, {
      id: "overview-entry",
      label: "Min översikt",
      to: "/jamfor/oversikt"
    }, {
      id: "hospitals-entry",
      label: "Sjukhus",
      to: "/jamfor/sjukhus"
    }].concat(sortedKeywordGroups),
    reportCategories: [{
      id: "news-entry",
      label: "Nyheter",
      to: "/nyheter"
    }].concat(customReportCategories)
  };
}