export default {
  pen: "\\e9b5",
  spinner: "\\e9d8",
  success: "\\e92c",
  locked: "\\e995",
  locked_lg: "\\e995",
  blocked: "\\e922",
  blocked_md: "\\e922",
  blocked_lg: "\\e922",
  questionMark: "\\e9c7",
  info: "\\e976",
  info_lg: "\\e977",
  info2: "\\e934",
  alert: "\\e904",
  warning: "\\e9f4",
  warning_lg: "\\e9f4",
  error: "\\e94c",
  attention: "\\e94a",
  attention_lg: "\\e94b",
  pdf2: "\\e948",
  pdf2_md: "\\e949",
  pdf2_lg: "\\e949",
  xls: "\\e946",
  xls_md: "\\e947",
  xls_lg: "\\e947",
  link: "\\e98d",
  brokenLink: "\\e98e",
  externalLink: "\\e991",
  quote: "\\e931",
  content: "\\e985",
  lightbulb: "\\e98a",
  lightbulb_md: "\\e98a"
};