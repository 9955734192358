import styled from "styled-components";
import PropTypes from "prop-types";
import { focusStyle, hoverStyle, extendedSystemStyles } from "@mixins";
var styledSystemKeys = ["space", "layout"];
var TabButton = styled.button.withConfig({
  displayName: "TabButton",
  componentId: "sc-gmw5kp-0"
})(["", " ", ""], function (_ref) {
  var theme = _ref.theme,
    active = _ref.active,
    inverted = _ref.inverted;
  return "\n    min-height: ".concat(theme.actionStyles.height, ";\n    font-family: ").concat(theme.fonts.robotoMedium, ";\n    font-size: ").concat(theme.fontSizes[theme.actionStyles.fontSize], ";\n    line-height: ").concat(theme.lineHeight[theme.actionStyles.lineHeight], ";\n\n    width: fit-content;\n    padding: 0 20px;\n    white-space: nowrap;\n    box-shadow: ").concat(!active ? "inset 0 0 0 1px ".concat(theme.colors.P2) : "", ";\n\n    background: ").concat(active ? inverted ? theme.colors.i2 : theme.colors.P2 : inverted ? theme.colors.P2 : theme.colors.G1, ";\n    color: ").concat(active ? inverted ? theme.colors.P0 : theme.colors.G1 : inverted ? theme.colors.G1 : theme.colors.P0, ";\n\n    appearance: none;\n    transition: all .3s;\n\n    &:first-child{ border-radius: 3px 0 0 3px; }\n    &:last-child{ border-radius: 0 3px 3px 0; }\n    &:only-child{ border-radius: 3px; }\n\n    &:focus{ ").concat(focusStyle(theme), " }\n    ").concat(!active ? "\n      &:hover{ ".concat(hoverStyle(theme), " }\n    ") : "", "\n    ");
}, extendedSystemStyles(styledSystemKeys));
TabButton.propTypes = {
  active: PropTypes.bool.isRequired,
  styles: PropTypes.object,
  id: PropTypes.string,
  className: PropTypes.string
};
export default TabButton;