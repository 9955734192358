import axios from "axios";
import { prop } from "ramda";
import queryString from "query-string";
import { webApiServiceUrl } from "@microServices";
import { preppQuery } from "@fetch";
export default function searchKeywords(searchQuery, authTicket) {
  var baseUrl = "".concat(webApiServiceUrl, "/api/search/keywords?");
  var mapping = {
    grupp: "keywordGroupSlug",
    grupperad: "grouped",
    q: "searchText"
  };
  var preppedQuery = queryString.stringify(preppQuery(searchQuery, mapping));
  return axios.get("".concat(baseUrl).concat(preppedQuery), {
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
}