import styled from "styled-components";
import { focusStyle, colorFullSroll } from "@mixins";
export var Options = styled.ul.withConfig({
  displayName: "groupedMultiselectstyles__Options",
  componentId: "sc-dk6d2q-0"
})(["", ""], function (_ref) {
  var theme = _ref.theme,
    open = _ref.open;
  return "\n    display: ".concat(open ? "flex" : "none", ";\n    flex-direction: column;\n    padding: 0;\n    margin: 0;\n    max-height: calc(44px * 8);\n    overflow-y: auto;\n    ").concat(colorFullSroll(theme, "10px", true), "\n\n    border: 3px solid ").concat(theme.colors.i1, ";\n    background: ").concat(theme.colors.G1, ";\n    z-index: 9;\n    border-top: none;\n    outline: none;\n\n    position: absolute;\n    top: 100%;\n    left: 0;\n    right: 0;\n  ");
});
export var Group = styled.ul.withConfig({
  displayName: "groupedMultiselectstyles__Group",
  componentId: "sc-dk6d2q-1"
})(["", ""], function (_ref2) {
  var open = _ref2.open,
    theme = _ref2.theme;
  return "\n    height: ".concat(open ? "auto" : 0, ";\n    display: ").concat(open ? "block" : "none", ";\n    box-shadow: inset 0 1px 0 0 ").concat(theme.colors.i1, ";\n  ");
});
export var ParentItem = styled.li.withConfig({
  displayName: "groupedMultiselectstyles__ParentItem",
  componentId: "sc-dk6d2q-2"
})(["", ""], function (_ref3) {
  var theme = _ref3.theme,
    focused = _ref3.focused,
    parents = _ref3.parents;
  return "\n    list-style: none;\n    box-shadow: inset 0 -1px 0 0 ".concat(theme.colors.i1, ";\n\n    ").concat(parents > 0 ? "\n      margin-left: calc(44px * ".concat(parents, ");\n    ") : "", "\n\n    .parent label {\n      font-family: ").concat(theme.fonts.robotoMedium, ";\n    }\n\n    ").concat(focused ? focusStyle(theme) : "", "\n  ");
});
export var ChildItem = styled.li.withConfig({
  displayName: "groupedMultiselectstyles__ChildItem",
  componentId: "sc-dk6d2q-3"
})(["", ""], function (_ref4) {
  var theme = _ref4.theme,
    focused = _ref4.focused,
    parents = _ref4.parents;
  return "\n    list-style: none;\n    box-shadow: inset 0 -1px 0 0 ".concat(theme.colors.i1, ";\n\n    ").concat(parents > 0 ? "\n      margin-left: 43px;\n      box-shadow: inset 1px 0 0 0px ".concat(theme.colors.i1, ", inset 0 -1px 0 ").concat(theme.colors.i1, ";\n      label {\n        padding-left: 44px;\n        padding-right: 10px;\n      }\n    ") : "", "\n\n\n    ").concat(focused ? focusStyle(theme) : "", "\n  ");
});