import styled from "styled-components";
import { focusStyle, hoverStyle, hiddenStyles, extendedSystemStyles } from "@mixins";
export var styledSystemKeys = ["space", "layout", "flexbox"];
export var StyledRadio = styled.input.withConfig({
  displayName: "radioFilterButtonstyles__StyledRadio",
  componentId: "sc-cge42l-0"
})(["", ""], hiddenStyles);
export var Label = styled.label.withConfig({
  displayName: "radioFilterButtonstyles__Label",
  componentId: "sc-cge42l-1"
})(["", " ", ""], function (_ref) {
  var theme = _ref.theme,
    checked = _ref.checked;
  return "\n    height: ".concat(theme.actionStyles.height, ";\n    font-family: ").concat(theme.fonts.robotoMedium, ";\n    font-size: ").concat(theme.fontSizes[theme.actionStyles.fontSize], ";\n    line-height: ").concat(theme.lineHeight[theme.actionStyles.lineHeight], ";\n\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n    padding: 0 6px;\n    flex: 1;\n    white-space: nowrap;\n    box-shadow: ").concat(!checked ? "inset 0 0 0 1px ".concat(theme.colors.P2) : "", ";\n\n    background: ").concat(checked ? theme.colors.P2 : theme.colors.G1, ";\n    color: ").concat(checked ? theme.colors.G1 : theme.colors.P0, ";\n\n    transition: all .3s;\n\n    &:first-child{ border-radius: 3px 0 0 3px; }\n    &:last-child{ border-radius: 0 3px 3px 0; }\n    &:only-child{ border-radius: 3px; }\n\n    &:focus-within{ ").concat(focusStyle(theme), " }\n    ").concat(!checked ? "\n      &:hover{ ".concat(hoverStyle(theme), " }\n    ") : "", "\n    ");
}, extendedSystemStyles(styledSystemKeys));