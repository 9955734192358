export default {
  checkboxChecked: "\\e92e",
  checkboxChecked_xs: "\\e92d",
  checkboxPartial: "\\e930",
  checkboxPartial_xs: "\\e92f",
  cross: "\\e941",
  cross_xs: "\\e941",
  crossCirle: "\\e940",
  loop: "\\e996",
  loop2: "\\e997",
  plus: "\\e9c0",
  plusCircle: "\\e9bf",
  minus: "\\e9a2",
  minus_xs: "\\e9a1",
  minusCircle: "\\e9a0",
  camera: "\\e92b",
  print: "\\e9c1",
  search: "\\e9cd",
  zoom: "\\e99a",
  copy: "\\e935",
  paste: "\\e9b2",
  trashcan: "\\e9eb",
  Save: "\\e9fe",
  wrench: "\\e9b3",
  menu: "\\e96f",
  sort: "\\e9d5",
  sortAsc: "\\e9d3",
  sortDesc: "\\e9d4",
  undo: "\\e9ef",
  redo: "\\e9ca",
  tab: "\\e9df",
  fullScreen: "\\e955",
  addPaper: "\\e903",
  addFolder: "\\e902",
  addDoc: "\\e901",
  uploadFolder: "\\e954",
  downloadFolder: "\\e953",
  uploadCloud: "\\e933",
  downloadCloud: "\\e932",
  upload: "\\e957",
  download: "\\e956",
  play_md: "\\e9bd",
  play_lg: "\\e9be",
  play: "\\e9bc",
  text: "\\e9e4",
  image: "\\e979",
  image_md: "\\e979",
  image_lg: "\\e979",
  quoteRight: "\\e9c9",
  quoteLeft: "\\e9c8",
  expandLeft: "\\e94e",
  expandRight: "\\e94f",
  expandRight_xs: "\\e94f",
  expandDown: "\\e94d",
  expandDown_xs: "\\e94d",
  expandUp: "\\e950",
  expandUp_xs: "\\e950",
  trendDown: "\\e918",
  trendUp: "\\e919",
  trendFlat: "\\e978",
  logOut: "\\e992",
  listArrow: "\\e917",
  listArrows: "\\e900"
};