import axios from "axios";
import { prop } from "ramda";
import { webApiServiceUrl } from "@microServices";
export var getAllReportingSystems = function getAllReportingSystems() {
  return axios.get("".concat(webApiServiceUrl, "/api/reportingsystem/reportingsystems"), {
    headers: {
      "Content-Type": "application/json"
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};