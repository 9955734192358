import axios from "axios";
import { prop } from "ramda";
import { mediaApiServiceUrl } from "@microServices";
export var uploadPrivateMedia = function uploadPrivateMedia(media, description, authTicket) {
  var formData = new FormData();
  formData.append("file", media);
  return axios.post("".concat(mediaApiServiceUrl, "/unmanaged?alt=").concat(description), formData, {
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};
export var deletePrivateMedia = function deletePrivateMedia(id, authTicket) {
  return axios.delete("".concat(mediaApiServiceUrl, "/unmanaged/").concat(id.split("/").pop()), {
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};