import { useEffect, useRef, useCallback } from "react";
import { equals } from "ramda";
import querystring from "query-string";
import { isEmptyObject } from "utils/validation";

//NOT pushstates job to slugify your urls, it only pushes your query/state to the history
export default function usePushState(id, pathname) {
  var query = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var paramsFunc = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function (x) {
    return x;
  };
  var callback = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : function (x) {
    return x;
  };
  var useReplace = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
  var baseUrl = !query || isEmptyObject(query) ? pathname : "".concat(pathname, "?");
  var prevQuery = useRef(query);
  var prevUseReplace = useRef(useReplace);
  var queryPopped = useRef(false);
  var pushState = useCallback(function (_query) {
    prevQuery.current = _query;
    history.pushState(_query, id, "".concat(baseUrl).concat(querystring.stringify(paramsFunc(_query), {
      encode: false
    }).toLowerCase()));
  }, [paramsFunc, baseUrl, id]);
  var replace = useCallback(function (_query) {
    prevQuery.current = _query;
    history.replaceState(_query, id, "".concat(baseUrl).concat(querystring.stringify(paramsFunc(_query), {
      encode: false
    }).toLowerCase()));
  }, [paramsFunc, baseUrl, id]);
  var onPopstate = useCallback(function (_ref) {
    var state = _ref.state;
    prevQuery.current = state || {};
    queryPopped.current = true;
    callback(state || {});
  }, [callback]);
  useEffect(function () {
    if (useReplace && !prevUseReplace.current) replace(query);
    prevUseReplace.current = useReplace;
  }, [useReplace, replace, query]);
  useEffect(function () {
    if (!queryPopped.current && !useReplace && !prevUseReplace.current && !equals(prevQuery.current, query)) pushState(query);else if (queryPopped.current) queryPopped.current = false;
  }, [query, useReplace, pushState]);
  useEffect(function () {
    window.addEventListener("popstate", onPopstate);
    return function () {
      var _window;
      return (_window = window) === null || _window === void 0 ? void 0 : _window.removeEventListener("popstate", onPopstate);
    };
  }, [onPopstate]);
}