import styled, { keyframes } from "styled-components";
import { addIcon, hoverStyle, focusStyle } from "@mixins";
import { fadeInAnim } from "@animation-ui";
var openAnim = keyframes(["from{height:0;}to{height:50px;}"]);
export var Icon = styled.span.withConfig({
  displayName: "notificationBannerstyles__Icon",
  componentId: "sc-uut2dw-0"
})(["&:before{", "}animation:", " 0.3s linear;margin:auto 10px auto 0;font-size:2.5rem;color:", ";"], function (_ref) {
  var theme = _ref.theme,
    icon = _ref.icon;
  return addIcon(theme, icon);
}, fadeInAnim, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.P1;
});
export var Close = styled.span.withConfig({
  displayName: "notificationBannerstyles__Close",
  componentId: "sc-uut2dw-1"
})(["&:before{", "}font-size:2.5rem;padding:12px;height:50px;margin:auto 0 auto auto;color:", ";transition:all 0.3s;&:hover{", "}&:focus{", "}"], function (_ref3) {
  var theme = _ref3.theme;
  return addIcon(theme, "cross");
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.P1;
}, function (_ref5) {
  var theme = _ref5.theme;
  return hoverStyle(theme);
}, function (_ref6) {
  var theme = _ref6.theme;
  return focusStyle(theme);
});
export var NotificationHolder = styled.div.withConfig({
  displayName: "notificationBannerstyles__NotificationHolder",
  componentId: "sc-uut2dw-2"
})(["animation:", " 0.3s ease;display:flex;width:100%;padding-left:15px;height:50px;background:", ";align-items:center;"], openAnim, function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.T2;
});