import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
import { equals } from "ramda";
export default function useDebouncedValue(delay, value) {
  var _useState = useState(value),
    _useState2 = _slicedToArray(_useState, 2),
    debouncedValue = _useState2[0],
    setDebouncedValue = _useState2[1];
  useEffect(function () {
    var handler = setTimeout(function () {
      if (!equals(value, debouncedValue)) {
        setDebouncedValue(value);
      }
    }, delay);
    return function () {
      clearTimeout(handler);
    };
  }, [debouncedValue, value, delay]);
  return debouncedValue;
}