import styled, { keyframes } from "styled-components";
import { equals } from "ramda";
import { addIcon, hoverStyle, focusStyle } from "@mixins";
var closeAnim = keyframes(["0%{width:330px;}"]);
var openAnim = keyframes(["0%{width:0;}"]);
export var StyledControlPanel = styled.section.withConfig({
  displayName: "controlpanelstyles__StyledControlPanel",
  componentId: "sc-11d732y-0"
})(["animation:", " 0.3s ease;", ""], function (_ref) {
  var open = _ref.open,
    prevOpen = _ref.prevOpen;
  return !equals(open, prevOpen) ? open ? closeAnim : openAnim : "";
}, function (_ref2) {
  var theme = _ref2.theme,
    open = _ref2.open,
    width = _ref2.width;
  return "\n    width: ".concat(width || "360px", ";\n    display: flex;\n    flex-direction: column;\n    background: ").concat(theme.colors.G1, ";\n    transform: rotate(0) translateY(0) translateX(0);\n\n    @media (max-width: ").concat(theme.breakpoints[4], "){\n      width: ").concat(width || "330px", "\n    }\n\n    @media (max-width: ").concat(theme.breakpoints[2], "){\n      width: 100%;\n      height: fit-content;\n      animation: none;\n    }\n\n    @media (min-width: ").concat(theme.breakpoints[2], "){\n      ").concat(!open ? "\n        height: fit-content;\n        transform: rotate(-90deg) translateY(-75px) translateX(-75px);\n        width: 210px;\n        margin-right: calc(-140px);\n\n        " : "", "\n    }");
});
export var Toggle = styled.button.withConfig({
  displayName: "controlpanelstyles__Toggle",
  componentId: "sc-11d732y-1"
})(["@media (min-width:", "){&:after{padding-left:10px;", "}}@media (max-width:", "){&:after{padding-left:10px;", "}}&:before{", "}", ""], function (_ref3) {
  var theme = _ref3.theme;
  return theme.breakpoints[2];
}, function (_ref4) {
  var theme = _ref4.theme,
    open = _ref4.open;
  return open ? addIcon(theme, "expandLeft", "auto 0 auto auto") : addIcon(theme, "expandDown", "auto 0 auto auto");
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.breakpoints[2];
}, function (_ref6) {
  var theme = _ref6.theme,
    open = _ref6.open;
  return open ? addIcon(theme, "expandUp", "auto 0 auto auto") : addIcon(theme, "expandDown", "auto 0 auto auto");
}, function (_ref7) {
  var theme = _ref7.theme;
  return addIcon(theme, "wrench", "auto 10px auto 0");
}, function (_ref8) {
  var theme = _ref8.theme;
  return "\n    height: 60px;\n    font-family: ".concat(theme.fonts.robotoRegular, ";\n    font-size: ").concat(theme.fontSizes.md, ";\n\n    padding: 10px 20px;\n    width: 100%;\n    display: flex;\n    align-items: center;\n\n    outline: none;\n    border: none;\n    background: ").concat(theme.colors.i5, ";\n    transition: all .3s;\n\n    &:hover{ ").concat(hoverStyle(theme), " }\n    &:focus{ ").concat(focusStyle(theme), " }\n  ");
});
export var Content = styled.div.withConfig({
  displayName: "controlpanelstyles__Content",
  componentId: "sc-11d732y-2"
})(["", ""], function (_ref9) {
  var open = _ref9.open;
  return "\n    flex: 1;\n    width: 100%;\n    visibility: ".concat(open ? "visible" : "hidden", ";\n    height: ").concat(open ? "100%" : 0, ";\n    display: ").concat(open ? "block" : "none", ";\n    overflow-y: auto;\n  ");
});