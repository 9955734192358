import axios from "axios";
import { prop } from "ramda";
import { webApiServiceUrl } from "@microServices";
export var getKeywordById = function getKeywordById(ids, authTicket) {
  return axios.post("".concat(webApiServiceUrl, "/api/keyword/keyword"), ids, {
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};
export var updateKeyword = function updateKeyword(keyword, authTicket) {
  return axios.put("".concat(webApiServiceUrl, "/api/keyword/keyword"), keyword, {
    headers: {
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};