import axios from "axios";
import { prop } from "ramda";
import { webApiServiceUrl } from "@microServices";
export default function exportExcel(excelParams, authTicket) {
  return axios.post("".concat(webApiServiceUrl, "/api/export/excel"), excelParams, {
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
}