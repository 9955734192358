import { ascend, sortWith } from "ramda";
export var allOrganizationTypes = {
  Land: {
    translation: "Riket"
  },
  Sjukvardsregion: {
    translation: "Sjukvårdsregioner",
    technicalName: "parentCareUnitRegions"
  },
  Region: {
    translation: "Regioner",
    technicalName: "parentRegionId"
  },
  Kommun: {
    translation: "Kommuner",
    technicalName: "parentMunicipaltyId"
  },
  Sjukhus: {
    translation: "Sjukhus"
  },
  Vardenhet: {
    translation: "Enheter"
  }
};
export var translateOrganizationType = function translateOrganizationType(type) {
  var _allOrganizationTypes;
  return (_allOrganizationTypes = allOrganizationTypes[type]) === null || _allOrganizationTypes === void 0 ? void 0 : _allOrganizationTypes.translation;
};
export var organizationTypeTechnicalName = function organizationTypeTechnicalName(type) {
  var _allOrganizationTypes2;
  return (_allOrganizationTypes2 = allOrganizationTypes[type]) === null || _allOrganizationTypes2 === void 0 ? void 0 : _allOrganizationTypes2.technicalName;
};
var organizationTypeKeys = Object.keys(allOrganizationTypes);
var byType = function byType(option) {
  return organizationTypeKeys.indexOf(option.type);
};
var byName = function byName(option) {
  return option.label.toLowerCase();
};
export var sortOrganizationsByType = sortWith([ascend(byType), ascend(byName)]);