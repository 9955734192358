import styled from "styled-components";
import { hiddenStyles, hoverStyle, extendedSystemStyles } from "@mixins";
export var styledSystemKeys = ["space", "layout", "flexbox"];
export var StyledInput = styled.input.withConfig({
  displayName: "uploadImagestyles__StyledInput",
  componentId: "sc-7ilwkd-0"
})(["", " &:focus + label{outline:-webkit-focus-ring-color auto 5px;}"], hiddenStyles);
export var UploadContainer = styled.div.withConfig({
  displayName: "uploadImagestyles__UploadContainer",
  componentId: "sc-7ilwkd-1"
})(["transition:all 0.3s;display:flex;box-shadow:0 0 0 3px ", ";", " &.highlighted{", "}"], function (_ref) {
  var theme = _ref.theme;
  return theme.colors.i1;
}, extendedSystemStyles(styledSystemKeys), function (_ref2) {
  var theme = _ref2.theme;
  return hoverStyle(theme);
});
export var UploadButton = styled.label.withConfig({
  displayName: "uploadImagestyles__UploadButton",
  componentId: "sc-7ilwkd-2"
})(["", ""], function (_ref3) {
  var theme = _ref3.theme;
  return "\n  margin: auto;\n  padding: 10px 20px;\n  display: inline-block;\n  font-family: ".concat(theme.fonts.robotoRegular, ";\n  font-size: ").concat(theme.fontSizes.md, ";\n  line-height: ").concat(theme.lineHeight.md, ";\n\n  transition: all .3s;\n  &:hover{ ").concat(hoverStyle(theme), " }");
});