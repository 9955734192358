import styled from "styled-components";
import { extendedSystemStyles } from "@mixins";
var styledSystemKeys = ["space", "color", "layout", "flexbox", "position", "border"];
export default {
  Column: styled.div.withConfig({
    displayName: "FlexContainer__Column",
    componentId: "sc-1hnie76-0"
  })(["display:flex;flex-direction:column;width:100%;", " ", ""], function (_ref) {
    var theme = _ref.theme,
      error = _ref.error;
    return "\n      box-shadow: ".concat(error ? "0 0 0 3px ".concat(theme.colors.V3) : "none", ";\n    ");
  }, extendedSystemStyles(styledSystemKeys)),
  Row: styled.div.withConfig({
    displayName: "FlexContainer__Row",
    componentId: "sc-1hnie76-1"
  })(["", " ", ""], function (_ref2) {
    var theme = _ref2.theme,
      breakpoint = _ref2.breakpoint,
      error = _ref2.error;
    return "\n      display: flex;\n      width:100%;\n      box-shadow: ".concat(error ? "0 0 0 3px ".concat(theme.colors.V3) : "none", ";\n      ").concat(breakpoint ? "\n        @media (max-width: ".concat(theme.breakpoints[breakpoint], "){\n          flex-direction: column;\n        }\n    ") : "");
  }, extendedSystemStyles(styledSystemKeys))
};