import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { equals, update, prop, curry } from "ramda";
import { addTypenameToCategory } from "@cms/utils";
import { GET_CATEGORY } from "@cms/categories/graphql";
import { sort } from "utils/sort";
export var optimisticCreateCategoryCache = function optimisticCreateCategoryCache(variables) {
  return {
    __typename: "CreateCategoryPayload",
    category: addTypenameToCategory(variables.input.category)
  };
};
export var optimisticUpdateCategoryCache = curry(function (query, variables, cache) {
  var data = cache.readQuery(query);
  var categoryToUpdate = data === null || data === void 0 ? void 0 : data.categories.find(function (category) {
    return equals(category.id, variables.input.id);
  });
  return {
    __typename: "UpdateCategoryPayload",
    category: addTypenameToCategory(_objectSpread(_objectSpread({}, categoryToUpdate), variables.input))
  };
});
export var createCategoryCache = curry(function (query, result, args, cache) {
  cache.inspectFields("Query").filter(function (field) {
    return field.fieldName === "categories";
  }).forEach(function (field) {
    cache.updateQuery({
      query: GET_CATEGORY,
      variables: field.arguments
    }, function (data) {
      return _objectSpread(_objectSpread({}, data), {}, {
        categories: sort(prop("title"), data.categories.concat(result.createCategory.category))
      });
    });
  });
});
export var updateCategoryCache = curry(function (query, result, args, cache) {
  cache.updateQuery(query, function (data) {
    var indexToUpdate = data.categories.findIndex(function (category) {
      return equals(category.id, result.updateCategory.category.id);
    });
    return _objectSpread(_objectSpread({}, data), {}, {
      categories: update(indexToUpdate, result.updateCategory.category, data.categories)
    });
  });
});
export var deleteCategoryCache = function deleteCategoryCache(result, args, cache) {
  cache.inspectFields("Query").filter(function (field) {
    return field.fieldName === "categories";
  }).forEach(function (field) {
    cache.updateQuery({
      query: GET_CATEGORY,
      variables: field.arguments
    }, function (data) {
      return _objectSpread(_objectSpread({}, data), {}, {
        categories: data.categories.filter(function (category) {
          return category.id !== args.input.id;
        })
      });
    });
  });
};