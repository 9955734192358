import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { v4 as uniqueId } from "uuid";
import { omit } from "ramda";
import { addIdsToComponent, removeTypenamesFromComposition } from "./contentEditor";
export default function copyComposition(composition) {
  var _composition$metadata, _composition$metadata2;
  return removeTypenamesFromComposition(_objectSpread(_objectSpread({}, composition), {}, {
    id: uniqueId(),
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    publishedBy: null,
    publishedDate: null,
    pageHeader: composition.pageHeader ? _objectSpread(_objectSpread({}, composition.pageHeader), {}, {
      id: uniqueId(),
      components: composition.pageHeader.components.map(addIdsToComponent)
    }) : null,
    metadata: omit(["category"], _objectSpread(_objectSpread({}, composition.metadata), {}, {
      categoryId: (_composition$metadata = composition.metadata) === null || _composition$metadata === void 0 ? void 0 : (_composition$metadata2 = _composition$metadata.category) === null || _composition$metadata2 === void 0 ? void 0 : _composition$metadata2.id
    })),
    views: composition.views.map(function (view) {
      return _objectSpread(_objectSpread({}, view), {}, {
        id: uniqueId(),
        chapters: view.chapters.map(function (chapter) {
          return _objectSpread(_objectSpread({}, chapter), {}, {
            id: uniqueId(),
            grids: chapter.grids.map(function (grid) {
              return _objectSpread(_objectSpread({}, grid), {}, {
                id: uniqueId(),
                components: grid.components.map(addIdsToComponent)
              });
            })
          });
        })
      });
    })
  }));
}