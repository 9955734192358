import { any } from "ramda";
import bvoResources from "@authentication/constants/bvoResources";
var findResource = function findResource(user, resource) {
  return user.resources.find(function (x) {
    return x.resourceName === bvoResources[resource];
  });
};
export var adminAccess = function adminAccess(user) {
  var _findResource;
  return (_findResource = findResource(user, "application")) === null || _findResource === void 0 ? void 0 : _findResource.hasAccess;
};
export var headKeywordAccess = function headKeywordAccess(user) {
  var _findResource2;
  return (_findResource2 = findResource(user, "headKeyword")) === null || _findResource2 === void 0 ? void 0 : _findResource2.hasAccess;
};
export var bannerAccess = function bannerAccess(user) {
  var _findResource3;
  return (_findResource3 = findResource(user, "banner")) === null || _findResource3 === void 0 ? void 0 : _findResource3.hasAccess;
};
export var systemStatusAccess = function systemStatusAccess(user) {
  var _findResource4;
  return (_findResource4 = findResource(user, "systemStatus")) === null || _findResource4 === void 0 ? void 0 : _findResource4.hasAccess;
};
export var mediaAccess = function mediaAccess(user) {
  var _findResource5;
  return (_findResource5 = findResource(user, "media")) === null || _findResource5 === void 0 ? void 0 : _findResource5.hasAccess;
};
export var developerAccess = function developerAccess(user) {
  var _findResource6;
  return (_findResource6 = findResource(user, "developer")) === null || _findResource6 === void 0 ? void 0 : _findResource6.hasAccess;
};
export var reportsAccess = function reportsAccess(user) {
  var _findResource7;
  return (_findResource7 = findResource(user, "report")) === null || _findResource7 === void 0 ? void 0 : _findResource7.hasAccess;
};
export var pageAccess = function pageAccess(user) {
  var _findResource8;
  return (_findResource8 = findResource(user, "page")) === null || _findResource8 === void 0 ? void 0 : _findResource8.hasAccess;
};
export var newsAccess = function newsAccess(user) {
  var _findResource9;
  return (_findResource9 = findResource(user, "news")) === null || _findResource9 === void 0 ? void 0 : _findResource9.hasAccess;
};
export var categoriesAccess = function categoriesAccess(user) {
  var _findResource10;
  return (_findResource10 = findResource(user, "category")) === null || _findResource10 === void 0 ? void 0 : _findResource10.hasAccess;
};
export var measuresAccess = function measuresAccess(user) {
  var _findResource11, _findResource12;
  return ((_findResource11 = findResource(user, "measures")) === null || _findResource11 === void 0 ? void 0 : _findResource11.hasAccess) && ((_findResource12 = findResource(user, "registries")) === null || _findResource12 === void 0 ? void 0 : _findResource12.hasAccess);
};
export var contenteditorAccess = function contenteditorAccess(user) {
  return reportsAccess(user) || pageAccess(user) || newsAccess(user) || categoriesAccess(user);
};
export var shortcutsAccess = function shortcutsAccess(user) {
  return [reportsAccess(user), bannerAccess(user), measuresAccess(user)].filter(Boolean).length > 1;
};
var resourceFuncs = [headKeywordAccess, bannerAccess, systemStatusAccess, mediaAccess, developerAccess, reportsAccess, pageAccess, newsAccess, categoriesAccess, contenteditorAccess];
var userAcces = function userAcces(user) {
  return {
    application: any(function (func) {
      return func(user);
    }, resourceFuncs),
    shortcuts: shortcutsAccess(user),
    measures: measuresAccess(user),
    headKeyword: headKeywordAccess(user),
    systemStatus: systemStatusAccess(user),
    page: pageAccess(user),
    report: reportsAccess(user),
    news: newsAccess(user),
    category: categoriesAccess(user),
    developer: developerAccess(user),
    contenteditor: contenteditorAccess(user),
    banner: bannerAccess(user),
    media: mediaAccess(user)
  };
};
export default userAcces;