import Sortable from "sortablejs";
import useCustomEffect from "./useCustomEffect";
export default function useSortable(id, draggable, callback) {
  useCustomEffect(function () {
    var options = {
      animation: 300,
      draggable: ".".concat(draggable),
      handle: ".sort-handle",
      onMove: function onMove(evt) {
        return evt.related.classList.contains(draggable);
      },
      onUpdate: function onUpdate(evt) {
        return callback(evt.oldIndex, evt.newIndex);
      },
      filter: ".ignore-drag",
      direction: "vertical"
    };
    var el = document.getElementById(id);
    Sortable.create(el, options);
  }, [id]);
}