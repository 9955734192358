import { useCallback } from "react";
import { blockDefaultEvent } from "utils/events";
import { useCustomEffect } from "hooks";
export default function useHandleKeyDown(id, containerRef, optionsRef, searchRef, open, setOpen, activeFocus, setActiveFocus, searchString, setSearchString, options) {
  var close = useCallback(function () {
    setOpen(false);
    setActiveFocus(-1);
    if (document.activeElement !== searchRef.current) searchRef.current.focus();
  }, [setOpen, setActiveFocus, searchRef]);
  useCustomEffect(function () {
    if (document.activeElement === optionsRef.current) {
      var ele = document.getElementById("".concat(id, "-option-").concat(activeFocus));
      ele.scrollIntoView({
        block: "nearest"
      });
    }
  }, [activeFocus]);
  var handleKeyDown = function handleKeyDown(e) {
    if (open && [9, 13, 27, 38, 40].includes(e.keyCode)) blockDefaultEvent(e);
    //Enter, Tab
    if (open && [13, 9].includes(e.keyCode)) close();
    //Escape
    else if (open && [27].includes(e.keyCode)) {
      close();
      setSearchString("");
    }

    //ArrowDown
    else if (e.keyCode === 40) {
      setActiveFocus(function (old) {
        return old < options.length - 1 ? old + 1 : 0;
      });
      if (document.activeElement !== optionsRef.current) optionsRef.current.focus();
      if (!open) setOpen(true);
    }
    //ArrowUp
    else if (e.keyCode === 38) {
      setActiveFocus(function (old) {
        return old > 0 ? old - 1 : options.length - 1;
      });
      if (document.activeElement !== optionsRef.current) optionsRef.current.focus();
      if (!open) setOpen(true);
    }

    //ArrowRight
    else if (e.keyCode === 39 || e.keyCode === 37) {
      if (document.activeElement !== searchRef.current) {
        setActiveFocus(-1);
        searchRef.current.focus();
      }
    }

    //Home, End
    else if (open && (e.keyCode === 36 || e.keyCode === 35)) {
      if (document.activeElement !== searchRef.current) searchRef.current.focus();
      setActiveFocus(-1);
    }
  };
  useCustomEffect(function () {
    var containerRefCopy = containerRef.current;
    containerRef.current.addEventListener("keydown", handleKeyDown);
    return function () {
      return containerRefCopy === null || containerRefCopy === void 0 ? void 0 : containerRefCopy.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, options, activeFocus]);
}