export var debounce = function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var _this = this;
    var args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(_this, args);
    }, wait);
    if (immediate && !timeout) func.apply(this, args);
  };
};