import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { curry } from "ramda";
export var dateConvertKeys = curry(function (keys, obj) {
  return keys.filter(function (key) {
    return obj[key];
  }).reduce(function (acc, curr) {
    if (!acc[curr] || isNaN(new Date(acc[curr]))) return acc;
    acc[curr] = new Date(acc[curr]);
    return acc;
  }, _objectSpread({}, obj));
});
export var getIsoDate = function getIsoDate(dateObj) {
  var pad = function pad(x) {
    return x.toString().length < 2 ? "0".concat(x) : x;
  };
  if (!dateObj || isNaN(dateObj) || typeof dateObj.getUTCFullYear === "undefined") return null;
  return "".concat(dateObj.getUTCFullYear(), "-").concat(pad(dateObj.getUTCMonth() + 1), "-").concat(pad(dateObj.getUTCDate()));
};
export var isoDateConvertKeys = curry(function (keys, obj) {
  return keys.filter(function (key) {
    return obj[key];
  }).reduce(function (acc, curr) {
    if (!acc[curr] || isNaN(new Date(acc[curr]))) return acc;
    acc[curr] = getIsoDate(new Date(acc[curr]));
    return acc;
  }, _objectSpread({}, obj));
});