import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useMutation } from "urql";
import { curry } from "ramda";
import { UPDATE_CATEGORY, CREATE_CATEGORY, DELETE_CATEGORY } from "@cms/categories/graphql";
export var useCategories = function useCategories() {
  var _useMutation = useMutation(UPDATE_CATEGORY),
    _useMutation2 = _slicedToArray(_useMutation, 2),
    _useMutation2$ = _useMutation2[0],
    updateError = _useMutation2$.error,
    updating = _useMutation2$.fetching,
    updateCategory = _useMutation2[1];
  var _useMutation3 = useMutation(CREATE_CATEGORY),
    _useMutation4 = _slicedToArray(_useMutation3, 2),
    _useMutation4$ = _useMutation4[0],
    createError = _useMutation4$.error,
    creating = _useMutation4$.fetching,
    createCategory = _useMutation4[1];
  var _useMutation5 = useMutation(DELETE_CATEGORY),
    _useMutation6 = _slicedToArray(_useMutation5, 2),
    _useMutation6$ = _useMutation6[0],
    deleteError = _useMutation6$.error,
    deleting = _useMutation6$.fetching,
    deleteCategory = _useMutation6[1];
  var mutation = curry(function (func, input) {
    return func({
      input: input
    });
  });
  return {
    updateCategory: mutation(updateCategory),
    updatingCategory: updating,
    updateCategoryError: updateError,
    createCategory: mutation(createCategory),
    creatingCategory: creating,
    createCategoryError: createError,
    deleteCategory: mutation(deleteCategory),
    deleteCategoryError: deleteError,
    deletingCategory: deleting,
    loading: updating || deleting
  };
};