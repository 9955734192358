import styled from "styled-components";
import { extendedSystemStyles } from "@mixins";
import { opacityInAnim } from "@animation-ui";
var styledSystemKeys = ["space", "layout", "grid"];
export default styled.ol.withConfig({
  displayName: "R1C2_1x1",
  componentId: "sc-k2ddwr-0"
})(["animation:", " 1s;", " ", ""], opacityInAnim, function (_ref) {
  var theme = _ref.theme;
  return "\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 5px;\n\n    @media (max-width: ".concat(theme.breakpoints[2], "){\n      display: grid;\n      grid-template-columns: 1fr;\n\n      img {\n        padding: 0;\n      }\n    }");
}, extendedSystemStyles(styledSystemKeys));