import styled from "styled-components";
import { tertiaryLarge, extendedSystemStyles } from "@mixins";
var styledSystemKeys = ["space", "layout", "color", "typography"];
export default styled.p.withConfig({
  displayName: "TertiaryLarge",
  componentId: "sc-1xhuxph-0"
})(["", " ", ""], function (_ref) {
  var theme = _ref.theme,
    inverted = _ref.inverted;
  return "\n    ".concat(tertiaryLarge(theme), "\n    color: ").concat(!inverted ? theme.colors.G4 : theme.colors.G1, ";\n    margin: 10px 0;");
}, extendedSystemStyles(styledSystemKeys));