import styled from "styled-components";
import { hoverStyle, focusStyle, extendedSystemStyles, activeStyle } from "@mixins";
var styledSystemKeys = ["space", "layout", "flexbox"];
export default styled.button.withConfig({
  displayName: "ParentButton",
  componentId: "sc-uym3zk-0"
})(["padding:0;display:flex;background:none;transition:all 0.3s;", " ", ""], function (_ref) {
  var theme = _ref.theme,
    selected = _ref.selected,
    active = _ref.active;
  return "\n    cursor: ".concat(selected ? "initial" : "pointer", ";\n    ").concat(active ? activeStyle(theme) : "", "\n    &:hover{\n      ").concat(hoverStyle(theme), "\n    }\n    &:focus {\n      ").concat(focusStyle(theme), "\n    }\n  ");
}, extendedSystemStyles(styledSystemKeys));