import Fluid from "./Fluid";
import R1CX_1 from "./R1CX_1";
import R1C1_1 from "./R1C1_1";
import R1C2_1x1 from "./R1C2_1x1";
import R1C3_1x1x1 from "./R1C3_1x1x1";
import R1C4_1x1x1x1 from "./R1C4_1x1x1x1";
import R1C2_1x2 from "./R1C2_1x2";
import R1C2_1x3 from "./R1C2_1x3";
import R1C2_1x4 from "./R1C2_1x4";
import R1C2_2x1 from "./R1C2_2x1";
import Carousel from "./Carousel/index";
export default {
  Fluid: Fluid,
  R1CX_1: R1CX_1,
  R1C1_1: R1C1_1,
  R1C2_1x1: R1C2_1x1,
  R1C3_1x1x1: R1C3_1x1x1,
  R1C4_1x1x1x1: R1C4_1x1x1x1,
  R1C2_1x2: R1C2_1x2,
  R1C2_1x3: R1C2_1x3,
  R1C2_1x4: R1C2_1x4,
  R1C2_2x1: R1C2_2x1,
  Carousel: Carousel
};