import styled from "styled-components";
import { hiddenStyles, colorFullSroll, tertiarySmall, subText, addIcon, hoverStyle, focusStyle, activeStyle, ellipsis, extendedSystemStyles } from "@mixins";
export var styledSystemKeys = ["layout", "space", "color", "flexbox", "typography", "position"];
var commonStyles = function commonStyles(theme) {
  return "\n  ".concat(subText(theme), "\n  text-align: left;\n  ").concat(extendedSystemStyles(styledSystemKeys));
};
export var TableContainer = styled.section.withConfig({
  displayName: "tableWrapperstyles__TableContainer",
  componentId: "sc-idlodr-0"
})(["", "", ""], function (_ref) {
  var theme = _ref.theme;
  return "\n  overflow-x: auto;\n  padding-bottom: 10px;\n  margin: 0;\n  width: 100%;\n  ".concat(colorFullSroll(theme, "100%", true), "\n");
}, extendedSystemStyles(styledSystemKeys));
export var Caption = styled.caption.withConfig({
  displayName: "tableWrapperstyles__Caption",
  componentId: "sc-idlodr-1"
})(["", ""], hiddenStyles);
export var Table = styled.table.withConfig({
  displayName: "tableWrapperstyles__Table",
  componentId: "sc-idlodr-2"
})(["width:100%;border-spacing:1px 4px;"]);
export var Tr = styled.tr.withConfig({
  displayName: "tableWrapperstyles__Tr",
  componentId: "sc-idlodr-3"
})(["", "", ""], function (_ref2) {
  var theme = _ref2.theme;
  return "\n    &:not(:first-child){\n      background: ".concat(theme.colors.G1, ";\n    }\n    &:not(:first-child, :last-child){\n      box-shadow: 0 1px 0 0 ").concat(theme.colors.G4, "1a;\n    }\n  ");
}, extendedSystemStyles(styledSystemKeys));
export var Th = styled.th.withConfig({
  displayName: "tableWrapperstyles__Th",
  componentId: "sc-idlodr-4"
})(["padding:0;", ""], extendedSystemStyles(styledSystemKeys));
export var ColumnLabel = styled.p.withConfig({
  displayName: "tableWrapperstyles__ColumnLabel",
  componentId: "sc-idlodr-5"
})(["", "", ""], function (_ref3) {
  var theme = _ref3.theme;
  return "\n    ".concat(tertiarySmall(theme), "\n    line-height: 1.2rem;\n    margin: 0 auto;\n    padding: 29px 20px 4px;\n  ");
}, extendedSystemStyles(styledSystemKeys));
export var SortableColumn = styled.button.withConfig({
  displayName: "tableWrapperstyles__SortableColumn",
  componentId: "sc-idlodr-6"
})(["&:after{position:absolute;right:5px;bottom:5px;line-height:2.4rem;", "}", "", ""], function (_ref4) {
  var theme = _ref4.theme,
    icon = _ref4.icon;
  return addIcon(theme, icon);
}, function (_ref5) {
  var theme = _ref5.theme,
    active = _ref5.active,
    disabled = _ref5.disabled;
  return "\n    ".concat(tertiarySmall(theme), "\n    line-height: 1.2rem;\n    padding: 29px 35px 4px 20px;\n    background: none;\n    position: relative;\n    text-align: left;\n    width: 100%;\n    height: 45px;\n    transition: all .3s;\n\n    &:hover{ ").concat(!disabled && hoverStyle(theme) || "", " }\n    &:focus{ ").concat(focusStyle(theme), " }\n    ").concat(active ? activeStyle(theme) : "", "\n\n  ");
}, extendedSystemStyles(styledSystemKeys));
export var Td = styled.td.withConfig({
  displayName: "tableWrapperstyles__Td",
  componentId: "sc-idlodr-7"
})(["", "", ""], function (_ref6) {
  var theme = _ref6.theme,
    ellipse = _ref6.ellipse;
  return "\n    ".concat(commonStyles(theme), "\n    box-shadow: -1px 0 0 0 ").concat(theme.colors.G4, "1a;\n    ").concat(ellipse ? ellipsis : "", "\n  ");
}, extendedSystemStyles(styledSystemKeys));
export default {
  TableContainer: TableContainer,
  Caption: Caption,
  Table: Table,
  Tr: Tr,
  Th: Th,
  ColumnLabel: ColumnLabel,
  SortableColumn: SortableColumn,
  Td: Td
};