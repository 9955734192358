import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useRef, useCallback, useMemo } from "react";
import { all } from "ramda";
import { useOpen, useClickOutside } from "hooks";
import handleKeyDown from "./handleKeyDown";
import { blockDefaultEvent } from "utils/events";
import { addRemoveListContent } from "utils/addRemoveListContent";
export default function useGroupedMultiSelect(id, onChange, search, options, flatOptions, searchString, parentRef) {
  var _useOpen = useOpen(false),
    open = _useOpen.open,
    setOpen = _useOpen.setOpen;
  var _useState = useState([]),
    _useState2 = _slicedToArray(_useState, 2),
    openGroups = _useState2[0],
    setOpenGroups = _useState2[1];
  var _useState3 = useState(-1),
    _useState4 = _slicedToArray(_useState3, 2),
    activeFocus = _useState4[0],
    setActiveFocus = _useState4[1]; //idx
  var containerRef = useRef();
  var optionsRef = useRef();
  var searchRef = useRef();
  var hasChildren = function hasChildren(x) {
    var _x$children;
    return ((_x$children = x.children) === null || _x$children === void 0 ? void 0 : _x$children.length) > 0;
  };
  var parentsAreOpen = function parentsAreOpen(x) {
    return !x.parents || all(function (id) {
      return openGroups.includes(id);
    }, x.parents);
  };
  var visibleOptions = useMemo(function () {
    return new Map();
  }, []);
  flatOptions.forEach(function (option) {
    if (option.parents) {
      if (parentsAreOpen(option)) visibleOptions.set(option.id, visibleOptions.size);
    } else visibleOptions.set(option.id, visibleOptions.size);
  });
  var handleEvent = useCallback(function (e) {
    blockDefaultEvent(e);
    var option = visibleOptions[activeFocus];
    if (hasChildren(option) && e.keyCode === 13) {
      //enter
      addRemoveListContent(setOpenGroups, null, !openGroups.includes(option.id), option.id);
    } else if (e.keyCode === 32) {
      //space
      if (!option.disabled) onChange(!option.checked, option.id);
    }
  }, [visibleOptions, activeFocus, onChange, setOpenGroups, openGroups]);
  var handleSearchString = function handleSearchString(string) {
    if (!open && string.length > 2) setOpen(true);
    search(string);
  };
  handleKeyDown(id, containerRef, optionsRef, searchRef, open, setOpen, activeFocus, setActiveFocus, searchString, search, visibleOptions, setOpenGroups);
  useClickOutside(parentRef || containerRef, function () {
    setOpen(false);
    setActiveFocus(-1);
  }, open);
  return {
    open: open,
    setOpen: setOpen,
    openGroups: openGroups,
    setOpenGroups: setOpenGroups,
    activeFocus: activeFocus,
    containerRef: containerRef,
    optionsRef: optionsRef,
    searchRef: searchRef,
    handleEvent: handleEvent,
    handleSearchString: handleSearchString,
    visibleOptions: visibleOptions
  };
}