import styled from "styled-components";
import { focusStyle, colorFullSroll } from "@mixins";
export var Options = styled.ul.withConfig({
  displayName: "multiselectstyles__Options",
  componentId: "sc-g0vomu-0"
})(["", ""], function (_ref) {
  var theme = _ref.theme,
    open = _ref.open;
  return "\n    display: ".concat(open ? "flex" : "none", ";\n    flex-direction: column;\n    padding: 0;\n    margin: 0;\n    max-height: calc(44px * 8);\n    overflow-y: auto;\n    ").concat(colorFullSroll(theme, "10px", true), "\n\n    border: 3px solid ").concat(theme.colors.i1, ";\n    background: ").concat(theme.colors.G1, ";\n    z-index: 9;\n    border-top: none;\n    outline: none;\n\n    position: absolute;\n    top: 100%;\n    left: 0;\n    right: 0;\n  ");
});
export var Option = styled.li.withConfig({
  displayName: "multiselectstyles__Option",
  componentId: "sc-g0vomu-1"
})(["", ""], function (_ref2) {
  var theme = _ref2.theme,
    focused = _ref2.focused;
  return "\n    list-style: none;\n\n    ".concat(focused ? focusStyle(theme) : "", "\n  ");
});