import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import axios from "axios";
import { prop } from "ramda";
import { webApiServiceUrl } from "@microServices";
var cleanParams = function cleanParams(params) {
  return _objectSpread(_objectSpread({}, params), {}, {
    periodType: params.periodType || "None" //Might be empty string or null, api wants it to be the enum None.
  });
};

export var getMeasurements = function getMeasurements(query, authTicket) {
  return axios.post("".concat(webApiServiceUrl, "/api/measurements/measurementswithmetadata"), cleanParams(query), {
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};