import styled from "styled-components";
import { extendedSystemStyles } from "@mixins";
var styledSystemKeys = ["space", "color", "layout", "flexbox", "position"];
export default styled.div.withConfig({
  displayName: "Spacer",
  componentId: "sc-1mvk2gi-0"
})(["", " ", ""], function (_ref) {
  var theme = _ref.theme,
    scrollY = _ref.scrollY,
    controlPanelPage = _ref.controlPanelPage;
  return "\n    flex: 1;\n    overflow-y: ".concat(scrollY || controlPanelPage ? "auto" : "hidden", ";\n\n    @media (max-width: ").concat(theme.breakpoints[2], "){\n      overflow-y: ").concat(controlPanelPage ? "initial" : "", ";\n    }");
}, extendedSystemStyles(styledSystemKeys));