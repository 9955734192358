import styled from "styled-components";
import { mainText, extendedSystemStyles } from "@mixins";
export var styledSystemKeys = ["layout", "flexbox", "space", "typography"];
export var StyledContainer = styled.div.withConfig({
  displayName: "formattedTextstyles__StyledContainer",
  componentId: "sc-38kw7u-0"
})(["", "", ""], function (_ref) {
  var theme = _ref.theme,
    inverted = _ref.inverted;
  return "\n  ".concat(mainText(theme), "\n  background: ").concat(inverted ? theme.colors.P3 : "", ";\n  color: ").concat(inverted ? theme.colors.G3 : "", ";\n  strong{\n    font-family: ").concat(theme.fonts.robotoMedium, ";\n    font-weight: normal;\n  }\n  ul, ol {\n    margin-left: 20px;\n  }\n  p{ margin: 0 0 10px; }\n  .ql-indent-1{ margin-left: 40px; }\n  .ql-indent-2{ margin-left: 80px; }\n  .ql-indent-3{ margin-left: 120px; }\n  ol li{ list-style: decimal; }\n  ul li{\n    list-style: none;\n    &:before {\n      content: '';\n      width: 8px;\n      border-top: 1px solid ").concat(theme.colors.P1, ";\n      display: block;\n      left: -16px;\n      top: 13px;\n      position: relative;\n    }\n  }\n  li ol li {\n    list-style: lower-alpha;\n    ol li { list-style: lower-roman; }\n    ul li { list-style: initial; }\n  }\n");
}, extendedSystemStyles(styledSystemKeys));