import styled from "styled-components";
import { positionAbsolute } from "@mixins";
export var FadedBackground = styled.div.withConfig({
  displayName: "styles__FadedBackground",
  componentId: "sc-tx40w8-0"
})(["", " background:", ";opacity:0.7;"], positionAbsolute(0, 0, 0, 0), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.P1;
});
export var StyledPopup = styled.div.withConfig({
  displayName: "styles__StyledPopup",
  componentId: "sc-tx40w8-1"
})(["margin:auto;z-index:1;"]);