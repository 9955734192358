import axios from "axios";
import { prop } from "ramda";
import { webApiServiceUrl } from "@microServices";
export var getKeywordGroupById = function getKeywordGroupById(id) {
  return axios.get("".concat(webApiServiceUrl, "/api/keyword/keywordgroup?id=").concat(id), {
    headers: {
      "Content-Type": "application/json"
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};
export var getAllKeywordGroups = function getAllKeywordGroups() {
  return axios.get("".concat(webApiServiceUrl, "/api/keyword/keywordgroup/all"), {
    headers: {
      "Content-Type": "application/json"
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};
export var createKeywordGroup = function createKeywordGroup(keywordGroup, authTicket) {
  return axios.post("".concat(webApiServiceUrl, "/api/keyword/keywordgroup"), keywordGroup, {
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};
export var updateKeywordGroup = function updateKeywordGroup(keywordGroup, authTicket) {
  return axios.put("".concat(webApiServiceUrl, "/api/keyword/keywordgroup"), keywordGroup, {
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};
export var removeKeywordGroup = function removeKeywordGroup(id, authTicket) {
  return axios.delete("".concat(webApiServiceUrl, "/api/keyword/keywordgroup?id=").concat(id), {
    headers: {
      "x-bvo-ticket": authTicket
    }
  });
};