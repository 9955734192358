import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { css } from "styled-components";
import graphIcons from "constants/icons/graphs";
import infoIcons from "constants/icons/info";
import interactiveElIcons from "constants/icons/interactiveElements";
import socialIcons from "constants/icons/social";
import visualizationIcons from "constants/icons/visualizations";
var iconLibrary = {
  graphIcons: graphIcons,
  infoIcons: infoIcons,
  interactiveElIcons: interactiveElIcons,
  socialIcons: socialIcons,
  visualizationIcons: visualizationIcons
};
var allIcons = Object.keys(iconLibrary).reduce(function (acc, key) {
  return _objectSpread(_objectSpread({}, acc), iconLibrary[key]);
}, {});
export var icons = _objectSpread(_objectSpread({}, iconLibrary), {}, {
  allIcons: allIcons
});
export var addIcon = function addIcon(theme, icon, margin) {
  var size = (icon === null || icon === void 0 ? void 0 : icon.split("_")[1]) || "sm";
  return icons.allIcons[icon] ? css(["margin:", ";font-size:", ";font-family:VIS_icons;content:", ";"], margin, theme.iconSizes[size], "'".concat(icons.allIcons[icon], "'")) : "";
};