import styled from "styled-components";
import { focusStyle, addIcon, hiddenStyles, ellipsis, mainText, extendedSystemStyles } from "@mixins";
export var styledSystemKeys = ["space", "layout", "flexbox"];
export var CheckboxContainer = styled.div.withConfig({
  displayName: "checkboxstyles__CheckboxContainer",
  componentId: "sc-1v5hwfn-0"
})(["", "", ""], function (_ref) {
  var theme = _ref.theme,
    disabled = _ref.disabled,
    hideLabel = _ref.hideLabel;
  return "\n    display: flex;\n    padding: 0px 20px;\n    width: ".concat(!hideLabel ? "100%" : "fit-content", ";\n    box-sizing: border-box;\n    transition: all .3s;\n\n    ").concat(!disabled ? "\n      &:hover {\n        input { box-shadow: inset 0 0 0 3px ".concat(theme.colors.P0, "; }\n      }\n    ") : "");
}, extendedSystemStyles(styledSystemKeys));
export var CheckboxLabel = styled.label.withConfig({
  displayName: "checkboxstyles__CheckboxLabel",
  componentId: "sc-1v5hwfn-1"
})(["", ""], function (_ref2) {
  var theme = _ref2.theme,
    disabled = _ref2.disabled,
    hideLabel = _ref2.hideLabel,
    ellipse = _ref2.ellipse,
    align = _ref2.align;
  return "\n    ".concat(mainText(theme), "\n\n    min-height: 44px;\n    width: calc(100% - 24px);\n    cursor: ").concat(!disabled ? "pointer" : "inital", ";\n    padding: ").concat(align === "right" ? "10px 0" : "10px 0 10px 10px", ";\n    color: ").concat(!disabled ? theme.colors.G4 : "".concat(theme.colors.P2, "a8"), ";\n\n    ").concat(hideLabel ? hiddenStyles : "", "\n    ").concat(ellipse ? ellipsis : "");
});
export var StyledCheckbox = styled.input.withConfig({
  displayName: "checkboxstyles__StyledCheckbox",
  componentId: "sc-1v5hwfn-2"
})(["&:after{", "}", ""], function (_ref3) {
  var theme = _ref3.theme,
    checked = _ref3.checked;
  return checked === true ? addIcon(theme, "checkboxChecked_xs", "auto") : checked === "mixed" ? addIcon(theme, "checkboxPartial_xs", "auto") : "";
}, function (_ref4) {
  var theme = _ref4.theme,
    disabled = _ref4.disabled,
    hideLabel = _ref4.hideLabel;
  return "\n    height: 24px;\n    width: 24px;\n    margin: auto 0;\n    display: flex;\n\n    cursor: ".concat(!disabled ? "pointer" : "inital", ";\n\n    color: ").concat(!disabled ? theme.colors.P0 : "".concat(theme.colors.P2, "a8"), ";\n    box-shadow: ").concat(!disabled ? "inset 0 0 0 3px ".concat(theme.colors.i1) : "inset 0 0 0 3px ".concat(theme.colors.i1, "80"), ";\n\n    border-radius: ").concat(theme.actionStyles.buttonRadius, ";\n\n    border: none;\n    outline: none;\n    appearance: none;\n    transition: all .3s;\n\n    ").concat(hideLabel ? "\n      &:focus{ ".concat(focusStyle(theme), " }\n    ") : "");
});