import testTheme from "./test";
import stagingTheme from "./staging";
import prodTheme from "./prod";
var themes = {
  "vis.local": prodTheme,
  "test.vardenisiffror.se": testTheme,
  "dev.vardenisiffror.se": testTheme,
  "staging.vardenisiffror.se": stagingTheme,
  "qa.vardenisiffror.se": stagingTheme
};
export var theme = themes[Object.keys(themes).find(function (x) {
  return location.origin.includes(x);
})] || prodTheme;