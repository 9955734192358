import styled from "styled-components";
import { rangeStyles } from "../rangeCommon.styles";
export var InputContainer = styled.div.withConfig({
  displayName: "multiRangestyles__InputContainer",
  componentId: "sc-1rrevhn-0"
})(["", ""], function (_ref) {
  var theme = _ref.theme;
  return "\n    height: 44px;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n\n    &:nth-child(1) { background: ".concat(theme.colors.P2, "; }\n    &:nth-child(2) {\n      position: absolute;\n      text-align: right;\n      box-shadow: inset 0 0 0 1px ").concat(theme.colors.i1, ";\n    }");
});
export var Range1 = styled.input.withConfig({
  displayName: "multiRangestyles__Range1",
  componentId: "sc-1rrevhn-1"
})(["", ""], function (_ref2) {
  var theme = _ref2.theme,
    disabled = _ref2.disabled;
  return "\n    ".concat(rangeStyles(theme, disabled), "\n    &::-webkit-slider-thumb {\n      z-index: 2;\n      margin-top: -1px;\n    }");
});
export var Range2 = styled.input.withConfig({
  displayName: "multiRangestyles__Range2",
  componentId: "sc-1rrevhn-2"
})(["", ""], function (_ref3) {
  var theme = _ref3.theme,
    width = _ref3.width,
    offset = _ref3.offset,
    disabled = _ref3.disabled;
  return "\n    ".concat(rangeStyles(theme, disabled), "\n    background: ").concat(theme.colors.G1, ";\n    border-left: 1px solid ").concat(theme.colors.i1, ";\n    border-bottom: 1px solid ").concat(theme.colors.i1, ";\n\n    &::-webkit-slider-thumb {\n      z-index: 3;\n    }\n\n    &:after {\n      content: '';\n      height: 22px;\n      width: calc(").concat(width, "%);\n      transform: translateX(").concat(offset, "%);\n      display: flex;\n      background: ").concat(disabled ? "".concat(theme.colors.P2, "80") : theme.colors.i2, ";\n      position: absolute;\n    }");
});