import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { pipe } from "ramda";
import { getPeriod, arrayAnnotationDate } from "utils/periods";
export var defaultDates = {
  earliestDataDate: new Date("1989"),
  lastDataDate: new Date()
};
var quarterMonth = function quarterMonth(month) {
  return month === "Januari" || month === "April" || month === "Juli" || month === "Oktober";
};
var shouldTruncatePeriod = function shouldTruncatePeriod(periodType) {
  return ["Quarter", "Tertials", "Month", "HalfYear"].indexOf(periodType) !== -1;
};
var firstPeriodOfYear = function firstPeriodOfYear(period) {
  return period === "T1" || period === "Q1" || period === "H1" || period === "Januari";
};
var everyOther = function everyOther(array) {
  return array.filter(function (x, idx) {
    return idx % 2 === 0;
  });
};
var capToYears = function capToYears(dateTos, periodType, cappedTicks) {
  var dates = dateTos;
  if (shouldTruncatePeriod(periodType)) {
    dates = dateTos.filter(function (startdate) {
      return pipe(arrayAnnotationDate(periodType), function (x) {
        return firstPeriodOfYear(x[0]);
      })(startdate);
    });
  }
  return dates.length > cappedTicks ? everyOther(dates) : dates;
};

/*
Sometimes we have a date that isn't a proper start/end date for the given periodType,
the method ensures that it's a valid date.
*/
export var adjustDatesToPeriod = function adjustDatesToPeriod(x) {
  return x.dateFrom && x.dateTo && x.periodType && _objectSpread(_objectSpread({}, x), {}, {
    dateFrom: getPeriod(x.periodType, x.dateFrom).start,
    dateTo: getPeriod(x.periodType, x.dateTo).end
  }) || x;
};
export var filterTicks = function filterTicks(dateTos, periodType, cappedTicks) {
  if (dateTos.length - 1 <= cappedTicks) return dateTos;
  if (periodType !== "Month") return capToYears(dateTos, periodType, cappedTicks);
  var filtered = dateTos.filter(function (startdate) {
    return pipe(arrayAnnotationDate(periodType), function (x) {
      return quarterMonth(x[0]);
    })(startdate);
  });
  return filtered.length > cappedTicks ? capToYears(filtered, periodType, cappedTicks) : filtered;
};

/**
 * Converts an array of years to a string of ranges
 * e. g. [2010, 2011, 2012, 2014, 2015, 2016] => "2010 - 2012, 2014 - 2016"
 * @param {Array} years years to convert to ranges
 * @returns string of ranges
 */
export var toRanges = function toRanges(years) {
  if (!years || years.length === 0) return "";
  var ranges = [];
  var start = years[0];
  var end = years[0];
  for (var i = 1; i < years.length; i++) {
    if (years[i] === end + 1)
      // If the current year is consecutive, update the end
      end = years[i];else {
      // If the current year is not consecutive or the range is less than 3 years
      if (end - start < 2) {
        ranges.push(start);
        if (start !== end) ranges.push(end);
      } else ranges.push("".concat(start, " - ").concat(end));
      start = end = years[i];
    }
  }

  // Push the last range or year
  if (end - start < 2) {
    ranges.push(start);
    if (start !== end) ranges.push(end);
  } else ranges.push("".concat(start, " - ").concat(end));
  return ranges.join(", ");
};