import { useMemo, useRef } from "react";
import { onPush, pipe } from "wonka";
import { createClient, dedupExchange, fetchExchange, errorExchange } from "urql";
import { cacheExchange } from "@urql/exchange-graphcache";
import { initializeErrorNotification } from "@notifications/errorNotifications";
import { useAuthContext } from "@authentication/context";
import { isEmptyArray } from "utils/validation";
import { translateError } from "utils/errors";

/* eslint-disable */
export var initiateUrqlClient = function initiateUrqlClient(url) {
  var cache = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var setLastMutationResultDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (x) {
    return x;
  };
  var _useAuthContext = useAuthContext(),
    getUser = _useAuthContext.getUser,
    eraseUser = _useAuthContext.eraseUser;
  var inFlightMutations = useRef([]);
  var globalFetchExchange = function globalFetchExchange(_ref) {
    var forward = _ref.forward;
    return function (operations$) {
      var operationResults$ = forward(pipe(operations$, onPush(function (op) {
        var kind = op.kind;
        if (kind === "mutation") {
          inFlightMutations.current = inFlightMutations.current.concat(1);
        }
      })));
      return pipe(operationResults$, onPush(function (op) {
        var data = op.data,
          error = op.error,
          operation = op.operation;
        if (data && operation.kind === "mutation" && !isEmptyArray(inFlightMutations.current)) {
          inFlightMutations.current = inFlightMutations.current.slice(1);
          setLastMutationResultDate(new Date());
        } else if (error) inFlightMutations.current = [];
      }));
    };
  };
  return useMemo(function () {
    return createClient({
      url: url,
      fetchOptions: function fetchOptions() {
        var _getUser;
        return {
          headers: {
            "x-bvo-ticket": (_getUser = getUser()) !== null && _getUser !== void 0 && _getUser.ticket ? getUser().ticket : ""
          }
        };
      },
      exchanges: [dedupExchange, globalFetchExchange, cacheExchange(cache), errorExchange({
        onError: function onError(error) {
          var isAuthError = error.graphQLErrors.some(function (e) {
            var _e$extensions;
            return ((_e$extensions = e.extensions) === null || _e$extensions === void 0 ? void 0 : _e$extensions.code) === "FORBIDDEN";
          });
          console.log("error", error);
          initializeErrorNotification("".concat(translateError(error.message)), "showErrorNotification", "closeErrorNotification");
          if (isAuthError) {
            eraseUser();
          }
        }
      }), fetchExchange]
    });
  }, [cache]);
};