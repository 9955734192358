export var ellipsis = "\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;";
export var mainText = function mainText(theme) {
  return "\n  font-family: ".concat(theme.fonts.robotoLight, ";\n  font-size: ").concat(theme.fontSizes.md, ";\n  line-height: ").concat(theme.lineHeight.md, ";\n  font-weight: 100;");
};
export var subText = function subText(theme) {
  return "\n  font-family: ".concat(theme.fonts.robotoRegular, ";\n  font-size: ").concat(theme.fontSizes.sm, ";\n  line-height: ").concat(theme.lineHeight.sm, ";\n  font-weight: 100;");
};
export var tertiarySmall = function tertiarySmall(theme) {
  return "\n  font-family: ".concat(theme.fonts.robotoMedium, ";\n  font-size: ").concat(theme.fontSizes.xs, ";\n  line-height: ").concat(theme.lineHeight.sm, ";\n  font-weight: 100;");
};
export var tertiaryLarge = function tertiaryLarge(theme) {
  return "\n  font-family: ".concat(theme.fonts.robotoRegular, ";\n  font-size: ").concat(theme.fontSizes.xxxl, ";\n  line-height: ").concat(theme.lineHeight.xxxl, ";\n  font-weight: 100;");
};
export var header1 = function header1(theme) {
  return "\n  font-family: ".concat(theme.fonts.robotoRegular, ";\n  font-size: ").concat(theme.fontSizes.xl, ";\n  line-height: ").concat(theme.lineHeight.xxl, ";\n  font-weight: 100;");
};
export var header2 = function header2(theme) {
  return "\n  font-family: ".concat(theme.fonts.robotoRegular, ";\n  font-size: ").concat(theme.fontSizes.lg, ";\n  line-height: ").concat(theme.lineHeight.xl, ";\n  font-weight: 100;");
};
export var header3 = function header3(theme) {
  return "\n  font-family: ".concat(theme.fonts.robotoMedium, ";\n  font-size: ").concat(theme.fontSizes.lg, ";\n  line-height: ").concat(theme.lineHeight.lg, ";\n  font-weight: 100;");
};
export var ingress = function ingress(theme) {
  return "\n  font-family: ".concat(theme.fonts.robotoRegular, ";\n  font-size: ").concat(theme.fontSizes.md, ";\n  line-height: ").concat(theme.lineHeight.md, ";\n  font-weight: 100;");
};