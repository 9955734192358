import { propEq } from "ramda";
export var allGenderTypes = {
  Total: "Totalt",
  Female: "Kvinnor",
  Male: "Män",
  ManWoman: "Kvinnor och Män"
};
export var getGender = function getGender(measure, measureId) {
  var _measure$submeasures;
  var submeasure = (_measure$submeasures = measure.submeasures) === null || _measure$submeasures === void 0 ? void 0 : _measure$submeasures.find(propEq("measureId", measureId));
  return allGenderTypes[submeasure === null || submeasure === void 0 ? void 0 : submeasure.gender];
};
export var translateGender = function translateGender(gender) {
  return {
    Total: "Totalt",
    Female: "Kvinnor",
    Male: "Män",
    ManWoman: "Kvinnor och Män"
  }[gender];
};