import useCustomEffect from "./useCustomEffect";
import useDidMount from "./useDidMount";

/*
Consider phasing this one out in favour of useDebouncedValue.
In general the problem we want to solve is that something changes frequently and we dont want the reaction
to that change to fire constantly. Instead of debouncing the reaction, consider using useDebouncedValue to
debounce the ever changing value so that we can fire the reaction once, once the value is finished changing/debouncing.
*/
export default function useDebounce(watchedValue, delay, func) {
  var awaitMount = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  var didMount = useDidMount();
  useCustomEffect(function () {
    var handler;
    if (!awaitMount || didMount) {
      handler = setTimeout(function () {
        func(watchedValue);
      }, delay);
    }
    return function () {
      if (handler) {
        clearTimeout(handler);
      }
    };
  }, [watchedValue, delay]);
}