import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useRef, useEffect, useState } from "react";
export default function useInView(_ref) {
  var rootMargin = _ref.rootMargin,
    root = _ref.root;
  var containerRef = useRef(null);
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    inView = _useState2[0],
    setIsInView = _useState2[1];
  useEffect(function () {
    var currentRef = containerRef.current;
    var observer = new IntersectionObserver(function (entries) {
      if (entries[0].isIntersecting) setIsInView(true);
    }, {
      rootMargin: rootMargin,
      root: root
    });
    if (currentRef) observer.observe(currentRef);
    return function () {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [root, rootMargin]);
  return [containerRef, inView];
}