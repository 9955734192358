import styled from "styled-components";
import { extendedSystemStyles } from "@mixins";
var styledSystemKeys = ["space", "color", "layout", "flexbox", "position", "border"];
export default {
  Column: styled.div.withConfig({
    displayName: "Paper__Column",
    componentId: "sc-1gv3qot-0"
  })(["", " ", ""], function (_ref) {
    var theme = _ref.theme;
    return "\n    background: ".concat(theme.colors.G1, ";\n    width: 100%;");
  }, extendedSystemStyles(styledSystemKeys)),
  Row: styled.div.withConfig({
    displayName: "Paper__Row",
    componentId: "sc-1gv3qot-1"
  })(["", " ", ""], function (_ref2) {
    var theme = _ref2.theme;
    return "\n    background: ".concat(theme.colors.G1, ";\n    width: 100%;\n    display: flex;");
  }, extendedSystemStyles(styledSystemKeys))
};