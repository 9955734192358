import axios from "axios";
import { prop } from "ramda";
import queryString from "query-string";
import { webApiServiceUrl } from "@microServices";
import { preppQuery } from "@fetch";
export default function searchOrganizations(searchQuery) {
  var baseUrl = "".concat(webApiServiceUrl, "/api/search/organizations?");
  var mapping = {
    typ: "type",
    q: "searchText"
  };
  var preppedQuery = queryString.stringify(preppQuery(searchQuery, mapping));
  return axios.get("".concat(baseUrl).concat(preppedQuery), {
    headers: {
      "Content-Type": "application/json"
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
}