import styled from "styled-components";
import { focusStyle, hiddenStyles, hoverStyle, addIcon, extendedSystemStyles } from "@mixins";
export var styledSystemKeys = ["space", "layout", "position"];
export var FilterLabel = styled.label.withConfig({
  displayName: "filterButtonstyles__FilterLabel",
  componentId: "sc-bwamgx-0"
})(["&:after{", " color:", ";}&:hover:after{color:", ";}", " ", ""], function (_ref) {
  var theme = _ref.theme;
  return addIcon(theme, "cross_xs", "auto 0 auto 10px");
}, function (_ref2) {
  var theme = _ref2.theme,
    checked = _ref2.checked;
  return checked ? theme.colors.i1 : theme.colors.P0;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.P0;
}, function (_ref4) {
  var theme = _ref4.theme,
    checked = _ref4.checked;
  return "\n    min-height: ".concat(theme.actionStyles.height, ";\n    font-family: ").concat(theme.fonts.robotoRegular, ";\n    font-size: ").concat(theme.fontSizes[theme.actionStyles.fontSize], ";\n    line-height: ").concat(theme.lineHeight[theme.actionStyles.lineHeight], ";\n    padding: 6px 20px;\n\n    display: flex;\n    align-items: center;\n    border-radius: ").concat(theme.actionStyles.buttonRadius, ";\n    box-shadow: ").concat(checked ? "inset 0 0 0 3px ".concat(theme.colors.P2) : "inset 0 0 0 3px ".concat(theme.colors.P2, "a8"), ";\n\n    background: ").concat(theme.colors.G1, ";\n    color: ").concat(checked ? theme.colors.P0 : "".concat(theme.colors.P0, "a8"), ";\n\n    outline: none;\n    border: none;\n    transition: all .3s;\n\n    &:hover{ ").concat(hoverStyle(theme), " }\n    &:focus-within{ ").concat(focusStyle(theme), " }");
}, extendedSystemStyles(styledSystemKeys));
export var FilterInput = styled.input.withConfig({
  displayName: "filterButtonstyles__FilterInput",
  componentId: "sc-bwamgx-1"
})(["", ""], hiddenStyles);