import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { curry, without, uniq } from "ramda";
export var addRemoveListContent = curry(function (setState, handle, add, ids) {
  setState(function (old) {
    if (handle) {
      if (add) return _objectSpread(_objectSpread({}, old), {}, _defineProperty({}, handle, uniq(old[handle].concat(ids))));
      return _objectSpread(_objectSpread({}, old), {}, _defineProperty({}, handle, without([ids].flat(), old[handle])));
    }
    if (add) return uniq(old.concat(ids));
    return without([ids].flat(), old);
  });
});