import styled from "styled-components";
import { hoverStyle, focusStyle, hiddenStyles, ellipsis, colorFullSroll, mainText } from "@mixins";
export var Label = styled.p.withConfig({
  displayName: "searchableSelectstyles__Label",
  componentId: "sc-1ln75xe-0"
})(["", ""], function (_ref) {
  var theme = _ref.theme,
    hideLabel = _ref.hideLabel,
    disabled = _ref.disabled,
    inverted = _ref.inverted;
  return "\n    font-family: ".concat(theme.fonts.robotoMedium, ";\n    font-size: ").concat(theme.fontSizes.xs, ";\n    line-height: ").concat(theme.lineHeight.xs, ";\n\n    color: ").concat(disabled ? "".concat(theme.colors.P2, "a8") : inverted ? theme.colors.G1 : theme.colors.G4, ";\n    font-weight: 100;\n    margin: 6px 0;\n\n    ").concat(hideLabel ? hiddenStyles : "");
});
export var Options = styled.ul.withConfig({
  displayName: "searchableSelectstyles__Options",
  componentId: "sc-1ln75xe-1"
})(["", ""], function (_ref2) {
  var theme = _ref2.theme,
    open = _ref2.open;
  return "\n    display: ".concat(open ? "flex" : "none", ";\n    flex-direction: column;\n    padding: 0;\n    margin: 0;\n    max-height: calc(44px * 6);\n    overflow-y: auto;\n    ").concat(colorFullSroll(theme, "10px", true), "\n\n    border: 3px solid ").concat(theme.colors.i1, ";\n    background: ").concat(theme.colors.G1, ";\n    z-index: 9;\n    border-top: none;\n    outline: none;\n\n    position: absolute;\n    top: 100%;\n    left: 0;\n    right: 0;\n  ");
});
export var Option = styled.li.withConfig({
  displayName: "searchableSelectstyles__Option",
  componentId: "sc-1ln75xe-2"
})(["", ""], function (_ref3) {
  var theme = _ref3.theme,
    activeFocus = _ref3.activeFocus;
  return "\n    transition: all .3s;\n    list-style: none;\n\n    ".concat(activeFocus ? focusStyle(theme) : "", "\n    &:hover{ ").concat(!activeFocus ? hoverStyle(theme) : "", " }\n  ");
});
export var OptionText = styled.p.withConfig({
  displayName: "searchableSelectstyles__OptionText",
  componentId: "sc-1ln75xe-3"
})(["", " ", " padding:0 20px;margin:0;line-height:44px;width:100%;height:44px;"], function (_ref4) {
  var theme = _ref4.theme;
  return mainText(theme);
}, ellipsis);