import { useEffect } from "react";
import { blockDefaultEvent } from "utils/events";
export default function useHandleKeyDown(id, containerRef, selectRef, open, setOpen, activeFocus, setActiveFocus, options) {
  useEffect(function () {
    if (activeFocus !== -1) {
      var ele = document.getElementById("".concat(id, "-link-").concat(activeFocus));
      if (ele) {
        ele.focus();
        ele.scrollIntoView({
          block: "nearest"
        });
      }
    }
  }, [activeFocus, id]);
  useEffect(function () {
    var close = function close() {
      setOpen(false);
      setActiveFocus(-1);
      if (document.activeElement !== selectRef.current) selectRef.current.focus();
    };
    var handleKeyDown = function handleKeyDown(e) {
      if (open && [9, 27, 38, 40].includes(e.keyCode)) blockDefaultEvent(e);
      //Escape
      if (open && [27].includes(e.keyCode)) {
        close();
      }
      //Tab
      else if (e.keyCode === 9) close();
      //ArrowDown
      else if (e.keyCode === 40) {
        setActiveFocus(function (old) {
          return old < options.length - 1 ? old + 1 : 0;
        });
        if (!open) setOpen(true);
      }
      //ArrowUp
      else if (e.keyCode === 38) {
        setActiveFocus(function (old) {
          return old > 0 ? old - 1 : options.length - 1;
        });
        if (!open) setOpen(true);
      }

      //Home, End
      else if (open && (e.keyCode === 36 || e.keyCode === 35)) {
        if (document.activeElement !== selectRef.current) selectRef.current.focus();
        setActiveFocus(-1);
      }
    };
    var containerRefCopy = containerRef.current;
    containerRefCopy.addEventListener("keydown", handleKeyDown);
    return function () {
      return containerRefCopy === null || containerRefCopy === void 0 ? void 0 : containerRefCopy.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, options, containerRef, selectRef, setOpen, setActiveFocus]);
}