import styled from "styled-components";
import { colorFullSroll, focusStyle, hoverStyle } from "@mixins";
export var StyledTextArea = styled.textarea.withConfig({
  displayName: "textareastyles__StyledTextArea",
  componentId: "sc-198v7o9-0"
})(["", ""], function (_ref) {
  var theme = _ref.theme,
    disabled = _ref.disabled,
    inverted = _ref.inverted;
  return "\n    ".concat(colorFullSroll(theme, "10px"), "\n    border-radius: ").concat(theme.actionStyles.inputRadius, ";\n    height: 80px;\n    width: 100%;\n    padding: 7px 20px;\n    overflow-y: auto;\n\n    color: ").concat(disabled ? "".concat(theme.colors.P2, "a8") : inverted ? theme.colors.G1 : theme.colors.P2, ";\n    background: ").concat(disabled ? theme.colors.G3 : "inherit", ";\n    font-family: ").concat(theme.fonts.robotoRegular, ";\n    font-size: ").concat(theme.fontSizes[theme.actionStyles.fontSize], ";\n    line-height: ").concat(theme.lineHeight[theme.actionStyles.lineHeight], ";\n\n    border-radius: ").concat(theme.actionStyles.buttonRadius, ";\n    box-shadow: ").concat(!disabled ? "inset 0 0 0 3px ".concat(theme.colors.i1) : "inset 0 0 0 3px ".concat(theme.colors.i1, "80"), ";\n\n    transition: all .3s;\n\n    &:hover { ").concat(hoverStyle(theme), " }\n    &:focus { ").concat(focusStyle(theme), " }");
});