import axios from "axios";
import { prop } from "ramda";
import queryString from "query-string";
import { webApiServiceUrl } from "@microServices";
import { preppQuery } from "@fetch";
export default function searchMeasures(searchQuery, authTicket) {
  var mapping = {
    "rapporterande-system": "reportingSystemId",
    q: "searchText",
    kallsystem: "informationSourceId"
  };
  var preppedQuery = preppQuery(searchQuery, mapping);
  var baseUrl = "".concat(webApiServiceUrl, "/api/search/measures?");
  return axios.get("".concat(baseUrl).concat(queryString.stringify(preppedQuery)), {
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
}
export var getAdminMeasures = function getAdminMeasures(searchQuery, authTicket) {
  var mapping = {
    "rapporterande-system": "reportingSystemId",
    q: "searchText",
    kallsystem: "informationSourceId"
  };
  var preppedQuery = preppQuery(searchQuery, mapping);
  var baseUrl = "".concat(webApiServiceUrl, "/api/admin/measures?");
  return axios.get("".concat(baseUrl).concat(queryString.stringify(preppedQuery)), {
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};