import styled from "styled-components";
import { colorFullSroll } from "@mixins";
export var Options = styled.ul.withConfig({
  displayName: "searchableSelectLinkstyles__Options",
  componentId: "sc-wpk11e-0"
})(["", ""], function (_ref) {
  var theme = _ref.theme,
    open = _ref.open;
  return "\n    display: ".concat(open ? "flex" : "none", ";\n    flex-direction: column;\n    padding: 0;\n    margin: 0;\n    max-height: calc(44px * 8);\n    overflow-y: auto;\n    ").concat(colorFullSroll(theme, "10px", true), "\n\n    border: 3px solid ").concat(theme.colors.i1, ";\n    background: ").concat(theme.colors.G1, ";\n    z-index: 101;\n    border-top: none;\n    outline: none;\n\n    position: absolute;\n    top: 100%;\n    left: 0;\n    right: 0;\n  ");
});
export var Option = styled.li.withConfig({
  displayName: "searchableSelectLinkstyles__Option",
  componentId: "sc-wpk11e-1"
})(["", ""], function (_ref2) {
  var theme = _ref2.theme;
  return "\n    box-shadow: 0 1px 0 0 ".concat(theme.colors.i1, ";\n    list-style: none;\n  ");
});