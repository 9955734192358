export var translateSort = function translateSort(sort) {
  return {
    unsorted: "unsorted",
    alpha: "alpha",
    reverseAlpha: "reverseAlpha",
    desc: "descending",
    asc: "ascending"
  }[sort] || "unsorted";
};
export var sortDirection = function sortDirection(sort) {
  return {
    unsorted: "none",
    alpha: "ascending",
    reverseAlpha: "descending",
    desc: "descending",
    asc: "ascending",
    partitionDesc: "descending",
    partitionAsc: "ascending"
  }[sort] || "none";
};
export var sortIcon = function sortIcon(sort) {
  return {
    none: "listArrows",
    ascending: "sortAsc",
    descending: "sortDesc",
    alpha: "sortAsc",
    reverseAlpha: "sortDesc"
  }[sort];
};