import styled from "styled-components";
import { columns, extendedSystemStyles } from "@mixins";
var styledSystemKeys = ["space", "layout"];
export default styled.div.withConfig({
  displayName: "ColumnsContainer",
  componentId: "sc-18mjc9v-0"
})(["", "", ""], function (_ref) {
  var theme = _ref.theme,
    count = _ref.count,
    gap = _ref.gap,
    fill = _ref.fill,
    breakpoint = _ref.breakpoint;
  return "\n    ".concat(columns(count, gap, fill), "\n\n    @media (max-width: ").concat(theme.breakpoints[breakpoint], "){\n      ").concat(columns(count - 1, gap, fill), "\n    }\n\n    @media (max-width: ").concat(theme.breakpoints[2], "){\n      ").concat(columns(1, gap, fill), "\n    }\n  ");
}, extendedSystemStyles(styledSystemKeys));