import styled from "styled-components";
import { extendedSystemStyles } from "@mixins";
import { opacityInAnim } from "@animation-ui";
var styledSystemKeys = ["space", "layout", "grid"];
export default styled.ol.withConfig({
  displayName: "R1C2_1x3",
  componentId: "sc-r8gwxv-0"
})(["animation:", " 1s;", " ", ""], opacityInAnim, function (_ref) {
  var theme = _ref.theme,
    breakpoint = _ref.breakpoint;
  return "\n    display: grid;\n    grid-template-columns: 1fr 3fr;\n    gap: 5px;\n\n    @media (max-width: ".concat(theme.breakpoints[breakpoint], "){\n      display: grid;\n      grid-template-columns: 1fr;\n\n      img {\n        max-height: 500px;\n      }\n    }");
}, extendedSystemStyles(styledSystemKeys));