import fontSizes from "../fontSizes";
import iconSizes from "../iconSizes";
import lineHeight from "../lineHeight";
import breakpoints from "../breakpoints";
import fonts from "../fonts";
import colors from "./colors";
export default {
  breakpoints: breakpoints,
  fontSizes: fontSizes,
  iconSizes: iconSizes,
  lineHeight: lineHeight,
  fonts: fonts,
  colors: colors,
  actionStyles: {
    fontSize: "md",
    lineHeight: "md",
    inputRadius: "4px",
    buttonRadius: "6px",
    height: "44px",
    focusBorder: "0 0 0 4px"
  },
  sizes: [1, 1 / 2, 1 / 3, 1 / 4, 1 / 5],
  space: {
    xs: "10px",
    sm: "20px",
    md: "40px",
    lg: "80px"
  }
};