import axios from "axios";
import { prop } from "ramda";
import { webApiServiceUrl } from "@microServices";
export var getBannerText = function getBannerText() {
  return axios.get("".concat(webApiServiceUrl, "/api/admin/systemtext/get?type=Banner"), {
    headers: {
      "Content-Type": "application/json"
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};
export var saveBannerText = function saveBannerText(bannerText, authTicket) {
  return axios.post("".concat(webApiServiceUrl, "/api/admin/systemtext/save"), {
    systemTextType: "Banner",
    text: bannerText
  }, {
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};