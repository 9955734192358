import { sortWith, ascend, descend, prop, curry, propEq, all, filter, uniq, pluck, compose } from "ramda";
import { isEmptyArray } from "utils/validation";
import { translateSort } from "utils/sort";
var partitionValue = curry(function (partition, entry) {
  var _entry$data$find;
  return (_entry$data$find = entry.data.find(propEq("partition", partition))) === null || _entry$data$find === void 0 ? void 0 : _entry$data$find.dependent;
});
var nullValues = function nullValues(entry) {
  return all(propEq("dependent", null), entry.data);
};
var totalValue = function totalValue(entry) {
  return entry.data.reduce(function (acc, curr) {
    if (curr.dependent) acc = acc + curr.dependent;
    return acc;
  }, 0);
};
var labels = function labels(entry) {
  var _labels = compose(filter(Boolean), uniq, pluck("label"))(entry.data);
  return isEmptyArray(_labels) ? 0 : 1;
};

//sort = partitionAsc
//partition = Kvinnor
//direction = ascending
export var sortGraphData = function sortGraphData(sort, data) {
  var partition = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "Totalt";
  var translatedSort = translateSort(sort);
  var sortOptions = {
    unsorted: [ascend(function () {
      return true;
    })],
    ascending: [ascend(nullValues), ascend(totalValue), descend(labels), ascend(prop("name"))],
    descending: [ascend(nullValues), descend(totalValue), descend(labels), ascend(prop("name"))],
    partitionAsc: [ascend(partitionValue(partition)), ascend(prop("name"))],
    partitionDesc: [descend(partitionValue(partition)), ascend(prop("name"))],
    alpha: [ascend(prop("name"))],
    reverseAlpha: [descend(prop("name"))]
  };
  return sortWith(sortOptions[translatedSort], data) || data;
};