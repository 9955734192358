import styled from "styled-components";
import { addIcon, hiddenStyles, extendedSystemStyles } from "@mixins";
export var styledSystemKeys = ["color", "space", "layout", "flexbox"];
export var StyledFieldset = styled.fieldset.withConfig({
  displayName: "fieldsetstyles__StyledFieldset",
  componentId: "sc-146qf4n-0"
})(["border:none;padding:10px 0 0;margin:0;min-width:0;width:100%;", ""], extendedSystemStyles(styledSystemKeys));
export var FieldsetLegend = styled.legend.withConfig({
  displayName: "fieldsetstyles__FieldsetLegend",
  componentId: "sc-146qf4n-1"
})(["&:before{", "}", ""], function (_ref) {
  var theme = _ref.theme,
    icon = _ref.icon;
  return addIcon(theme, icon, "-.2rem 8px auto 1px");
}, function (_ref2) {
  var theme = _ref2.theme,
    hideLegend = _ref2.hideLegend;
  return "\n    min-height: ".concat(theme.actionStyles.height, ";\n    font-family: ").concat(theme.fonts.robotoRegular, ";\n    font-size: ").concat(theme.fontSizes.md, ";\n    width: 100%;\n    display: flex;\n\n    align-items: center;\n    padding: ").concat(theme.space.xs, " ").concat(theme.space.sm, ";\n    box-sizing: border-box;\n\n    background: ").concat(theme.colors.i5, ";\n    border-top: 1px solid ").concat(theme.colors.i4, ";\n\n    ").concat(hideLegend ? hiddenStyles : "", "\n  ");
});