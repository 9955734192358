import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import axios from "axios";
import { prop } from "ramda";
import queryString from "query-string";
import { isEmptyObject } from "utils/validation";
import { mediaApiServiceUrl } from "@microServices";
export var getPublicMedia = function getPublicMedia(query, authTicket) {
  var newQuery = query.id ? _objectSpread(_objectSpread({}, query), {}, {
    guid: query.id.split("/").pop()
  }) : query;
  var url = isEmptyObject(query) ? "".concat(mediaApiServiceUrl, "/managed") : "".concat(mediaApiServiceUrl, "/managed?").concat(queryString.stringify(newQuery));
  return axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};
export var searchPublicMedia = function searchPublicMedia(query, authTicket) {
  var newQuery = query.id ? _objectSpread(_objectSpread({}, query), {}, {
    guid: query.id.split("/").pop()
  }) : query;
  var url = isEmptyObject(query) ? "".concat(mediaApiServiceUrl, "/managed/search") : "".concat(mediaApiServiceUrl, "/managed/search?").concat(queryString.stringify(newQuery));
  return axios.post(url, {}, {
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};
export var uploadPublicMedia = function uploadPublicMedia(media, description, authTicket) {
  var formData = new FormData();
  if (Array.isArray(media)) {
    for (var i = 0; i < media.length; i++) {
      formData.append("file", media[i]);
    }
  } else formData.append("file", media);
  return axios.post("".concat(mediaApiServiceUrl, "/managed?alt=").concat(description), formData, {
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};
export var deletePublicMedia = function deletePublicMedia(id, authTicket) {
  return axios.delete("".concat(mediaApiServiceUrl, "/managed/").concat(id.split("/").pop()), {
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(prop("data")).catch(function (error) {
    throw new Error(error);
  });
};