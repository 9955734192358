import styled from "styled-components";
import { subText, extendedSystemStyles } from "@mixins";
var styledSystemKeys = ["space", "layout", "color", "typography", "position"];
export default styled.p.withConfig({
  displayName: "SubText",
  componentId: "sc-3p0mku-0"
})(["", " ", ""], function (_ref) {
  var theme = _ref.theme,
    inverted = _ref.inverted;
  return "\n    ".concat(subText(theme), "\n    color: ").concat(inverted ? theme.colors.G1 : theme.colors.G4, ";\n    margin: 10px 0;");
}, extendedSystemStyles(styledSystemKeys));