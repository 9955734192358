import _defineProperty from "@babel/runtime/helpers/defineProperty";
/* eslint-disable */
Array.prototype.toObjectEntries = function (handle) {
  var O = Object(this);
  return O.map(function (x) {
    return _defineProperty({}, handle, x);
  });
};
Number.prototype.countDecimals = function () {
  var _this$toString$split$;
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return ((_this$toString$split$ = this.toString().split(".")[1]) === null || _this$toString$split$ === void 0 ? void 0 : _this$toString$split$.length) || 0;
};