import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import axios from "axios";
import { webApiServiceUrl } from "@microServices";
import { measureGroupTypeId } from "utils/measures";
var request = function request(method, path, data, authTicket) {
  return axios({
    url: "".concat(webApiServiceUrl, "/api").concat(path),
    method: method,
    data: data,
    headers: {
      "Content-Type": "application/json",
      "x-bvo-ticket": authTicket
    }
  }).then(function (_ref) {
    var data = _ref.data;
    return data;
  });
};
export var getMeasuresMetadataByIds = function getMeasuresMetadataByIds(measureIds, authTicket) {
  return request("post", "/measures/measures", measureIds, authTicket);
};
export var getMeasureDetailModel = function getMeasureDetailModel(query, authTicket) {
  var translatedQuery = query.relatedMeasuresByEntry ? _objectSpread(_objectSpread({}, query), {}, {
    relatedMeasuresByEntry: Object.values(measureGroupTypeId).includes(query.relatedMeasuresByEntry) ? query.relatedMeasuresByEntry : measureGroupTypeId[query.relatedMeasuresByEntry]
  }) : query;
  return request("post", "/measuredetail/measuredetailmodel", translatedQuery, authTicket);
};
export var getDashboardModel = function getDashboardModel(query, authTicket) {
  var relatedMeasuresByEntry = measureGroupTypeId[query.relatedMeasuresByEntry] || "keyword";
  return request("post", "/dashboard/dashboardmodel", _objectSpread(_objectSpread({}, query), {}, {
    relatedMeasuresByEntry: relatedMeasuresByEntry
  }), authTicket);
};
export var getMeasureSettingsById = function getMeasureSettingsById(ids, authTicket) {
  return request("post", "/admin/measuresettings/get", ids, authTicket);
};
export var saveMeasureSettings = function saveMeasureSettings(settings, authTicket) {
  return request("post", "/admin/measuresettings/save", settings, authTicket);
};
export var syncMeasure = function syncMeasure(settings, authTicket) {
  return request("post", "/measures/sync", settings, authTicket);
};
export var fetchSyncingMeasures = function fetchSyncingMeasures(authTicket) {
  return request("get", "/measures/sync-status", null, authTicket);
};