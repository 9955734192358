import styled from "styled-components";
import PropTypes from "prop-types";
import { focusStyle, activeStyle, hoverStyle, addIcon, extendedSystemStyles } from "@mixins";
var styledSystemKeys = ["space", "layout", "flexbox"];
var SecondaryButton = styled.button.withConfig({
  displayName: "SecondaryButton",
  componentId: "sc-w3g16-0"
})(["&:before{", "}&:after{", "}", " ", ""], function (_ref) {
  var iconBefore = _ref.iconBefore,
    theme = _ref.theme;
  return addIcon(theme, iconBefore, "auto 10px auto 0");
}, function (_ref2) {
  var iconAfter = _ref2.iconAfter,
    theme = _ref2.theme;
  return addIcon(theme, iconAfter, "auto 0 auto 10px");
}, function (_ref3) {
  var theme = _ref3.theme,
    active = _ref3.active,
    disabled = _ref3.disabled,
    background = _ref3.background,
    color = _ref3.color;
  return "\n    height: ".concat(theme.actionStyles.height, ";\n    font-family: ").concat(theme.fonts.robotoRegular, ";\n    font-size: ").concat(theme.fontSizes[theme.actionStyles.fontSize], ";\n    line-height: ").concat(theme.actionStyles.height, ";\n\n    display: flex;\n    justify-content: center;\n\n    width: 210px;\n    padding: 0;\n    border-radius: ").concat(theme.actionStyles.buttonRadius, ";\n    box-shadow: ").concat(disabled ? "inset 0 0 0 3px ".concat(theme.colors.P2, "a8") : "inset 0 0 0 3px ".concat(theme.colors.P2), ";\n\n    &.no-border{\n      box-shadow: none;\n    }\n\n    background: ").concat(background || theme.colors.G1, ";\n    color: ").concat(!disabled ? theme.colors.P0 : "".concat(theme.colors.P0, "a8"), ";\n    color: ").concat(!disabled ? color || theme.colors.P0 : "".concat(color || theme.colors.P0, "a8"), ";\n\n    outline: none;\n    border: none;\n    transition: all .3s;\n\n    ").concat(!active && !disabled ? "\n      &:hover{ ".concat(hoverStyle(theme), " }\n      &:focus{ ").concat(focusStyle(theme), " }\n    ") : "", "\n\n    ").concat(active ? activeStyle(theme) : "", "\n    ");
}, extendedSystemStyles(styledSystemKeys));
SecondaryButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  styles: PropTypes.object,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string
};
export default SecondaryButton;