import styled from "styled-components";
import { extendedSystemStyles } from "@mixins";
var styledSystemKeys = ["space", "color", "layout", "flexbox", "position", "border"];
export default styled.div.withConfig({
  displayName: "Div",
  componentId: "sc-vnldfr-0"
})(["width:100%;", " ", ""], function (_ref) {
  var isLgBreakpoint = _ref.isLgBreakpoint,
    compositionType = _ref.compositionType;
  return "\n   margin-top: ".concat(compositionType && !isLgBreakpoint ? "50px" : "0", ";\n    ");
}, extendedSystemStyles(styledSystemKeys));