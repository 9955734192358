import { useEffect } from "react";
export default function useClickOutside(ref, callback, open) {
  useEffect(function () {
    var listener = function listener(event) {
      if (!ref.current || ref.current.contains(event.target)) return;
      callback(event);
    };
    var escapeListener = function escapeListener(event) {
      if (event.key === "Escape") callback(event);
    };
    if (open) {
      document.addEventListener("mousedown", listener);
      document.addEventListener("keydown", escapeListener);
    } else {
      var _document, _document2;
      (_document = document) === null || _document === void 0 ? void 0 : _document.removeEventListener("mousedown", listener);
      (_document2 = document) === null || _document2 === void 0 ? void 0 : _document2.removeEventListener("keydown", escapeListener);
    }
    return function () {
      var _document3, _document4;
      (_document3 = document) === null || _document3 === void 0 ? void 0 : _document3.removeEventListener("mousedown", listener);
      (_document4 = document) === null || _document4 === void 0 ? void 0 : _document4.removeEventListener("keydown", escapeListener);
    };
  }, [open, callback, ref]);
}