import styled from "styled-components";
import { rangeStyles } from "../rangeCommon.styles";
export var InputContainer = styled.div.withConfig({
  displayName: "rangeInputstyles__InputContainer",
  componentId: "sc-fxxon5-0"
})(["", ""], function (_ref) {
  var theme = _ref.theme;
  return "\n    height: 44px;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n\n    &:nth-child(2) { background: ".concat(theme.colors.P2, "; }\n    &:nth-child(3) {\n      box-shadow: inset 0 0 0 1px ").concat(theme.colors.i1, ";\n    }");
});
export var Range = styled.input.withConfig({
  displayName: "rangeInputstyles__Range",
  componentId: "sc-fxxon5-1"
})(["", ""], function (_ref2) {
  var theme = _ref2.theme;
  return "\n    ".concat(rangeStyles(theme), "\n    border-left: 1px solid ").concat(theme.colors.i1, ";\n    border-bottom: 1px solid ").concat(theme.colors.i1, ";\n\n    &::-webkit-slider-thumb {\n      z-index: 2;\n      border: none;\n    }");
});