import styled from "styled-components";
import { focusStyle, hiddenStyles, ellipsis, extendedSystemStyles } from "@mixins";
export var styledSystemKeys = ["space", "layout", "flexbox"];
export var Description = styled.span.withConfig({
  displayName: "radioButtonstyles__Description",
  componentId: "sc-6soucd-0"
})(["", ""], hiddenStyles);
export var RadioContainer = styled.div.withConfig({
  displayName: "radioButtonstyles__RadioContainer",
  componentId: "sc-6soucd-1"
})(["", "", ""], function (_ref) {
  var theme = _ref.theme,
    checked = _ref.checked,
    disabled = _ref.disabled,
    hideLabel = _ref.hideLabel;
  return "\n    display: flex;\n    padding: 0 20px;\n    width: ".concat(!hideLabel ? "100%" : "fit-content", ";\n\n    pointer-events: ").concat(!disabled ? "all" : "none", ";\n    transition: all .3s;\n\n    ").concat(!disabled && !checked ? "\n      &:hover {\n        input { box-shadow: inset 0 0 0 3px ".concat(theme.colors.P0, "; }\n      }\n    ") : "");
}, extendedSystemStyles(styledSystemKeys));
export var RadioLabel = styled.label.withConfig({
  displayName: "radioButtonstyles__RadioLabel",
  componentId: "sc-6soucd-2"
})(["", ""], function (_ref2) {
  var theme = _ref2.theme,
    disabled = _ref2.disabled,
    hideLabel = _ref2.hideLabel,
    ellipse = _ref2.ellipse,
    maxWidth = _ref2.maxWidth;
  return "\n    font-family: ".concat(theme.fonts.robotoRegular, ";\n    font-size: ").concat(theme.fontSizes[theme.actionStyles.fontSize], ";\n    line-height: ").concat(theme.lineHeight[theme.actionStyles.lineHeight], ";\n\n    width: calc(100% - 33px);\n    ").concat(maxWidth ? "max-width: ".concat(maxWidth, ";") : "", "\n    cursor: ").concat(!disabled ? "pointer" : "inital", ";\n    padding: 11px 0 9px;\n    color: ").concat(!disabled ? theme.colors.G4 : "".concat(theme.colors.P2, "a8"), ";\n\n    font-weight: 100;\n    margin: auto 0 auto 10px;\n\n    ").concat(hideLabel ? hiddenStyles : "", "\n    ").concat(ellipse ? ellipsis : "");
});
export var StyledRadio = styled.input.withConfig({
  displayName: "radioButtonstyles__StyledRadio",
  componentId: "sc-6soucd-3"
})(["&:after{margin:auto;content:\"\";", "}", ""], function (_ref3) {
  var theme = _ref3.theme,
    checked = _ref3.checked;
  return checked ? "\n      height: 9px;\n      width: 9px;\n      border-radius: 50%;\n      background: ".concat(theme.colors.P0, ";\n    ") : "";
}, function (_ref4) {
  var theme = _ref4.theme,
    disabled = _ref4.disabled,
    hideLabel = _ref4.hideLabel;
  return "\n    height: 23px;\n    width: 23px;\n    margin: auto 0;\n    display: flex;\n\n    cursor: ".concat(!disabled ? "pointer" : "inital", ";\n\n    color: ").concat(!disabled ? theme.colors.P0 : "".concat(theme.colors.P2, "a8"), ";\n    box-shadow: ").concat(!disabled ? "inset 0 0 0 3px ".concat(theme.colors.i1) : "inset 0 0 0 3px ".concat(theme.colors.i1, "80"), ";\n\n    border-radius: 50%;\n\n    appearance: none;\n    transition: all .3s;\n\n    ").concat(hideLabel ? "\n      &:focus{ ".concat(focusStyle(theme), " }\n    ") : "");
});