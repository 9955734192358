import _typeof from "@babel/runtime/helpers/typeof";
var uuidRegexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
export var isValidValue = function isValidValue(x) {
  return x === 0 || x;
};
export var isValidNumber = function isValidNumber(x) {
  return typeof x === "number" && !isNaN(x);
};
export var isValidDate = function isValidDate(x) {
  return Object.prototype.toString.call(x) === "[object Date]";
};
export var isValidBool = function isValidBool(x) {
  return x === true || x === false;
};
export var isNotEmptyObject = function isNotEmptyObject(x) {
  return _typeof(x) === "object" && Object.keys(x).length !== 0;
};
export var isEmptyObject = function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
};
export var isValidArray = function isValidArray(x) {
  return Array.isArray(x);
};
export var isNotEmptyArray = function isNotEmptyArray(x) {
  return Array.isArray(x) && x.length > 0;
};
export var isEmptyArray = function isEmptyArray(x) {
  return Array.isArray(x) && x.length === 0;
};
export var sameLengthArray = function sameLengthArray(x, y) {
  return Array.isArray(x) && Array.isArray(y) && x.length === y.length;
};
export var isValidString = function isValidString(x) {
  return typeof x === "string" && x.length > 0;
};
export var isValidUuid = function isValidUuid(x) {
  return uuidRegexExp.test(x);
};
export var valueBearing = function valueBearing(value) {
  return typeof value === "number";
};