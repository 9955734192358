import styled from "styled-components";
import { tertiarySmall, extendedSystemStyles } from "@mixins";
var styledSystemKeys = ["space", "layout", "color", "typography"];
export default styled.p.withConfig({
  displayName: "TertiarySmall",
  componentId: "sc-raujfp-0"
})(["", " ", ""], function (_ref) {
  var theme = _ref.theme,
    inverted = _ref.inverted,
    dimmed = _ref.dimmed;
  return "\n    ".concat(tertiarySmall(theme), "\n    color: ").concat(inverted ? theme.colors.G1 : dimmed ? "".concat(theme.colors.P2, "a8") : theme.colors.G4, ";\n\n    margin: 10px 0;");
}, extendedSystemStyles(styledSystemKeys));