import styled from "styled-components";
import { activeStyle, hoverStyle, hoverStyle_noBorder, addIcon, extendedSystemStyles } from "@mixins";
var styledSystemKeys = ["space", "layout", "position", "color"];
export default styled.button.withConfig({
  displayName: "iconButtonstyles",
  componentId: "sc-rwysup-0"
})(["&:before{", " font-size:", ";}", " ", ""], function (_ref) {
  var icon = _ref.icon,
    theme = _ref.theme;
  return addIcon(theme, icon, "auto");
}, function (_ref2) {
  var theme = _ref2.theme,
    iconSize = _ref2.iconSize;
  return theme.iconSizes[iconSize] || "";
}, function (_ref3) {
  var theme = _ref3.theme,
    active = _ref3.active,
    disabled = _ref3.disabled,
    inverted = _ref3.inverted,
    type = _ref3.type,
    error = _ref3.error,
    noBorder = _ref3.noBorder,
    hideBoxshadow = _ref3.hideBoxshadow,
    hideBackgroundColor = _ref3.hideBackgroundColor;
  return "\n    height: ".concat(theme.actionStyles.height, ";\n    min-height: ").concat(theme.actionStyles.height, ";\n\n    display: flex;\n    justify-content: center;\n    min-width: 34px;\n    padding: 0 10px;\n\n    &:not(.no-border){\n      box-shadow: ").concat(error ? "inset 0 0 0 3px ".concat(theme.colors.V3) : !disabled && !hideBoxshadow ? "inset 0 0 0 1px ".concat(theme.colors.i1) : "", ";\n    }\n\n    background: ").concat(hideBackgroundColor ? "transparent" : disabled ? "".concat(theme.colors.P2, "80") : type === "error" ? theme.colors.V3 : inverted ? theme.colors.P2 : theme.colors.G1, ";\n    color: ").concat(disabled ? theme.colors.G5 : type === "error" ? theme.colors.G1 : inverted ? theme.colors.i4 : theme.colors.P3, ";\n\n    transition: all .3s;\n\n    ").concat(!active && !disabled ? "\n\n    &:hover{\n      ".concat(noBorder ? hoverStyle_noBorder(theme) : hoverStyle(theme), "\n      background: ").concat(hideBackgroundColor ? "transparent" : type === "error" ? theme.colors.V3_2 : "", ";\n      color: ").concat(type === "error" ? theme.colors.G1 : "", ";\n    }\n  ") : "", "\n\n\n    ").concat(active ? activeStyle(theme) : "", "\n    ");
}, extendedSystemStyles(styledSystemKeys));