import styled from "styled-components";
import { focusStyle, subText, activeStyle, hoverStyle, addIcon, extendedSystemStyles } from "@mixins";
export var styledSystemKeys = ["space", "layout", "flexbox"];
export var StyledLabel = styled.label.withConfig({
  displayName: "viewButtonstyles__StyledLabel",
  componentId: "sc-g05692-0"
})(["&:before{", "}", "", ""], function (_ref) {
  var icon = _ref.icon,
    theme = _ref.theme;
  return addIcon(theme, icon, "auto");
}, function (_ref2) {
  var theme = _ref2.theme,
    checked = _ref2.checked,
    disabled = _ref2.disabled;
  return "\n  ".concat(subText(theme), "\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    padding: 10px 10px 15px;\n    width: 120px;\n    height: 95px;\n    text-align: center;\n    box-shadow: ").concat(!disabled ? "inset 0 0 0 3px ".concat(theme.colors.i1) : "inset 0 0 0 3px ".concat(theme.colors.P2, "a8"), ";\n\n    background: ").concat(theme.colors.G1, ";\n    color: ").concat(!disabled ? theme.colors.P1 : "".concat(theme.colors.P0, "a8"), ";\n    margin: 0 -1px 5px;\n\n    outline: none;\n    border: none;\n    transition: all .3s;\n    appearance: none;\n\n    &:first-child{ border-radius: 3px 0 0 3px; }\n    &:last-child{ border-radius: 0 3px 3px 0; }\n    &:only-child{ border-radius: 3px; }\n\n    &:focus{ ").concat(focusStyle(theme), " }\n\n    ").concat(!checked && !disabled ? "\n      &:hover{ ".concat(hoverStyle(theme), " }\n    ") : "", "\n\n    ").concat(checked ? activeStyle(theme) : "", "\n    ");
}, extendedSystemStyles(styledSystemKeys));
export var StyledInput = styled.input.withConfig({
  displayName: "viewButtonstyles__StyledInput",
  componentId: "sc-g05692-1"
})(["margin:0;outline:none;border:none;appearance:none;"]);