import styled from "styled-components";
import { hiddenStyles } from "@mixins";
export default styled.label.withConfig({
  displayName: "Label",
  componentId: "sc-re12qp-0"
})(["", ""], function (_ref) {
  var theme = _ref.theme,
    disabled = _ref.disabled,
    hideLabel = _ref.hideLabel,
    inverted = _ref.inverted;
  return "\n    font-family: ".concat(theme.fonts.robotoMedium, ";\n    font-size: ").concat(theme.fontSizes.xs, ";\n    line-height: ").concat(theme.lineHeight.xs, ";\n\n    color: ").concat(!disabled ? theme.colors.G4 : "".concat(theme.colors.P2, "a8"), ";\n    font-weight: 100;\n    margin: 6px 0;\n\n    ").concat(inverted ? "color: ".concat(theme.colors.G1, ";") : "", "\n\n    ").concat(hideLabel ? hiddenStyles : "");
});