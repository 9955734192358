import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { v4 as uniqueId } from "uuid";
import { categoryBlueprint } from "@cms/categories/constants";
export var newCategory = function newCategory() {
  return _objectSpread(_objectSpread({}, categoryBlueprint), {}, {
    id: uniqueId()
  });
};
export var CATEGORY = "".concat(Object.keys(categoryBlueprint));
export var GET_CATEGORY = "\n  query categories($where: CategoryFilterInput, $order: [CategorySortInput!]){\n    categories(where: $where, order: $order) { ".concat(CATEGORY, " }\n  }");
export var CREATE_CATEGORY = "\n  mutation createCategory($input: CreateCategoryInput!){\n    createCategory(input: $input){\n      category { ".concat(CATEGORY, " }\n    }\n  }");
export var UPDATE_CATEGORY = "\n  mutation updateCategory($input: UpdateCategoryInput!){\n    updateCategory(input: $input){\n      category { ".concat(CATEGORY, " }\n    }\n  }");
export var DELETE_CATEGORY = "\n  mutation deleteCategory($input: DeleteInput!){\n    deleteCategory(input: $input){\n      success\n    }\n  }";