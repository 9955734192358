export default {
  lineGraph: "\\e98b",
  lineGraph_md: "\\e98c",
  lineGraph_lg: "\\e98c",
  stackedLineGraph: "\\e91e",
  stackedLineGraph_md: "\\e91f",
  stackedLineGraph_lg: "\\e91f",
  barChart: "\\e920",
  barChart_md: "\\e921",
  barChart_lg: "\\e921",
  stackedBarchart: "\\e91c",
  stackedBarchart_md: "\\e91d",
  stackedBarchart_lg: "\\e91d",
  deviationChart: "\\e91a",
  deviationChart_md: "\\e91b",
  deviationChart_lg: "\\e91b",
  spiderChart: "\\e9d6",
  spiderChart_md: "\\e9d7",
  spiderChart_lg: "\\e9d7",
  heatChart: "\\e9e0",
  heatChart_md: "\\e9e1",
  heatChart_lg: "\\e9e1",
  pieChart: "\\e9ba",
  pieChart_md: "\\e9bb",
  pieChart_lg: "\\e9bb",
  goal: "\\e96a",
  goal_md: "\\e96b",
  goal_lg: "\\e96b",
  list: "\\e993",
  list_md: "\\e993",
  list_lg: "\\e994"
};