import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { sortWith, ascend, descend, curry, pipe, propEq, prop, compose, find } from "ramda";
import { getPeriod } from "utils/periods";
import { extractValue, getRelativeToValue, measurementByPeriod } from "utils/measurement/measurementExtensions";
import { valueBearing } from "utils/validation";
export var sortOptions = {
  unsorted: "Ingen sortering",
  alpha: "Från A till Ö",
  reverseAlpha: "Från Ö till A",
  desc: "Fallande",
  asc: "Stigande"
};

// Moves the "SE" region code to the top of the list if it's present,
// indicating prioritization of this region in the list.
// "SE" signifies a specific region also known as "Riket" in the unitList.
export var sortUnits = function sortUnits(units) {
  if (!Array.isArray(units) || !units.includes("SE")) return units;
  return ["SE"].concat(_toConsumableArray(units.filter(function (id) {
    return id !== "SE";
  })));
};
export var sort = function sort(extractor, data, desc) {
  if (desc) {
    return sortWith([descend(extractor)])(data);
  }
  return sortWith([ascend(extractor)])(data);
};
export var sortByPeriod = function sortByPeriod(x) {
  return x.period.end.getTime();
};
export var sortMeasurementsByPeriod = sortWith([descend(sortByPeriod)]);
export var sortOrganizationIdsByPeriodValue = curry(function (lookupOrganization, sort, periodType, dateTo, units, measurementsWithMetadata) {
  var findDateToByOrganization = curry(function (defaultValue, organizationId) {
    return measurementsWithMetadata ? pipe(find(propEq("organizationId", organizationId)), prop("measurements"), measurementByPeriod(getPeriod(periodType, dateTo)), extractValue(defaultValue))(measurementsWithMetadata) : 1;
  });
  var byName = function byName(id) {
    var _lookupOrganization;
    return (_lookupOrganization = lookupOrganization(id)) === null || _lookupOrganization === void 0 ? void 0 : _lookupOrganization.name.toLowerCase();
  };
  var byValueBearing = function byValueBearing(value) {
    return value === "-" ? 1 : !valueBearing(value) ? 2 : 3;
  };
  var sortOptions = {
    unsorted: [ascend(function () {
      return true;
    })],
    asc: [descend(compose(byValueBearing, findDateToByOrganization("-"))), ascend(findDateToByOrganization(Infinity)), ascend(prop("name"))],
    desc: [descend(compose(byValueBearing, findDateToByOrganization("-"))), descend(findDateToByOrganization(-Infinity)), ascend(prop("name"))],
    alpha: [ascend(byName)],
    reverseAlpha: [descend(byName)]
  };
  return sort && periodType && dateTo && sortWith(sortOptions[sort], units) || units;
});
export var sortOrganizationIdsByRelativeTo = curry(function (lookupOrganization, sort, relativeTo, periodType, dateTo, units, measurementsWithMetadata) {
  var byRelativeTo = curry(function (defaultValue, organizationId) {
    return measurementsWithMetadata ? pipe(find(propEq("organizationId", organizationId)), prop("measurements"), measurementByPeriod(getPeriod(periodType, dateTo)), getRelativeToValue(defaultValue, relativeTo))(measurementsWithMetadata) : 1;
  });
  var byName = function byName(id) {
    var _lookupOrganization2;
    return (_lookupOrganization2 = lookupOrganization(id)) === null || _lookupOrganization2 === void 0 ? void 0 : _lookupOrganization2.name.toLowerCase();
  };
  var byValueBearing = function byValueBearing(value) {
    return value === "-" ? 1 : !valueBearing(value) ? 2 : 3;
  };
  var sortOptions = {
    unsorted: [ascend(function () {
      return true;
    })],
    asc: [descend(compose(byValueBearing, byRelativeTo("-"))), ascend(byRelativeTo(Infinity)), ascend(prop("name"))],
    desc: [descend(compose(byValueBearing, byRelativeTo("-"))), descend(byRelativeTo(-Infinity)), ascend(prop("name"))],
    alpha: [ascend(byName)],
    reverseAlpha: [descend(byName)]
  };
  return periodType && dateTo && sortWith(sortOptions[sort], units) || units;
});