import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useEffect } from "react";
import { debounce } from "utils/debounce";
export default function useWindowWidth() {
  var _useState = useState(window.innerWidth),
    _useState2 = _slicedToArray(_useState, 2),
    windowWidth = _useState2[0],
    setWindowWidth = _useState2[1];
  useEffect(function () {
    var handleResize = debounce(function () {
      setWindowWidth(window.innerWidth);
    }, 200);
    window.addEventListener("resize", handleResize);
    return function () {
      var _window;
      (_window = window) === null || _window === void 0 ? void 0 : _window.removeEventListener("resize", handleResize);
    };
  }, []);
  return windowWidth;
}