import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { uniqBy, update, pipe, prop, propEq, equals, curry, find, all, map, sortWith, ascend, toLower, compose } from "ramda";
import { v4 as uniqueId } from "uuid";
import { isValidValue, isValidNumber, sameLengthArray, isNotEmptyObject, isValidArray } from "utils/validation";
export var generateUnitListName = function generateUnitListName(name, unitLists) {
  var unitListsWithPrefix = unitLists.filter(function (list) {
    return list.name.startsWith(name);
  }).map(function (list) {
    var digits = list.name.split("").map(function (list) {
      return parseInt(list);
    }).filter(Number.isInteger);
    return digits.length > 0 ? Math.max.apply(Math, _toConsumableArray(digits)) : 0;
  });
  if (unitListsWithPrefix.length === 0) return name;
  return "".concat(name, " (").concat(Math.max.apply(Math, _toConsumableArray(unitListsWithPrefix)) + 1, ")");
};
export var newUnitListEntry = function newUnitListEntry() {
  var units = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var unitLists = arguments.length > 1 ? arguments[1] : undefined;
  var selected = arguments.length > 2 ? arguments[2] : undefined;
  var volatile = arguments.length > 3 ? arguments[3] : undefined;
  var listName = arguments.length > 4 ? arguments[4] : undefined;
  return {
    id: uniqueId(),
    name: listName ? generateUnitListName(listName.trim(), unitLists) : volatile ? generateUnitListName("Osparad lista", unitLists) : generateUnitListName("Min lista", unitLists),
    selected: selected,
    units: units,
    volatile: volatile
  };
};
export var repairUnitListsProps = map(function (list) {
  return _objectSpread(_objectSpread({}, list), {}, {
    id: isValidValue(list.id) ? list.id : uniqueId(),
    name: isValidValue(list.name) ? list.name : "",
    units: isValidArray(list.units) ? list.units : []
  });
});
export var sortUnitlists = sortWith([ascend(function (x) {
  return x.label.toLowerCase().includes("osparad lista") ? 2 : 1;
}), ascend(compose(toLower, prop("label")))]);
export var getUnitListIdx = function getUnitListIdx(lists, id) {
  return lists.findIndex(propEq("id", id));
};
export var selectedIdx = function selectedIdx(lists) {
  return lists.findIndex(function (x) {
    return x.selected;
  });
};
export var volatileIdx = function volatileIdx(lists) {
  return lists.findIndex(function (x) {
    return x.volatile;
  });
};
export var getVolatileId = function getVolatileId(lists) {
  var _lists$volatileIdx;
  return (_lists$volatileIdx = lists[volatileIdx(lists)]) === null || _lists$volatileIdx === void 0 ? void 0 : _lists$volatileIdx.id;
};
export var getSelectedId = function getSelectedId(lists) {
  var _lists$selectedIdx;
  return (_lists$selectedIdx = lists[selectedIdx(lists)]) === null || _lists$selectedIdx === void 0 ? void 0 : _lists$selectedIdx.id;
};
export var selectedUnitListUnits = function selectedUnitListUnits(lists) {
  var _lists$selectedIdx2;
  return ((_lists$selectedIdx2 = lists[selectedIdx(lists)]) === null || _lists$selectedIdx2 === void 0 ? void 0 : _lists$selectedIdx2.units) || [];
};
export var volatileUnitListUnits = function volatileUnitListUnits(lists) {
  var _lists$volatileIdx2;
  return ((_lists$volatileIdx2 = lists[volatileIdx(lists)]) === null || _lists$volatileIdx2 === void 0 ? void 0 : _lists$volatileIdx2.units) || [];
};
export var setUnitListProp = curry(function (handle, value, idx, list) {
  return isValidNumber(idx) && idx !== -1 ? update(idx, _objectSpread(_objectSpread({}, list[idx]), {}, _defineProperty({}, handle, value)), list) : list;
});
export var missingBothSelectedAndVolatile = function missingBothSelectedAndVolatile(list) {
  return selectedIdx(list) === -1 && volatileIdx(list) === -1;
};
export var missingSelectedHasVolatile = function missingSelectedHasVolatile(list) {
  return selectedIdx(list) === -1 && volatileIdx(list) !== -1;
};
export var selectUnitListCheckbox = function selectUnitListCheckbox(unitLists, id, isChecked) {
  return !isValidValue(id) ? unitLists : pipe(function (_unitLists) {
    var selectedIdx = _unitLists.findIndex(function (unitList) {
      return unitList.selected;
    });
    return setUnitListProp("selected", false, selectedIdx, _unitLists); // deselect old
  }, function (_unitLists) {
    var targetIdx = _unitLists.findIndex(function (unitList) {
      return unitList.id === id;
    });
    return setUnitListProp("selected", isChecked, targetIdx, _unitLists); // select new
  })(unitLists);
};
export var selectUnitList = function selectUnitList(unitLists, id) {
  return !isValidValue(id) ? unitLists : pipe(function (_unitLists) {
    return setUnitListProp("selected", false, selectedIdx(_unitLists), _unitLists);
  },
  //deselect old
  function (_unitLists) {
    return setUnitListProp("selected", true, getUnitListIdx(_unitLists, id), _unitLists);
  } //select new
  )(unitLists);
};
export var addVolatileListIfMissing = function addVolatileListIfMissing(unitLists) {
  return volatileIdx(unitLists) === -1 ? unitLists.concat(newUnitListEntry([], unitLists, false, true)) //volatile always last
  : unitLists;
};
export var copyUnitsIntoVolatileList = function copyUnitsIntoVolatileList(unitLists, units) {
  return pipe(addVolatileListIfMissing, function (_unitLists) {
    return setUnitListProp("units", units, volatileIdx(_unitLists), _unitLists);
  })(unitLists);
};
export var selectUnitListWithUnits = function selectUnitListWithUnits(unitLists, unitIds) {
  return pipe(find(function (_unitList) {
    return sameLengthArray(_unitList.units, unitIds.flat()) && all(function (unit) {
      return unitIds.flat().includes(unit.id);
    }, _unitList.units);
  }), function (matchingList) {
    return isNotEmptyObject(matchingList) ? selectUnitList(unitLists, matchingList.id) //pick list with matching units
    : pipe(function (x) {
      return copyUnitsIntoVolatileList(x, unitIds.flat().toObjectEntries("id"));
    }, function (x) {
      return selectUnitList(x, getVolatileId(x));
    })(unitLists);
  })(unitLists);
};
export var createNewUnitList = pipe(function (_unitLists) {
  return setUnitListProp("selected", false, selectedIdx(_unitLists), _unitLists);
}, function (_unitLists) {
  return [newUnitListEntry([], _unitLists, true, false)].concat(_unitLists);
}); //new entry always first

export var repairSelectedUnitList = pipe(function (_unitLists) {
  return missingBothSelectedAndVolatile(_unitLists) ? setUnitListProp("selected", true, 0, _unitLists) : _unitLists;
}, function (_unitLists) {
  return missingSelectedHasVolatile(_unitLists) ? setUnitListProp("selected", true, volatileIdx(_unitLists), _unitLists) : _unitLists;
});
export var removeUnitList = curry(function (unitLists, id) {
  return pipe(function (_unitLists) {
    return isValidValue(id) ? _unitLists.filter(function (x) {
      return x.id !== id;
    }) : _unitLists;
  }, repairSelectedUnitList)(unitLists);
});
export var createNewUnitListFromSelected = function createNewUnitListFromSelected(unitLists, listName) {
  var selectedUnits = selectedUnitListUnits(unitLists); // Fetch units of the currently selected list
  return pipe(function (_unitLists) {
    return setUnitListProp("selected", false, selectedIdx(_unitLists), _unitLists);
  },
  // Deselect the current list
  function (_unitLists) {
    return [newUnitListEntry(selectedUnits, _unitLists, true, false, listName)].concat(_unitLists);
  } // Create new list with the units of selected list and set it as selected
  )(unitLists);
};
export var addUnitsToUnitList = function addUnitsToUnitList(unitLists, listIdx, unitIds) {
  return !isValidNumber(listIdx) ? unitLists : pipe(function (_unitLists) {
    var _unitLists$listIdx;
    return (_unitLists$listIdx = _unitLists[listIdx]) === null || _unitLists$listIdx === void 0 ? void 0 : _unitLists$listIdx.units.concat(unitIds.flat().toObjectEntries("id"));
  }, uniqBy(prop("id")), function (_units) {
    return setUnitListProp("units", _units, listIdx, unitLists);
  })(unitLists);
};
export var removeUnitsFromUnitList = function removeUnitsFromUnitList(unitLists, listIdx, unitIds) {
  return !isValidNumber(listIdx) ? unitLists : pipe(function (_unitList) {
    return _unitList === null || _unitList === void 0 ? void 0 : _unitList.units.filter(function (x) {
      return !unitIds.flat().includes(x.id);
    });
  }, function (_units) {
    return setUnitListProp("units", _units, listIdx, unitLists);
  })(unitLists[listIdx]);
};
export var addVolatileUnits = function addVolatileUnits(unitLists, unitIds) {
  return !missingBothSelectedAndVolatile(unitLists) && equals(volatileIdx(unitLists), selectedIdx(unitLists)) ? addUnitsToUnitList(unitLists, selectedIdx(unitLists), unitIds.flat()) : pipe(function (_unitLists) {
    return copyUnitsIntoVolatileList(_unitLists, selectedUnitListUnits(_unitLists));
  }, function (_unitLists) {
    return selectUnitList(_unitLists, getVolatileId(_unitLists));
  }, function (_unitLists) {
    return addUnitsToUnitList(_unitLists, volatileIdx(_unitLists), unitIds.flat());
  })(unitLists);
};
export var addUnitsToSelectedList = function addUnitsToSelectedList(unitLists, unitIds) {
  if (selectedIdx(unitLists) < 0) {
    // No selected list, create a new volatile list
    return addVolatileUnits(unitLists, unitIds);
  }

  // Existing selected list is available
  return addUnitsToUnitList(unitLists, selectedIdx(unitLists), unitIds.flat());
};
export var removeVolatileUnits = function removeVolatileUnits(unitLists, unitIds) {
  return !missingBothSelectedAndVolatile(unitLists) && equals(volatileIdx(unitLists), selectedIdx(unitLists)) ? removeUnitsFromUnitList(unitLists, selectedIdx(unitLists), unitIds.flat()) : pipe(function (_unitLists) {
    return copyUnitsIntoVolatileList(_unitLists, selectedUnitListUnits(_unitLists));
  }, function (_unitLists) {
    return selectUnitList(_unitLists, getVolatileId(_unitLists));
  }, function (_unitLists) {
    return removeUnitsFromUnitList(_unitLists, volatileIdx(_unitLists), unitIds.flat());
  })(unitLists);
};
export var removeUnitsFromSelectedList = function removeUnitsFromSelectedList(unitLists, unitIds) {
  if (selectedIdx(unitLists) < 0) {
    // No selected list, work on the volatile list
    return removeVolatileUnits(unitLists, unitIds);
  }

  // Existing selected list is available
  return removeUnitsFromUnitList(unitLists, selectedIdx(unitLists), unitIds.flat());
};