import styled from "styled-components";
import { focusStyle, hoverStyle, header1, header2, header3, mainText, subText } from "@mixins";
var availableStyles = {
  h1: header1,
  h2: header2,
  h3: header3,
  mainText: mainText,
  subText: subText
};
export var StyledInput = styled.input.withConfig({
  displayName: "textInputstyles__StyledInput",
  componentId: "sc-ltoi0-0"
})(["", ""], function (_ref) {
  var theme = _ref.theme,
    disabled = _ref.disabled,
    inverted = _ref.inverted,
    styleAs = _ref.styleAs,
    error = _ref.error;
  return "\n    ".concat(availableStyles[styleAs] ? availableStyles[styleAs](theme) : "\n      ".concat(mainText(theme), "\n      font-family: ").concat(theme.fonts.robotoRegular, ";"), "\n\n    height: ").concat(theme.actionStyles.height, ";\n    width: 100%;\n    padding: 0 20px;\n\n    color: ").concat(disabled ? "".concat(theme.colors.P2, "a8") : theme.colors.P2, ";\n    background: ").concat(disabled ? theme.colors.G3 : "inherit", ";\n\n    border-radius: ").concat(theme.actionStyles.buttonRadius, ";\n    box-shadow: ").concat(!disabled ? error ? "inset 0 0 0 3px ".concat(theme.colors.V3) : "inset 0 0 0 3px ".concat(theme.colors.i1) : "inset 0 0 0 3px ".concat(theme.colors.i1, "80"), ";\n\n    transition: all .2s;\n\n    ").concat(!disabled ? "\n      &:hover { ".concat(hoverStyle(theme), " }\n      &:focus { ").concat(focusStyle(theme), " }\n    ") : "", "\n\n    ::-webkit-input-placeholder, :placeholder, ::-ms-input-placeholder {\n      color: ").concat(!disabled ? "".concat(theme.colors.P2, "a8") : "".concat(theme.colors.P2, "80"), ";\n    }\n\n    ").concat(inverted ? "color: ".concat(theme.colors.G1, ";") : "");
});