export default {
  psychiatry: "\\e9c5",
  psychiatry_md: "\\e9c5",
  psychiatry_lg: "\\e9c6",
  stroke: "\\e9db",
  stroke_md: "\\e9db",
  stroke_lg: "\\e9dc",
  heartCare: "\\e923",
  heartCare_md: "\\e923",
  heartCare_lg: "\\e924",
  lugns: "\\e998",
  lugns_md: "\\e998",
  lugns_lg: "\\e999",
  kidneys: "\\e986",
  kidneys_md: "\\e986",
  kidneys_lg: "\\e987",
  brokenArm: "\\e927",
  brokenArm_md: "\\e927",
  brokenArm_lg: "\\e928",
  heartbeat: "\\e971",
  heartbeat_md: "\\e971",
  heartbeat_lg: "\\e9ae",
  hospitalBed: "\\e972",
  hospitalBed_md: "\\e972",
  hospitalBed_lg: "\\e973",
  waitingTimes: "\\e9f0",
  waitingTimes_md: "\\e9f0",
  waitingTimes_lg: "\\e9f1",
  surgery: "\\e9dd",
  surgery_md: "\\e9dd",
  surgery_lg: "\\e9de",
  stetoscope: "\\e9d9",
  stetoscope_md: "\\e9d9",
  stetoscope_lg: "\\e9da",
  endOfLifeCare: "\\e9b0",
  endOfLifeCare_md: "\\e9b0",
  endOfLifeCare_lg: "\\e9b1",
  patientExperience: "\\e97f",
  patientExperience_md: "\\e97f",
  patientExperience_lg: "\\e980",
  population: "\\e981",
  population_md: "\\e981",
  population_lg: "\\e982",
  eye: "\\e983",
  eye_md: "\\e983",
  eye_lg: "\\e984",
  knee: "\\e9ab",
  knee_md: "\\e9ab",
  knee_lg: "\\e9ac",
  diabetes: "\\e944",
  diabetes_md: "\\e944",
  diabetes_lg: "\\e945",
  costProductivity: "\\e936",
  costProductivity_md: "\\e936",
  costProductivity_lg: "\\e93b",
  settings: "\\e936",
  settings_md: "\\e936",
  settings_lg: "\\e93b",
  heart: "\\e970",
  heart_md: "\\e970",
  heart_lg: "\\e9ad",
  heartFilled: "\\e97e",
  heartFilled_md: "\\e97e",
  heartFilled_lg: "\\e9af",
  cancer: "\\e97c",
  cancer_md: "\\e97c",
  cancer_lg: "\\e97d",
  woman: "\\e9f7",
  woman_md: "\\e9f7",
  woman_lg: "\\e9f8",
  womanFilled: "\\e96e",
  womanFilled_md: "\\e9f9",
  womanFilled_lg: "\\e9f9",
  man: "\\e99c",
  man_md: "\\e99c",
  man_lg: "\\e99d",
  manFilled: "\\e99e",
  manFilled_md: "\\e99f",
  manFilled_lg: "\\e99f",
  manWoman: "\\e9f5",
  manWoman_md: "\\e9f5",
  manWoman_lg: "\\e9f6",
  baby: "\\e97a",
  baby_md: "\\e97a",
  baby_lg: "\\e97b",
  workout: "\\e9e9",
  workout_md: "\\e9e9",
  workout_lg: "\\e9ea",
  vis: "\\e9b8",
  vis_md: "\\e9b8",
  vis_lg: "\\e9b9",
  hospital: "\\e974",
  hospital_md: "\\e974",
  hospital_lg: "\\e975",
  news: "\\e9e7",
  news_md: "\\e9e8",
  news_lg: "\\e9e8",
  openBook: "\\e925",
  openBook_md: "\\e926",
  openBook_lg: "\\e926",
  thematic: "\\e9e5",
  thematic_md: "\\e9e5",
  thematic_lg: "\\e9e6",
  healthcare: "\\e9f2",
  healthcare_md: "\\e9f2",
  healthcare_lg: "\\e9f3",
  regional: "\\e9cb",
  regional_md: "\\e9cb",
  regional_lg: "\\e9cc",
  area: "\\e905",
  area_md: "\\e905",
  area_lg: "\\e90e",
  global: "\\e958",
  global_md: "\\e958",
  global_lg: "\\e961",
  siths: "\\e9cf",
  siths_md: "\\e9cf",
  siths_lg: "\\e9d2",
  mobile: "\\e9a3",
  mobile_md: "\\e9a3",
  mobile_lg: "\\e9a7",
  browser: "\\e929",
  browser_md: "\\e92a",
  browser_lg: "\\e92a",
  laptop: "\\e988",
  laptop_md: "\\e988",
  laptop_lg: "\\e989",
  desktop: "\\e942",
  desktop_md: "\\e942",
  desktop_lg: "\\e943",
  tablet: "\\e9e2",
  tablet_md: "\\e9e2",
  tablet_lg: "\\e9e3",
  phone: "\\e9b6",
  phone_md: "\\e9b6",
  phone_lg: "\\e9b7",
  user: "\\e9c3",
  user_md: "\\e9c3",
  femaleUser: "\\e9c2",
  femaleUser_md: "\\e9c2",
  maleUser: "\\e9c4",
  maleUser_md: "\\e9c4"
};