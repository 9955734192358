export var urlIsPrefixed = function urlIsPrefixed(urlString) {
  return urlString && (urlString.toLowerCase().includes("://") || urlString.toLowerCase().includes("mailto:"));
};
export var isExternalLink = function isExternalLink(url) {
  if (url) {
    return url.replace(/.+\/\/|.+:|www.|\/.*/g, "") !== window.location.hostname;
  }
  return false;
};

//Expects urls to include their host. relative url's will not work.
export var makeUrl = function makeUrl(str, secure) {
  var prefix = secure === true ? "https://" : "http://";
  return str && str !== "" && !urlIsPrefixed(str) ? prefix + str : str;
};