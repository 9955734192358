import { any, equals } from "ramda";
export var compositionTypes = [{
  compositionType: "REPORT",
  translatedPlural: "Rapporter",
  translatedSingular: "Rapport"
}, {
  compositionType: "NEWS",
  translatedPlural: "Nyheter",
  translatedSingular: "Nyhet"
}, {
  compositionType: "PAGE",
  translatedPlural: "Sidor",
  translatedSingular: "Sida"
}];
export var translateCompositionType = function translateCompositionType(variable, to) {
  var _compositionTypes$fin;
  return (_compositionTypes$fin = compositionTypes.find(function (x) {
    return any(function (key) {
      var _x$key;
      return equals((_x$key = x[key]) === null || _x$key === void 0 ? void 0 : _x$key.toLowerCase(), variable === null || variable === void 0 ? void 0 : variable.toLowerCase());
    }, Object.keys(x));
  })) === null || _compositionTypes$fin === void 0 ? void 0 : _compositionTypes$fin[to];
};