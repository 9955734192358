import styled from "styled-components";
import { header3, extendedSystemStyles } from "@mixins";
var styledSystemKeys = ["space", "layout", "color", "typography"];
export default styled.h3.withConfig({
  displayName: "Header3",
  componentId: "sc-o4euub-0"
})(["", " ", ""], function (_ref) {
  var theme = _ref.theme,
    inverted = _ref.inverted;
  return "\n    ".concat(header3(theme), "\n    color: ").concat(!inverted ? theme.colors.G4 : theme.colors.G1, ";\n    margin: 5px 0 15px;");
}, extendedSystemStyles(styledSystemKeys));