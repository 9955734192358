import styled from "styled-components";
import { mainText, extendedSystemStyles } from "@mixins";
var styledSystemKeys = ["space", "layout", "color", "typography", "border"];
export default styled.p.withConfig({
  displayName: "MainText",
  componentId: "sc-ugp9dh-0"
})(["", " ", ""], function (_ref) {
  var theme = _ref.theme,
    inverted = _ref.inverted,
    lineClamp = _ref.lineClamp;
  return "\n    ".concat(mainText(theme), "\n    color: ").concat(inverted ? theme.colors.G2 : theme.colors.G4, ";\n    margin: 10px 0;\n\n    ").concat(lineClamp ? "\n      display: -webkit-box;\n      -webkit-line-clamp: ".concat(lineClamp, ";\n      -webkit-box-orient: vertical;\n    ") : "", "\n");
}, extendedSystemStyles(styledSystemKeys));