export default {
  xxs: "1rem",
  xs: "1.2rem",
  sm: "1.8rem",
  md: "2.4rem",
  lg: "2.4rem",
  xl: "2.8rem",
  xxl: "3.4rem",
  xxxl: "3.5rem",
  xxxxl: "4.5rem"
};