import styled from "styled-components";
import { focusStyle, hoverStyle, addIcon } from "@mixins";
export var InputContainer = styled.div.withConfig({
  displayName: "searchInputstyles__InputContainer",
  componentId: "sc-28bda7-0"
})(["&:before{", " ", "}&:hover:before{color:", ";}", ""], function (_ref) {
  var theme = _ref.theme,
    icon = _ref.icon;
  return icon ? addIcon(theme, icon) : addIcon(theme, "search");
}, function (_ref2) {
  var theme = _ref2.theme,
    disabled = _ref2.disabled,
    inverted = _ref2.inverted;
  return "\n      position: absolute;\n      top: calc(50% - 1.3rem);\n      left: 15px;\n      color: ".concat(disabled ? "".concat(theme.colors.P2, "a8") : inverted ? "".concat(theme.colors.G3, "d9") : theme.colors.P3, ";\n    ");
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.P0;
}, function (_ref4) {
  var theme = _ref4.theme,
    disabled = _ref4.disabled;
  return "\n    position: relative;\n    transition: all .2s;\n    border-radius: ".concat(theme.actionStyles.buttonRadius, ";\n\n    ").concat(!disabled ? "\n      &:focus-within{ ".concat(focusStyle(theme), " }\n    ") : "", "\n");
});
export var StyledInput = styled.input.withConfig({
  displayName: "searchInputstyles__StyledInput",
  componentId: "sc-28bda7-1"
})(["", ""], function (_ref5) {
  var theme = _ref5.theme,
    disabled = _ref5.disabled,
    inverted = _ref5.inverted,
    error = _ref5.error;
  return "\n    font-family: ".concat(theme.fonts.robotoRegular, ";\n    font-size: ").concat(theme.fontSizes[theme.actionStyles.fontSize], ";\n    line-height: ").concat(theme.lineHeight[theme.actionStyles.lineHeight], ";\n\n    height: ").concat(theme.actionStyles.height, ";\n    width: 100%;\n    padding: 0 20px 0 50px;\n\n    color: ").concat(disabled ? "".concat(theme.colors.P2, "a8") : inverted ? theme.colors.G1 : theme.colors.P2, ";\n    background: none;\n\n    border-radius: ").concat(theme.actionStyles.buttonRadius, ";\n    box-shadow: ").concat(!disabled ? error ? "inset 0 0 0 3px ".concat(theme.colors.V3) : "inset 0 0 0 3px ".concat(theme.colors.i1) : "inset 0 0 0 3px ".concat(theme.colors.i1, "80"), ";\n\n    outline: none;\n    transition: all .2s;\n\n    ").concat(!disabled ? "\n      &:hover {\n        ".concat(hoverStyle(theme), "\n        &::-webkit-input-placeholder {\n          color: ").concat(theme.colors.P2, "a8;\n        }\n      }\n    ") : "", "\n\n    &::-webkit-input-placeholder {\n      color: ").concat(disabled ? "".concat(theme.colors.P2, "80") : inverted ? "".concat(theme.colors.G3, "d9") : "".concat(theme.colors.P2, "a8"), ";\n    }");
});