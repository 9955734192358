import { propEq } from "ramda";
export var measureStatuses = [{
  id: "Synced",
  label: "SYNKAD",
  description: "Indikatorn synkades under senaste nattliga synkning. En uppdatering av data har gjorts om datum för senast ändrad checksumma sammanfaller med dagens datum. Då har allt data kopplat till den gamla checksumman ersatts av nytt data från rapporterande system. Varje rapporterande mätperiod har en egen checksumma.",
  color: "G1",
  background: "V1"
}, {
  id: "Paused",
  label: "PAUSAD",
  description: "Indikatorn synkas f n inte i den nattliga synkningen. Indikatorn har synkats tidigare och det kan finnas tidigare synkat data för indikatorn i VIS.",
  color: "G4",
  background: "G1"
}, {
  id: "SyncError",
  label: "FEL",
  description: "Något gick fel i den senaste synkningen av indikatorn. Indikatorn uppdateras inte så länge felet kvarstår.",
  color: "G1",
  background: "V3"
}, {
  id: "Blocked",
  label: "BLOCKAD",
  description: "Indikatorn har inte kunnat synkas p g a fel i synken av andra indikatorer för samma rapporterande system. Ett nytt försök sker nästkommande natt.",
  color: "G1",
  background: "V2"
}, {
  id: "New",
  label: "NY",
  description: "Systemet har hämtat metadata för en ny, ej tidigare synkad indikator från KIK. När indikatorn i status Ny har aktiverats i checkboxen för Nattlig synk kommer synkning att ske efterföljande natt.",
  color: "G1",
  background: "C10"
}, {
  id: "AwaitingResponse",
  label: "INVÄNTAR SVAR",
  description: "Indikatorn är aktiverad för synkning men en första synkning har inte skett ännu. Alternativt kan indikatorn ha varit pausad och nästa synkning efter återaktivering inte genomförts.",
  color: "G1",
  background: "V2"
}, {
  id: "N/A",
  label: "N/A",
  description: "Systemet har inte tillgång till tillräcklig information för att avgöra indikatorns aktuella status.",
  color: "G4",
  background: "G1"
}];
export var getMeasureStatusLabel = function getMeasureStatusLabel(id) {
  var _measureStatuses$find;
  return (_measureStatuses$find = measureStatuses.find(propEq("id", id))) === null || _measureStatuses$find === void 0 ? void 0 : _measureStatuses$find.label;
};
export var getMeasureStatusDescription = function getMeasureStatusDescription(id) {
  var _measureStatuses$find2;
  return (_measureStatuses$find2 = measureStatuses.find(propEq("id", id))) === null || _measureStatuses$find2 === void 0 ? void 0 : _measureStatuses$find2.description;
};
export var getMeasureStatusColor = function getMeasureStatusColor(id) {
  var _measureStatuses$find3;
  return (_measureStatuses$find3 = measureStatuses.find(propEq("id", id))) === null || _measureStatuses$find3 === void 0 ? void 0 : _measureStatuses$find3.color;
};
export var getMeasureStatusBackground = function getMeasureStatusBackground(id) {
  var _measureStatuses$find4;
  return (_measureStatuses$find4 = measureStatuses.find(propEq("id", id))) === null || _measureStatuses$find4 === void 0 ? void 0 : _measureStatuses$find4.background;
};