import { curry } from "ramda";
var allMonths = ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"];
export var allPeriodTypes = {
  month: {
    id: "Month",
    translation: "Månad",
    statisticPeriodInMonths: 1,
    months: 1
  },
  quarter: {
    id: "Quarter",
    translation: "Kvartal",
    statisticPeriodInMonths: 3,
    months: 3
  },
  tertials: {
    id: "Tertials",
    translation: "Tertial",
    statisticPeriodInMonths: 4,
    months: 4
  },
  halfyear: {
    id: "HalfYear",
    translation: "Halvår",
    statisticPeriodInMonths: 6,
    months: 6
  },
  year: {
    id: "Year",
    translation: "År",
    statisticPeriodInMonths: 12,
    months: 12
  },
  twoyear: {
    id: "TwoYear",
    translation: "Två år",
    statisticPeriodInMonths: 12,
    months: 24
  },
  threeyear: {
    id: "ThreeYear",
    translation: "Tre år",
    statisticPeriodInMonths: 12,
    months: 36
  },
  fouryear: {
    id: "FourYear",
    translation: "Fyra år",
    statisticPeriodInMonths: 12,
    months: 48
  },
  fiveyear: {
    id: "FiveYear",
    translation: "Fem år",
    statisticPeriodInMonths: 12,
    months: 60
  },
  sixyear: {
    id: "SixYear",
    translation: "Sex år",
    statisticPeriodInMonths: 12,
    months: 72
  },
  sevenyear: {
    id: "SevenYear",
    translation: "Sju år",
    statisticPeriodInMonths: 12,
    months: 84
  },
  eightyear: {
    id: "EightYear",
    translation: "Åtta år",
    statisticPeriodInMonths: 12,
    months: 96
  },
  nineyear: {
    id: "NineYear",
    translation: "Nio år",
    statisticPeriodInMonths: 12,
    months: 108
  },
  tenyear: {
    id: "TenYear",
    translation: "Tio år",
    statisticPeriodInMonths: 12,
    months: 120
  },
  twentyyear: {
    id: "TwentyYear",
    translation: "Tjugo år",
    statisticPeriodInMonths: 12,
    months: 240
  },
  none: {
    id: "None",
    translation: "-",
    statisticPeriodInMonths: 0,
    months: 0
  }
};
export var quarter = function quarter(date) {
  return Math.floor((date.getUTCMonth() + 3) / 3);
};
export var tertials = function tertials(date) {
  return Math.floor((date.getUTCMonth() + 4) / 4);
};
export var halfyear = function halfyear(date) {
  return Math.floor((date.getUTCMonth() + 6) / 6);
};
export var translateMonth = function translateMonth(month) {
  return allMonths[month] || "-";
};
export var truncateMonth = function truncateMonth(month) {
  return month.slice(0, 3);
};
export var translateMonthTruncated = function translateMonthTruncated(month) {
  var _allMonths$month;
  return ((_allMonths$month = allMonths[month]) === null || _allMonths$month === void 0 ? void 0 : _allMonths$month.slice(0, 3)) || "-";
};
export var translatePeriod = function translatePeriod(period) {
  return allPeriodTypes[(period === null || period === void 0 ? void 0 : period.toLowerCase()) || "none"].translation;
};
export var statisticPeriodInMonths = function statisticPeriodInMonths(period) {
  return allPeriodTypes[(period === null || period === void 0 ? void 0 : period.toLowerCase()) || "none"].statisticPeriodInMonths;
};
export var monthsInPeriod = function monthsInPeriod(period) {
  return allPeriodTypes[(period === null || period === void 0 ? void 0 : period.toLowerCase()) || "none"].months;
};

/*
Different ways to stringify a period, should be able to simplify
*/
export var periodAnnotation = curry(function (periodType, arrayDate) {
  var noHyphen = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!periodType || !arrayDate) return null;
  if (monthsInPeriod(periodType) > 12 && !noHyphen) {
    var newYear = arrayDate[0] - monthsInPeriod(periodType) / 12 + 1;
    return "".concat(new Date(Date.UTC(newYear)).getUTCFullYear(), "-").concat(arrayDate[0].toString());
  }
  return statisticPeriodInMonths(periodType) < 12 ? "".concat(arrayDate[0], " ").concat(arrayDate[1]) : arrayDate[0].toString();
});
export var truncatedPeriodAnnotation = curry(function (periodType, arrayDate) {
  var noHyphen = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!periodType || !arrayDate) return null;
  if (monthsInPeriod(periodType) > 12 && !noHyphen) {
    var newYear = arrayDate[0] - monthsInPeriod(periodType) / 12 + 1;
    return "".concat(new Date(Date.UTC(newYear)).getUTCFullYear(), "-").concat(arrayDate[0].toString());
  }
  if (periodType === "Month") {
    return noHyphen ? "".concat(truncateMonth(arrayDate[0]), " ").concat(arrayDate[1]) : "".concat(truncateMonth(arrayDate[0]), " - ").concat(arrayDate[1]);
  }
  if (statisticPeriodInMonths(periodType) < 12) {
    return noHyphen ? "".concat(arrayDate[0], " ").concat(arrayDate[1]) : "".concat(arrayDate[0], " - ").concat(arrayDate[1]);
  }
  return arrayDate[0].toString();
});
export var arrayAnnotationDate = curry(function (periodType, date) {
  if (!periodType || !date) return null;
  switch (periodType === null || periodType === void 0 ? void 0 : periodType.toLowerCase()) {
    case "month":
      return [translateMonth(date.getUTCMonth()), date.getUTCFullYear()];
    case "quarter":
      return ["Q".concat(quarter(date)), date.getUTCFullYear()];
    case "halfyear":
      return ["H".concat(halfyear(date)), date.getUTCFullYear()];
    case "tertials":
      return ["T".concat(tertials(date)), date.getUTCFullYear()];
    case "year":
    case "twoyear":
    case "threeyear":
    case "fouryear":
    case "fiveyear":
    case "sixyear":
    case "sevenyear":
    case "eightyear":
    case "nineyear":
    case "tenyear":
    case "twentyyear":
      return [date.getUTCFullYear()];
    default:
      return null;
  }
});
export var translateDateToPeriod = curry(function (periodType, date) {
  var noHyphen = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var converted = arrayAnnotationDate(periodType, date);
  if (!converted) return "-";
  return periodAnnotation(periodType, converted, noHyphen);
});