import styled from "styled-components";
import { focusStyle, hiddenStyles, hoverStyle, mainText } from "@mixins";
export var Label = styled.label.withConfig({
  displayName: "dateInputstyles__Label",
  componentId: "sc-1oup7go-0"
})(["", ""], function (_ref) {
  var theme = _ref.theme,
    disabled = _ref.disabled,
    hideLabel = _ref.hideLabel;
  return "\n    font-family: ".concat(theme.fonts.robotoMedium, ";\n    font-size: ").concat(theme.fontSizes.xs, ";\n    line-height: ").concat(theme.lineHeight.xs, ";\n\n    color: ").concat(!disabled ? theme.colors.G4 : "".concat(theme.colors.P2, "a8"), ";\n    font-weight: 100;\n    margin: 6px 0;\n\n    ").concat(hideLabel ? hiddenStyles : "");
});
export var Input = styled.input.withConfig({
  displayName: "dateInputstyles__Input",
  componentId: "sc-1oup7go-1"
})(["", ""], function (_ref2) {
  var theme = _ref2.theme,
    disabled = _ref2.disabled,
    inverted = _ref2.inverted;
  return "\n    ".concat(mainText(theme), "\n    background: ").concat(inverted ? theme.colors.P3 : "none", ";\n    color: ").concat(inverted ? theme.colors.G1 : theme.colors.P1, ";\n\n    &::-webkit-calendar-picker-indicator{\n      background-size: 2.4rem;\n      background-position: center;\n      height: 44px;\n      width: 44px;\n      padding: 0;\n\n      box-shadow: ").concat(!disabled ? "inset 0 0 0 1px ".concat(theme.colors.i1) : "", ";\n      color: ").concat(disabled ? theme.colors.G1 : inverted ? theme.colors.G1 : theme.colors.G4, ";\n\n      outline: none;\n      border: none;\n      transition: all .3s;\n      appearance: none;\n      margin-left: 10px;\n      cursor: ").concat(!disabled ? "pointer" : "initial", ";\n\n      ").concat(!disabled ? "\n        &:hover{ ".concat(focusStyle(theme), " }\n        &:focus{ ").concat(focusStyle(theme), " }\n      ") : "", "\n    }\n\n    &::-webkit-datetime-edit{\n      height: ").concat(theme.actionStyles.height, ";\n      border-radius: ").concat(theme.actionStyles.buttonRadius, ";\n      box-shadow: ").concat(!disabled ? "inset 0 0 0 3px ".concat(theme.colors.i1) : "inset 0 0 0 3px ".concat(theme.colors.i1, "80"), ";\n\n      transition: all .3s;\n      padding: 0 20px;\n      display: flex;\n      align-items: center;\n\n      ").concat(!disabled ? "\n        &:hover { ".concat(hoverStyle(theme), " }\n        &:focus { ").concat(focusStyle(theme), " }\n      ") : "", "\n    }\n  ");
});