import styled from "styled-components";
import { hiddenStyles, extendedSystemStyles } from "@mixins";
export var styledSystemKeys = ["space", "layout", "flexbox"];
export var Fieldset = styled.fieldset.withConfig({
  displayName: "viewbuttonFieldsetstyles__Fieldset",
  componentId: "sc-14x3wf-0"
})(["border:none;padding:0;margin:0;", ""], extendedSystemStyles(styledSystemKeys));
export var Legend = styled.legend.withConfig({
  displayName: "viewbuttonFieldsetstyles__Legend",
  componentId: "sc-14x3wf-1"
})(["", ""], hiddenStyles);