import styled from "styled-components";
import { flex } from "@mixins";
export var StyledFooter = styled.footer.withConfig({
  displayName: "footerstyles__StyledFooter",
  componentId: "sc-dxubhl-0"
})(["", ""], function (_ref) {
  var theme = _ref.theme;
  return "\n    box-sizing: border-box;\n    width: 100%;\n    background: ".concat(theme.colors.P1, ";\n    padding: 20px;\n  ");
});
export var SectionsHolder = styled.div.withConfig({
  displayName: "footerstyles__SectionsHolder",
  componentId: "sc-dxubhl-1"
})(["", ""], flex(null, "center", null, "wrap"));
export var FooterSection = styled.div.withConfig({
  displayName: "footerstyles__FooterSection",
  componentId: "sc-dxubhl-2"
})(["", ""], function (_ref2) {
  var theme = _ref2.theme;
  return "\n    ".concat(flex("column"), "\n    width: 305px;\n    max-width: 100%;\n    margin: 0 ").concat(theme.space.lg, " ").concat(theme.space.lg, " 0;\n\n    @media (max-width: ").concat(theme.breakpoints[4], "){\n      width: calc(50% - ").concat(theme.space.lg, ");\n    }\n    @media (max-width: ").concat(theme.breakpoints[2], "){\n      width: 100%;\n      margin-right: 0;\n    }\n  ");
});