import { useEffect } from "react";
import { initializeErrorNotification } from "./initializeErrorNotification";
import { translateError } from "utils/errors";
export var useErrorNotifications = function useErrorNotifications(error, message) {
  useEffect(function () {
    var timeout;
    if (error) {
      timeout = setTimeout(function () {
        if (error) initializeErrorNotification("".concat(translateError(error)).concat(message || ""), "showErrorNotification", "closeErrorNotification");
      }, 500);
    } else window.dispatchEvent(new CustomEvent("closeErrorNotification"));
    return function () {
      return clearTimeout(timeout);
    };
  }, [error, message]);
};